import React, { useEffect, useState } from 'react';

import { setFormsInfoAction } from 'stores/money/forms/selectors';
import { useZustandFormsStore } from 'stores/money/forms/store';
import { setPaymentsCurrentStepAction } from 'stores/money/payments/selectors';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';

import Input from 'components/ui/Input/Base';
import { DefaultInputType } from 'components/ui/Input/Base/types';
import { DEFAULT_INPUT_VALUE } from 'components/ui/Input/Base/constant';
import AmountInput from 'components/ui/Input/AmountInput';
import InputUserInfo from 'components/ui/Input/InputUserInfo';
import PhoneInput from 'components/ui/Input/Phone';

import { isFilledForm, validateEmailValue } from 'utils/validation';
import { Steps } from 'stores/money/payments/types';
import { PaymentChannelType } from 'decryption_protocol/dist/lib/store/user/types';
import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';

import withdrawStyles from '../../Withdraw.module.css';

type Props = {
  min: number;
  max: number;
};

const AznWithdraw = ({ max, min }: Props) => {
  const setFormInfo = useZustandFormsStore(setFormsInfoAction);

  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  const currencyId = useZustandUserStore(getUserCurrencyIdSelector);

  const [phone, setPhone] = useState<string>('');

  const [email, setEmail] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [cardNumber, setCardNumber] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [name, setName] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [familyName, setFamilyName] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [amount, setAmount] = useState<string>('1500');

  const changeCardNumber = (value: string) => {
    setCardNumber({
      value,
      errorText: value.length !== 16 ? 'errors.invalidValue' : '',
    });
  };

  useEffect(() => {
    const isFilled = isFilledForm([
      phone,
      email,
      cardNumber,
      name,
      familyName,
      amount,
    ]);

    if (isFilled && currencyId) {
      setFormInfo({
        isDeposit: false,
        form: {
          currencyId,
          amount,
          phone: phone.slice(3),
          email: email.value,
          cardNumber: cardNumber.value,
          name: name.value,
          sureName: familyName.value,
          channelId: PaymentChannelType.CashXAZN,
          requestId: ERequestIds.WalletAzn,
        },
      });

      setStep(Steps.FilledForm);
    } else {
      setStep(Steps.TypingData);
    }
  }, [
    currencyId,
    phone,
    cardNumber,
    email,
    cardNumber,
    name,
    familyName,
    amount,
  ]);

  return (
    <div className={withdrawStyles.marginInputBottom}>
      <AmountInput
        placeholder="walletPopUp.forms.amount"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        isWithdraw
        size="full"
        onChange={setAmount}
        isPresets={false}
        min={min}
        max={max}
        classes={withdrawStyles.amountInputMargin}
        wrapperClasses={withdrawStyles.marginInputBottom}
      />
      <InputUserInfo
        isIgnoreUserInfo
        userInfoKey="name"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.name"
        size="full"
        classes={withdrawStyles.marginInputBottom}
        onChange={setName}
        field={name}
      />
      <InputUserInfo
        isIgnoreUserInfo
        userInfoKey="surname"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.sureName"
        size="full"
        field={familyName}
        onChange={setFamilyName}
        classes={withdrawStyles.marginInputBottom}
      />
      <InputUserInfo
        isIgnoreUserInfo
        userInfoKey="email"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.email"
        size="full"
        validationFn={validateEmailValue}
        field={email}
        onChange={setEmail}
        classes={withdrawStyles.marginInputBottom}
      />
      <PhoneInput
        countryCodeDefault="AZ"
        withConfirm={false}
        size="full"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        sendKey="09x"
        onChange={setPhone}
        isHidePlaceholder={false}
        baseValue="+91"
        placeholder="walletPopUp.forms.phone"
        classesWrapper={withdrawStyles.marginInputBottom}
      />
      <Input
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.bankAccountNumber"
        size="full"
        errorText={cardNumber.errorText}
        onChange={changeCardNumber}
        classes={withdrawStyles.marginInputBottom}
      />
    </div>
  );
};

export default AznWithdraw;
