import { useNavigate } from 'react-router';

import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { ModalTypes } from 'stores/modal/types';
import { setActiveProviderIdAction } from 'stores/casino/games/selectors';
import { useZustandCasinoGamesStore } from 'stores/casino/games/store';

import { ERouteNames } from 'Routes/types';

import styles from './ProviderItem.module.css';

type Props = {
  id: number;
  name: string;
  gameCounter: number;
};

const ProviderItem = (props: Props) => {
  const { id, name, gameCounter } = props;

  const closeModal = useZustandModalStore(closeAndOpenModalAction);

  const setProviderId = useZustandCasinoGamesStore(setActiveProviderIdAction);
  const navigate = useNavigate();

  const jumpTo = () => {
    closeModal(ModalTypes.Unknown);
    setProviderId(id);
    navigate(ERouteNames.CasinoProviderGames.replace(':id', `${id}`));
  };

  return (
    <div className={styles.row} onClick={jumpTo}>
      <div className={styles.name}>{name}</div>
      <div className={styles.value}>{gameCounter || '-'}</div>
    </div>
  );
};

export default ProviderItem;
