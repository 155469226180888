import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import { ERouteNames } from 'Routes/types';
import {
  getDeviceTypeSelector,
  setDeviceTypeAction,
} from 'stores/device/selectors';
import { useZustandDeviceStore } from 'stores/device/store';
import { handleDeviceDetection } from 'stores/device/utils';

type Props = {
  children: React.ReactElement;
};

const HOCDevice = ({ children }: Props) => {
  const setDevice = useZustandDeviceStore(setDeviceTypeAction);

  const currentDeviceType = useZustandDeviceStore(getDeviceTypeSelector);

  const { pathname } = useLocation();
  const resizer = useCallback(() => {
    if (pathname.includes(ERouteNames.CasinoGame.replaceAll(':gameId', ''))) {
      console.info(pathname, 'PATHNAME');

      return;
    }

    // if()
    const deviceType = handleDeviceDetection();

    if (currentDeviceType !== deviceType) {
      setDevice(deviceType);
    }
  }, [pathname, currentDeviceType]);

  useEffect(() => {
    window.addEventListener('resize', resizer);

    return () => {
      window.removeEventListener('resize', resizer);
    };
  }, [resizer]);

  return children;
};

export default HOCDevice;
