import { useParams } from 'react-router-dom';
import { useGetCasinoGameById } from 'decryption_protocol_hooks';
import { AdaptedGame } from 'decryption_protocol/dist/lib/store/casino/entities/games/types';

import CasinoGameHeaderActions from 'components/CasinoComponents/CasinoGameHeaderActions';
import LaunchGame from 'components/CasinoComponents/CasinoLaunchGame';
import RecommendedGames from 'components/CasinoComponents/CasinoRecommendedGames';
import CircularLoader from 'components/ui/Loaders/Circular';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './CasinoGame.module.css';

const CasinoGamePage = () => {
  const { gameId = 1 } = useParams();

  const game = useHookFabric({
    fn: useGetCasinoGameById,
    customProps: {
      gameId,
    },
  }) as AdaptedGame | null;

  if (!game) {
    return (
      <div className={styles.center}>
        <CircularLoader />
      </div>
    );
  }

  const { providerName, providerId, imageUrl = '', name } = game;

  return (
    <>
      <div className={styles.wrapperGameHeader}>
        <CasinoGameHeaderActions
          providerName={providerName}
          providerId={providerId}
          specialLabel={game.specialLabel}
        />
      </div>

      <LaunchGame
        gameId={+gameId}
        casinoImgSrc={imageUrl}
        name={name}
        providerId={providerId}
      />

      <div className={styles.recommendedWrapper}>
        <RecommendedGames providerId={providerId} />
      </div>
    </>
  );
};

export default CasinoGamePage;
