import cln from 'classnames';
import { PrepareFavoriteEntitiesForSavingProps } from 'decryption_protocol/dist/lib/store/user/entities/favorites/types';
import {
  useIsFavoriteGame,
  useFavoriteController,
} from 'decryption_protocol_hooks';
import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';

import { FavoritesSvg } from 'decryption_component-kit';
import HOCRequestLoader from 'components/HOCs/HOCRequestLoader';
import CircularLoader from 'components/ui/Loaders/Circular';

import { useZustandUserStore } from 'stores/user/personal/store';
import { isAuthorizedUserSelector } from 'stores/user/personal/selectors';
import { useZustandModalStore } from 'stores/modal/store';
import { setModalInfoAction } from 'stores/modal/selectors';

import { ModalTypes } from 'stores/modal/types';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './FavoriteButton.module.css';

type Props = {
  classes?: string;
  gameId: number;
};

const FavoriteButton = (props: Props) => {
  const { classes, gameId } = props;

  const isAuth = useZustandUserStore(isAuthorizedUserSelector);

  const setModalType = useZustandModalStore(setModalInfoAction);

  const isFavGame = useHookFabric({
    fn: useIsFavoriteGame,
    customProps: { gameId },
  }) as boolean;

  const { updateFavorites } = useHookFabric({
    fn: useFavoriteController,
  }) as {
    updateFavorites?: (d: PrepareFavoriteEntitiesForSavingProps) => void;
  };

  const handleUpdateFavorite = () => {
    if (!isAuth) {
      setModalType(ModalTypes.SignIn);

      return;
    }

    updateFavorites?.({
      isGame: true,
      gameId,
    });
  };

  return (
    <HOCRequestLoader
      command="lib"
      requestId={ERequestIds.SaveToFav}
      customLoader={<CircularLoader />}
    >
      <div
        className={cln(styles.buttonBackground, classes)}
        onClick={handleUpdateFavorite}
      >
        <FavoritesSvg
          classes={cln(styles.favBase, {
            [styles.fav]: isFavGame,
          })}
        />
      </div>
    </HOCRequestLoader>
  );
};

export default FavoriteButton;
