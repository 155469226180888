import { create } from 'zustand';
import { ZustandRequestStore } from './types';

export const useZustandRequestStore = create<ZustandRequestStore>(
  (set, get) => ({
    queue: {},

    removeKey: (id) => {
      const { queue } = get();

      delete queue[id];

      set({ queue });
    },
    addKey: (id) => {
      const { queue } = get();

      queue[id] = true;

      set({ queue });
    },

    reset: () => {
      set({ queue: {} });
    },
  })
);
