import { EPixSelector } from '../types';

export const FIRST_SELECTOR = [
  {
    name: 'walletPopUp.forms.cpf',
    id: EPixSelector.Cpf,
  },
  {
    name: 'walletPopUp.forms.cnpj',
    id: EPixSelector.Cnpj,
  },
];

export const SECOND_SELECTOR = [
  {
    name: 'walletPopUp.forms.cpf',
    id: EPixSelector.Cpf,
  },
  {
    name: 'walletPopUp.forms.cnpj',
    id: EPixSelector.Cnpj,
  },
  {
    name: 'walletPopUp.forms.evp',
    id: EPixSelector.Evp,
  },
  {
    name: 'walletPopUp.forms.phone',
    id: EPixSelector.Phone,
  },
  {
    name: 'walletPopUp.forms.email',
    id: EPixSelector.Email,
  },
];
