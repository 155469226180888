import { useBettingSportsById } from 'decryption_protocol_hooks';

import { useHookFabric } from 'hooks/useHookFabric';

type Props = {
  classes?: string;
  sportId: number;
};

const SportName = ({ sportId, classes }: Props) => {
  const sport = useHookFabric({
    fn: useBettingSportsById,
    customProps: {
      sportId,
    },
  });

  return <div className={classes}>{sport?.name}</div>;
};

export default SportName;
