import { useEffect, useState } from 'react';
import * as brazilValidation from 'cpf-cnpj-validator';

import AmountInput from 'components/ui/Input/AmountInput';
import PixAccountInput from 'components/ui/Input/PixInput';
import Input from 'components/ui/Input/Base';

import { setFormsInfoAction } from 'stores/money/forms/selectors';
import { useZustandFormsStore } from 'stores/money/forms/store';
import { setPaymentsCurrentStepAction } from 'stores/money/payments/selectors';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';

import { PaymentChannelType } from 'decryption_protocol/dist/lib/store/user/types';
import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';

import { Steps } from 'stores/money/payments/types';

import styles from '../Withdraw.module.css';
import { DefaultInputType } from 'components/ui/Input/Base/types';
import { DEFAULT_INPUT_VALUE } from 'components/ui/Input/Base/constant';
import { isFilledForm, validationNameSurname } from 'utils/validation';
import InputUserInfo from 'components/ui/Input/InputUserInfo';

type Props = {
  min: number;
  max: number;
};
const PixWithdraw = (props: Props) => {
  const { min, max } = props;

  const setFormInfo = useZustandFormsStore(setFormsInfoAction);

  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  const currencyId = useZustandUserStore(getUserCurrencyIdSelector);

  const [amount, setAmount] = useState<string>('');

  const [name, setName] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [sureName, setSurname] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [cpf, setCpf] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [pixAccount, setPixAccount] = useState<string>('');

  const handleValidateForm =
    (inputName: 'name' | 'familyName' | 'cpf') => (value: string) => {
      if (inputName === 'name' || inputName === 'familyName') {
        const setFn = inputName === 'familyName' ? setSurname : setName;

        setFn(validationNameSurname(value));

        return;
      }

      const isValid = brazilValidation.cpf.isValid(value);
      const errorText = isValid ? '' : 'errors.invalidValue';

      setCpf({ value, errorText });
    };

  useEffect(() => {
    const isFilled = isFilledForm([amount, name, sureName, cpf, pixAccount]);

    if (currencyId && isFilled) {
      setFormInfo({
        isDeposit: false,
        form: {
          currencyId,
          channelId: PaymentChannelType.Pix,
          amount,
          firstName: name.value,
          lastName: sureName.value,
          bankName: '',
          branch: '',
          ifscCode: '',
          accountNumber: pixAccount,
          cpf: cpf.value,
          TRC20Address: '',
          requestId: ERequestIds.WithdrawPix,
        },
      });

      setStep(Steps.FilledForm);
    } else {
      setStep(Steps.Amount);
      setFormInfo(null);
    }
  }, [amount, currencyId, amount, name, sureName, cpf, pixAccount]);

  return (
    <div>
      <AmountInput
        placeholder="walletPopUp.forms.amount"
        //
        isWithdraw
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        size="full"
        onChange={setAmount}
        isPresets={false}
        min={min}
        max={max}
        wrapperClasses={styles.marginInputBottom}
      />

      <InputUserInfo
        isIgnoreUserInfo
        userInfoKey="name"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.name"
        size="full"
        classes={styles.marginInputBottom}
        onChange={setName}
        field={name}
      />

      <InputUserInfo
        isIgnoreUserInfo
        userInfoKey="surname"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.sureName"
        size="full"
        field={sureName}
        onChange={setSurname}
        classes={styles.marginInputBottom}
      />

      <Input
        placeholder="walletPopUp.forms.cpf"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        size="full"
        errorText={cpf.errorText}
        classes={styles.marginInputBottom}
        onChange={handleValidateForm('cpf')}
      />

      <PixAccountInput onChange={setPixAccount} />
    </div>
  );
};

export default PixWithdraw;
