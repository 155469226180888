import cln from 'classnames';

import styles from './CasinoRightSide.module.css';
import RightSideToggler from './components/RightSideToggler';
import CasinoTournament from '../Battles/CasinoTournament';
import ProviderTournaments from '../Battles/CasinoProviderTournaments';

import { useZustandRightSideStore } from 'stores/drawers/rightSide/store';
import {
  getActiveRightSideTabSelector,
  isFullRightSideSelector,
} from 'stores/drawers/rightSide/selectors';

import animation from 'styles/animation.module.css';
import { EActiveRightSideTab } from 'stores/drawers/rightSide/types';
const CasinoRightSide = () => {
  const isView = useZustandRightSideStore(isFullRightSideSelector);

  const activeTab = useZustandRightSideStore(getActiveRightSideTabSelector);

  return (
    <div
      className={cln(styles.casinoRightSideBar, {
        [animation.animationRightSideClosing]: !isView,
        [animation.animationRightSideGrowing]: isView,
        [styles.closed]: !isView,
      })}
    >
      <RightSideToggler />

      {activeTab === EActiveRightSideTab.ActiveBattle ? (
        <CasinoTournament />
      ) : (
        <ProviderTournaments onlyActive />
      )}
    </div>
  );
};

export default CasinoRightSide;
