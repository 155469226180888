import * as Sentry from '@sentry/browser';

export const sentryInitializing = () => {
  if (
    process.env.NODE_ENV !== 'production' ||
    !process.env.REACT_APP_SENTRY_DSN
  ) {
    console.warn('PLEASE PROVIDE KEYS SENTRY');

    return;
  }

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: 'elon-casino@2.3.12',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

    tracesSampleRate: 0.5,

    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};

export const sendSentryError = (text: string) => {
  Sentry.captureException(text);
};
