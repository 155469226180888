export const getDateByFormat = (
  format: string,
  timestamp: number | string
): string => {
  const date = new Date(timestamp || 0);

  const currentDate = new Date();

  const year = date.getFullYear();

  const month = String(date.getMonth() + 1).padStart(2, '0');

  const day = String(date.getDate()).padStart(2, '0');

  const hours = String(date.getHours()).padStart(2, '0');

  const minutes = String(date.getMinutes()).padStart(2, '0');

  const formattedDate = format
    .replace('YYYY', String(year))
    .replace('MM', month)
    .replace('DD', day)
    .replace('HH', hours)
    .replace('mm', minutes);

  return formattedDate;
};

export const formatTimestamp = (
  timestamp: number
): 'times.today' | 'times.tomorrow' | '' => {
  const currentDate = new Date();
  const inputDate = new Date(timestamp);

  // Check if the timestamp is for today
  if (
    inputDate.getDate() === currentDate.getDate() &&
    inputDate.getMonth() === currentDate.getMonth() &&
    inputDate.getFullYear() === currentDate.getFullYear()
  ) {
    return 'times.today';
  }

  // Check if the timestamp is for tomorrow
  const tomorrowDate = new Date(currentDate);

  tomorrowDate.setDate(currentDate.getDate() + 1);

  if (
    inputDate.getDate() === tomorrowDate.getDate() &&
    inputDate.getMonth() === tomorrowDate.getMonth() &&
    inputDate.getFullYear() === tomorrowDate.getFullYear()
  ) {
    return 'times.tomorrow';
  }

  return '';
};

export const formattedDate = (countDown: number): string[] => {
  const days = String(Math.floor(countDown / (1000 * 60 * 60 * 24))).padStart(
    2,
    '0'
  );

  const hours = String(
    Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  ).padStart(2, '0');

  const minutes = String(
    Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
  ).padStart(2, '0');

  const seconds = String(Math.floor((countDown % (1000 * 60)) / 1000)).padStart(
    2,
    '0'
  );

  return [days, hours, minutes, seconds];
};
export const getNextSaturday = (): number => {
  const currentDate = new Date();
  const dayOfWeek = currentDate.getDay();
  const daysUntilNextSaturday = dayOfWeek === 6 ? 7 : 6 - dayOfWeek;
  const nextSaturday = new Date(currentDate);

  nextSaturday.setDate(currentDate.getDate() + daysUntilNextSaturday);
  nextSaturday.setUTCHours(0, 0, 0, 0); // Reset to midnight in UTC

  // Get the user's time zone offset in minutes
  const userTimeZoneOffsetMinutes = nextSaturday.getTimezoneOffset(); // in minutes
  const userTimeZoneOffsetHours = userTimeZoneOffsetMinutes / 60; // convert to hours

  // Adjust to the user's time zone by subtracting the offset
  nextSaturday.setHours(nextSaturday.getHours() + userTimeZoneOffsetHours + 3); // Adjust to 3 AM local time

  return nextSaturday.getTime();
};

export const getPastSaturday = (): number => {
  const currentDate = new Date();
  const dayOfWeek = currentDate.getDay();
  const daysSinceLastSaturday = dayOfWeek;
  const pastSaturday = new Date(currentDate);

  pastSaturday.setDate(currentDate.getDate() - daysSinceLastSaturday);

  pastSaturday.setHours(12, 0, 0, 0);

  return pastSaturday.getTime();
};
