export const COUNTRY_MOCK = [
  { id: 643, code: 'RU', name: 'Russia', dialCode: '+7', imageCode: 'ru' },
  { id: 4, code: 'AFG', name: 'Afghanistan', dialCode: '+93', imageCode: 'af' },
  { id: 8, code: 'ALB', name: 'Albania', dialCode: '+355', imageCode: 'al' },
  { id: 12, code: 'DZA', name: 'Algeria', dialCode: '+213', imageCode: 'dz' },
  {
    id: 16,
    code: 'ASM',
    name: 'American Samoa',
    dialCode: '+1',
    imageCode: 'as',
  },
  { id: 20, code: 'AND', name: 'Andorra', dialCode: '+376', imageCode: 'ad' },
  { id: 24, code: 'AGO', name: 'Angola', dialCode: '+244', imageCode: 'ao' },
  {
    id: 660,
    code: 'AIA',
    name: 'Anguilla',
    dialCode: '+1264',
    imageCode: 'ai',
  },
  {
    id: 10,
    code: 'ATA',
    name: 'Antarctica',
    dialCode: '+672',
    imageCode: 'aq',
  },
  {
    id: 28,
    code: 'ATG',
    name: 'Antigua and Barbuda',
    dialCode: '+1268',
    imageCode: 'ag',
  },
  { id: 32, code: 'ARG', name: 'Argentina', dialCode: '+54', imageCode: 'ar' },
  { id: 51, code: 'ARM', name: 'Armenia', dialCode: '+374', imageCode: 'am' },
  { id: 40, code: 'AUT', name: 'Austria', dialCode: '+43', imageCode: 'at' },
  {
    id: 31,
    code: 'AZE',
    name: 'Azerbaijan',
    dialCode: '+994',
    imageCode: 'az',
  },
  { id: 44, code: 'BHS', name: 'Bahamas', dialCode: '+1242', imageCode: 'bs' },
  { id: 48, code: 'BHR', name: 'Bahrain', dialCode: '+973', imageCode: 'bh' },
  {
    id: 50,
    code: 'BGD',
    name: 'Bangladesh',
    dialCode: '+880',
    imageCode: 'bd',
  },
  { id: 52, code: 'BRB', name: 'Barbados', dialCode: '+1246', imageCode: 'bb' },
  { id: 56, code: 'BEL', name: 'Belgium', dialCode: '+32', imageCode: 'be' },
  { id: 84, code: 'BLZ', name: 'Belize', dialCode: '+501', imageCode: 'bz' },
  { id: 204, code: 'BEN', name: 'Benin', dialCode: '+229', imageCode: 'bj' },
  { id: 60, code: 'BMU', name: 'Bermuda', dialCode: '+1441', imageCode: 'bm' },
  { id: 64, code: 'BTN', name: 'Bhutan', dialCode: '+975', imageCode: 'bt' },
  {
    id: 68,
    code: 'BOL',
    name: 'Bolivia, Plurinational State of',
    dialCode: '+591',
    imageCode: 'bo',
  },
  {
    id: 70,
    code: 'BIH',
    name: 'Bosnia and Herzegovina',
    dialCode: '+387',
    imageCode: 'ba',
  },
  { id: 72, code: 'BWA', name: 'Botswana', dialCode: '+267', imageCode: 'bw' },
  {
    id: 74,
    code: 'BVT',
    name: 'Bouvet Island',
    dialCode: '+47',
    imageCode: 'bv',
  },
  { id: 76, code: 'BRA', name: 'Brazil', dialCode: '+55', imageCode: 'br' },
  {
    id: 86,
    code: 'IOT',
    name: 'British Indian Ocean Territory',
    dialCode: '+246',
    imageCode: 'io',
  },
  {
    id: 96,
    code: 'BRN',
    name: 'Brunei Darussalam',
    dialCode: '+673',
    imageCode: 'bn',
  },
  { id: 100, code: 'BGR', name: 'Bulgaria', dialCode: '+359', imageCode: 'bg' },
  {
    id: 854,
    code: 'BFA',
    name: 'Burkina Faso',
    dialCode: '+226',
    imageCode: 'bf',
  },
  { id: 108, code: 'BDI', name: 'Burundi', dialCode: '+257', imageCode: 'bi' },
  { id: 116, code: 'KHM', name: 'Cambodia', dialCode: '+855', imageCode: 'kh' },
  { id: 120, code: 'CMR', name: 'Cameroon', dialCode: '+237', imageCode: 'cm' },
  { id: 124, code: 'CAN', name: 'Canada', dialCode: '+1', imageCode: 'ca' },
  {
    id: 132,
    code: 'CPV',
    name: 'Cape Verde',
    dialCode: '+238',
    imageCode: 'cv',
  },
  {
    id: 136,
    code: 'CYM',
    name: 'Cayman Islands',
    dialCode: '+345',
    imageCode: 'ky',
  },
  {
    id: 140,
    code: 'CAF',
    name: 'Central African Republic',
    dialCode: '+236',
    imageCode: 'cf',
  },
  { id: 148, code: 'TCD', name: 'Chad', dialCode: '+235', imageCode: 'td' },
  { id: 152, code: 'CHL', name: 'Chile', dialCode: '+56', imageCode: 'cl' },
  { id: 156, code: 'CHN', name: 'China', dialCode: '+86', imageCode: 'cn' },
  {
    id: 162,
    code: 'CXR',
    name: 'Christmas Island',
    dialCode: '+61',
    imageCode: 'cx',
  },
  {
    id: 166,
    code: 'CCK',
    name: 'Cocos (Keeling) Islands',
    dialCode: '+61',
    imageCode: 'cc',
  },
  { id: 170, code: 'COL', name: 'Colombia', dialCode: '+57', imageCode: 'co' },
  { id: 174, code: 'COM', name: 'Comoros', dialCode: '+269', imageCode: 'km' },
  { id: 178, code: 'COG', name: 'Congo', dialCode: '+242', imageCode: 'cg' },
  {
    id: 180,
    code: 'COD',
    name: 'Congo, the Democratic Republic of the',
    dialCode: '+243',
    imageCode: 'cd',
  },
  {
    id: 184,
    code: 'COK',
    name: 'Cook Islands',
    dialCode: '+682',
    imageCode: 'ck',
  },
  {
    id: 188,
    code: 'CRI',
    name: 'Costa Rica',
    dialCode: '+506',
    imageCode: 'cr',
  },
  {
    id: 384,
    code: 'CIV',
    name: 'Cote ',
    dialCode: '+225',
    imageCode: 'ci',
  },
  { id: 191, code: 'HRV', name: 'Croatia', dialCode: '+385', imageCode: 'hr' },
  { id: 192, code: 'CUB', name: 'Cuba', dialCode: '+53', imageCode: 'cu' },
  {
    id: 203,
    code: 'CZE',
    name: 'Czech Republic',
    dialCode: '+420',
    imageCode: 'cz',
  },
  { id: 208, code: 'DNK', name: 'Denmark', dialCode: '+45', imageCode: 'dk' },
  { id: 262, code: 'DJI', name: 'Djibouti', dialCode: '+253', imageCode: 'dj' },
  {
    id: 212,
    code: 'DMA',
    name: 'Dominica',
    dialCode: '+1767',
    imageCode: 'dm',
  },
  {
    id: 214,
    code: 'DOM',
    name: 'Dominican Republic',
    dialCode: '+1849',
    imageCode: 'do',
  },
  { id: 218, code: 'ECU', name: 'Ecuador', dialCode: '+593', imageCode: 'ec' },
  { id: 818, code: 'EGY', name: 'Egypt', dialCode: '+20', imageCode: 'eg' },
  {
    id: 222,
    code: 'SLV',
    name: 'El Salvador',
    dialCode: '+503',
    imageCode: 'sv',
  },
  {
    id: 226,
    code: 'GNQ',
    name: 'Equatorial Guinea',
    dialCode: '+240',
    imageCode: 'gq',
  },
  { id: 232, code: 'ERI', name: 'Eritrea', dialCode: '+291', imageCode: 'er' },
  { id: 233, code: 'EST', name: 'Estonia', dialCode: '+372', imageCode: 'ee' },
  { id: 231, code: 'ETH', name: 'Ethiopia', dialCode: '+251', imageCode: 'et' },
  {
    id: 238,
    code: 'FLK',
    name: 'Falkland Islands (Malvinas)',
    dialCode: '+500',
    imageCode: 'fk',
  },
  {
    id: 234,
    code: 'FRO',
    name: 'Faroe Islands',
    dialCode: '+298',
    imageCode: 'fo',
  },
  { id: 242, code: 'FJI', name: 'Fiji', dialCode: '+679', imageCode: 'fj' },
  { id: 246, code: 'FIN', name: 'Finland', dialCode: '+358', imageCode: 'fi' },

  { id: 266, code: 'GAB', name: 'Gabon', dialCode: '+241', imageCode: 'ga' },
  { id: 270, code: 'GMB', name: 'Gambia', dialCode: '+220', imageCode: 'gm' },
  { id: 268, code: 'GEO', name: 'Georgia', dialCode: '+995', imageCode: 'ge' },
  { id: 276, code: 'DEU', name: 'Germany', dialCode: '+49', imageCode: 'de' },
  { id: 288, code: 'GHA', name: 'Ghana', dialCode: '+233', imageCode: 'gh' },
  {
    id: 292,
    code: 'GIB',
    name: 'Gibraltar',
    dialCode: '+350',
    imageCode: 'gi',
  },
  { id: 300, code: 'GRC', name: 'Greece', dialCode: '+30', imageCode: 'gr' },
  {
    id: 304,
    code: 'GRL',
    name: 'Greenland',
    dialCode: '+299',
    imageCode: 'gl',
  },
  { id: 308, code: 'GRD', name: 'Grenada', dialCode: '+1473', imageCode: 'gd' },
  {
    id: 312,
    code: 'GLP',
    name: 'Guadeloupe',
    dialCode: '+590',
    imageCode: 'gp',
  },
  { id: 316, code: 'GUM', name: 'Guam', dialCode: '+1671', imageCode: 'gu' },
  {
    id: 320,
    code: 'GTM',
    name: 'Guatemala',
    dialCode: '+502',
    imageCode: 'gt',
  },
  { id: 831, code: 'GGY', name: 'Guernsey', dialCode: '+44', imageCode: 'gg' },
  { id: 324, code: 'GIN', name: 'Guinea', dialCode: '+224', imageCode: 'gn' },
  {
    id: 624,
    code: 'GNB',
    name: 'Guinea-Bissau',
    dialCode: '+245',
    imageCode: 'gw',
  },
  { id: 328, code: 'GUY', name: 'Guyana', dialCode: '+595', imageCode: 'gy' },
  { id: 332, code: 'HTI', name: 'Haiti', dialCode: '+509', imageCode: 'ht' },
  {
    id: 334,
    code: 'HMD',
    name: 'Heard Island and McDonald Islands',
    dialCode: '+672',
    imageCode: 'hm',
  },
  {
    id: 336,
    code: 'VAT',
    name: 'Holy See (Vatican City State)',
    dialCode: '+379',
    imageCode: 'va',
  },
  { id: 340, code: 'HND', name: 'Honduras', dialCode: '+504', imageCode: 'hn' },
  {
    id: 344,
    code: 'HKG',
    name: 'Hong Kong',
    dialCode: '+852',
    imageCode: 'hk',
  },
  { id: 348, code: 'HUN', name: 'Hungary', dialCode: '+36', imageCode: 'hu' },
  { id: 352, code: 'ISL', name: 'Iceland', dialCode: '+354', imageCode: 'is' },
  { id: 356, code: 'IND', name: 'India', dialCode: '+91', imageCode: 'in' },
  { id: 360, code: 'IDN', name: 'Indonesia', dialCode: '+62', imageCode: 'id' },
  {
    id: 364,
    code: 'IRN',
    name: 'Iran, Islamic Republic of',
    dialCode: '+98',
    imageCode: 'ir',
  },
  { id: 368, code: 'IRQ', name: 'Iraq', dialCode: '+964', imageCode: 'iq' },
  { id: 372, code: 'IRL', name: 'Ireland', dialCode: '+353', imageCode: 'ie' },
  {
    id: 833,
    code: 'IMN',
    name: 'Isle of Man',
    dialCode: '+44',
    imageCode: 'im',
  },
  { id: 376, code: 'ISR', name: 'Israel', dialCode: '+972', imageCode: 'il' },
  { id: 380, code: 'ITA', name: 'Italy', dialCode: '+39', imageCode: 'it' },
  { id: 388, code: 'JAM', name: 'Jamaica', dialCode: '+1876', imageCode: 'jm' },
  { id: 392, code: 'JPN', name: 'Japan', dialCode: '+81', imageCode: 'jp' },
  { id: 832, code: 'JEY', name: 'Jersey', dialCode: '+44', imageCode: 'je' },
  { id: 400, code: 'JOR', name: 'Jordan', dialCode: '+962', imageCode: 'jo' },
  {
    id: 398,
    code: 'KAZ',
    name: 'Kazakhstan',
    dialCode: '+77',
    imageCode: 'kz',
  },
  { id: 404, code: 'KEN', name: 'Kenya', dialCode: '+254', imageCode: 'ke' },
  { id: 296, code: 'KIR', name: 'Kiribati', dialCode: '+686', imageCode: 'ki' },
  {
    id: 408,
    code: 'PRK',
    name: 'Korea',
    dialCode: '+850',
    imageCode: 'kp',
  },
  {
    id: 410,
    code: 'KOR',
    name: 'Korea, Republic of',
    dialCode: '+82',
    imageCode: 'kr',
  },
  { id: 414, code: 'KWT', name: 'Kuwait', dialCode: '+965', imageCode: 'kw' },
  {
    id: 417,
    code: 'KGZ',
    name: 'Kyrgyzstan',
    dialCode: '+996',
    imageCode: 'kg',
  },
  {
    id: 248,
    code: 'ALA',
    name: 'Åland Islands',
    dialCode: '+358',
    imageCode: 'ax',
  },
  {
    id: 418,
    code: 'LAO',
    name: 'Lao',
    dialCode: '+856',
    imageCode: 'la',
  },
  { id: 428, code: 'LVA', name: 'Latvia', dialCode: '+371', imageCode: 'lv' },
  { id: 422, code: 'LBN', name: 'Lebanon', dialCode: '+961', imageCode: 'lb' },
  { id: 426, code: 'LSO', name: 'Lesotho', dialCode: '+266', imageCode: 'ls' },
  { id: 430, code: 'LBR', name: 'Liberia', dialCode: '+231', imageCode: 'lr' },
  {
    id: 434,
    code: 'LBY',
    name: 'Libyan Arab Jamahiriya',
    dialCode: '+218',
    imageCode: 'ly',
  },
  {
    id: 438,
    code: 'LIE',
    name: 'Liechtenstein',
    dialCode: '+423',
    imageCode: 'li',
  },
  {
    id: 440,
    code: 'LTU',
    name: 'Lithuania',
    dialCode: '+370',
    imageCode: 'lt',
  },
  {
    id: 442,
    code: 'LUX',
    name: 'Luxembourg',
    dialCode: '+352',
    imageCode: 'lu',
  },
  { id: 446, code: 'MAC', name: 'Macao', dialCode: '+853', imageCode: 'mo' },
  {
    id: 807,
    code: 'MKD',
    name: 'Macedonia, the former Yugoslav Republic of',
    dialCode: '+389',
    imageCode: 'mk',
  },
  {
    id: 450,
    code: 'MDG',
    name: 'Madagascar',
    dialCode: '+261',
    imageCode: 'mg',
  },
  { id: 454, code: 'MWI', name: 'Malawi', dialCode: '+265', imageCode: 'mw' },
  { id: 458, code: 'MYS', name: 'Malaysia', dialCode: '+60', imageCode: 'my' },
  { id: 462, code: 'MDV', name: 'Maldives', dialCode: '+960', imageCode: 'mv' },
  { id: 466, code: 'MLI', name: 'Mali', dialCode: '+223', imageCode: 'ml' },
  { id: 470, code: 'MLT', name: 'Malta', dialCode: '+356', imageCode: 'mt' },
  {
    id: 584,
    code: 'MHL',
    name: 'Marshall Islands',
    dialCode: '+692',
    imageCode: 'mh',
  },
  {
    id: 474,
    code: 'MTQ',
    name: 'Martinique',
    dialCode: '+596',
    imageCode: 'mq',
  },
  {
    id: 478,
    code: 'MRT',
    name: 'Mauritania',
    dialCode: '+222',
    imageCode: 'mr',
  },
  {
    id: 480,
    code: 'MUS',
    name: 'Mauritius',
    dialCode: '+230',
    imageCode: 'mu',
  },
  { id: 175, code: 'MYT', name: 'Mayotte', dialCode: '+262', imageCode: 'yt' },
  { id: 484, code: 'MEX', name: 'Mexico', dialCode: '+52', imageCode: 'mx' },
  {
    id: 583,
    code: 'FSM',
    name: 'Micronesia, Federated States of',
    dialCode: '+691',
    imageCode: 'fm',
  },
  {
    id: 498,
    code: 'MDA',
    name: 'Moldova, Republic of',
    dialCode: '+373',
    imageCode: 'md',
  },
  { id: 492, code: 'MCO', name: 'Monaco', dialCode: '+377', imageCode: 'mc' },
  { id: 496, code: 'MNG', name: 'Mongolia', dialCode: '+976', imageCode: 'mn' },
  {
    id: 499,
    code: 'MNE',
    name: 'Montenegro',
    dialCode: '+382',
    imageCode: 'me',
  },
  {
    id: 500,
    code: 'MSR',
    name: 'Montserrat',
    dialCode: '+1664',
    imageCode: 'ms',
  },
  { id: 504, code: 'MAR', name: 'Morocco', dialCode: '+212', imageCode: 'ma' },
  {
    id: 508,
    code: 'MOZ',
    name: 'Mozambique',
    dialCode: '+258',
    imageCode: 'mz',
  },
  { id: 104, code: 'MMR', name: 'Myanmar', dialCode: '+95', imageCode: 'mm' },
  { id: 516, code: 'NAM', name: 'Namibia', dialCode: '+264', imageCode: 'na' },
  { id: 520, code: 'NRU', name: 'Nauru', dialCode: '+674', imageCode: 'nr' },
  { id: 524, code: 'NPL', name: 'Nepal', dialCode: '+977', imageCode: 'np' },

  {
    id: 540,
    code: 'NCL',
    name: 'New Caledonia',
    dialCode: '+687',
    imageCode: 'nc',
  },
  {
    id: 554,
    code: 'NZL',
    name: 'New Zealand',
    dialCode: '+64',
    imageCode: 'nz',
  },
  {
    id: 558,
    code: 'NIC',
    name: 'Nicaragua',
    dialCode: '+505',
    imageCode: 'ni',
  },
  { id: 562, code: 'NER', name: 'Niger', dialCode: '+227', imageCode: 'ne' },
  { id: 566, code: 'NGA', name: 'Nigeria', dialCode: '+234', imageCode: 'ng' },
  { id: 570, code: 'NIU', name: 'Niue', dialCode: '+683', imageCode: 'nu' },

  { id: 578, code: 'NOR', name: 'Norway', dialCode: '+47', imageCode: 'no' },
  { id: 512, code: 'OMN', name: 'Oman', dialCode: '+968', imageCode: 'om' },
  { id: 586, code: 'PAK', name: 'Pakistan', dialCode: '+92', imageCode: 'pk' },
  { id: 585, code: 'PLW', name: 'Palau', dialCode: '+680', imageCode: 'pw' },
  {
    id: 275,
    code: 'PSE',
    name: 'Palestinian Territory, Occupied',
    dialCode: '+970',
    imageCode: 'ps',
  },
  { id: 591, code: 'PAN', name: 'Panama', dialCode: '+507', imageCode: 'pa' },
  {
    id: 598,
    code: 'PNG',
    name: 'Papua New Guinea',
    dialCode: '+675',
    imageCode: 'pg',
  },
  { id: 600, code: 'PRY', name: 'Paraguay', dialCode: '+595', imageCode: 'py' },
  { id: 604, code: 'PER', name: 'Peru', dialCode: '+51', imageCode: 'pe' },
  {
    id: 608,
    code: 'PHL',
    name: 'Philippines',
    dialCode: '+63',
    imageCode: 'ph',
  },
  { id: 612, code: 'PCN', name: 'Pitcairn', dialCode: '+872', imageCode: 'pn' },
  { id: 616, code: 'POL', name: 'Poland', dialCode: '+48', imageCode: 'pl' },
  { id: 620, code: 'PRT', name: 'Portugal', dialCode: '+351', imageCode: 'pt' },
  {
    id: 630,
    code: 'PRI',
    name: 'Puerto Rico',
    dialCode: '+1939',
    imageCode: 'pr',
  },
  { id: 634, code: 'QAT', name: 'Qatar', dialCode: '+974', imageCode: 'qa' },
  { id: 638, code: 'REU', name: 'Reunion', dialCode: '+262', imageCode: 're' },
  { id: 642, code: 'ROU', name: 'Romania', dialCode: '+40', imageCode: 'ro' },

  { id: 646, code: 'RWA', name: 'Rwanda', dialCode: '+250', imageCode: 'rw' },
  {
    id: 652,
    code: 'BLM',
    name: 'Saint Barthelemy',
    dialCode: '+590',
    imageCode: 'bl',
  },
  {
    id: 654,
    code: 'SHN',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    dialCode: '+290',
    imageCode: 'sh',
  },
  {
    id: 659,
    code: 'KNA',
    name: 'Saint Kitts and Nevis',
    dialCode: '+1869',
    imageCode: 'kn',
  },

  {
    id: 666,
    code: 'SPM',
    name: 'Saint Pierre and Miquelon',
    dialCode: '+508',
    imageCode: 'pm',
  },
  {
    id: 670,
    code: 'VCT',
    name: 'Saint Vincent and the Grenadines',
    dialCode: '+1784',
    imageCode: 'vc',
  },
  { id: 882, code: 'WSM', name: 'Samoa', dialCode: '+685', imageCode: 'ws' },
  {
    id: 674,
    code: 'SMR',
    name: 'San Marino',
    dialCode: '+378',
    imageCode: 'sm',
  },
  {
    id: 678,
    code: 'STP',
    name: 'Sao Tome and Principe',
    dialCode: '+239',
    imageCode: 'st',
  },
  {
    id: 682,
    code: 'SAU',
    name: 'Saudi Arabia',
    dialCode: '+966',
    imageCode: 'sa',
  },
  { id: 686, code: 'SEN', name: 'Senegal', dialCode: '+221', imageCode: 'sn' },
  { id: 688, code: 'SRB', name: 'Serbia', dialCode: '+381', imageCode: 'rs' },
  {
    id: 690,
    code: 'SYC',
    name: 'Seychelles',
    dialCode: '+248',
    imageCode: 'sc',
  },
  {
    id: 694,
    code: 'SLE',
    name: 'Sierra Leone',
    dialCode: '+232',
    imageCode: 'sl',
  },
  { id: 702, code: 'SGP', name: 'Singapore', dialCode: '+65', imageCode: 'sg' },
  { id: 703, code: 'SVK', name: 'Slovakia', dialCode: '+421', imageCode: 'sk' },
  { id: 705, code: 'SVN', name: 'Slovenia', dialCode: '+386', imageCode: 'si' },
  {
    id: 90,
    code: 'SLB',
    name: 'Solomon Islands',
    dialCode: '+677',
    imageCode: 'sb',
  },
  { id: 706, code: 'SOM', name: 'Somalia', dialCode: '+252', imageCode: 'so' },
  {
    id: 710,
    code: 'ZAF',
    name: 'South Africa',
    dialCode: '+27',
    imageCode: 'za',
  },
  {
    id: 239,
    code: 'SGS',
    name: 'South Georgia and the South Sandwich Islands',
    dialCode: '+500',
    imageCode: 'gs',
  },

  { id: 144, code: 'LKA', name: 'Sri Lanka', dialCode: '+94', imageCode: 'lk' },
  { id: 736, code: 'SDN', name: 'Sudan', dialCode: '+249', imageCode: 'sd' },
  { id: 740, code: 'SUR', name: 'Suriname', dialCode: '+597', imageCode: 'sr' },
  {
    id: 744,
    code: 'SJM',
    name: 'Svalbard and Jan Mayen',
    dialCode: '+47',
    imageCode: 'sj',
  },
  {
    id: 748,
    code: 'SWZ',
    name: 'Swaziland',
    dialCode: '+268',
    imageCode: 'sz',
  },
  { id: 752, code: 'SWE', name: 'Sweden', dialCode: '+46', imageCode: 'se' },
  {
    id: 756,
    code: 'CHE',
    name: 'Switzerland',
    dialCode: '+41',
    imageCode: 'ch',
  },
  {
    id: 760,
    code: 'SYR',
    name: 'Syrian Arab Republic',
    dialCode: '+963',
    imageCode: 'sy',
  },
  {
    id: 158,
    code: 'TWN',
    name: 'Taiwan, Province of China',
    dialCode: '+886',
    imageCode: 'tw',
  },
  {
    id: 762,
    code: 'TJK',
    name: 'Tajikistan',
    dialCode: '+992',
    imageCode: 'tj',
  },
  {
    id: 834,
    code: 'TZA',
    name: 'Tanzania, United Republic of',
    dialCode: '+255',
    imageCode: 'tz',
  },
  { id: 764, code: 'THA', name: 'Thailand', dialCode: '+66', imageCode: 'th' },
  {
    id: 626,
    code: 'TLS',
    name: 'Timor-Leste',
    dialCode: '+670',
    imageCode: 'tl',
  },
  { id: 768, code: 'TGO', name: 'Togo', dialCode: '+228', imageCode: 'tg' },
  { id: 772, code: 'TKL', name: 'Tokelau', dialCode: '+690', imageCode: 'tk' },
  { id: 776, code: 'TON', name: 'Tonga', dialCode: '+676', imageCode: 'to' },
  {
    id: 780,
    code: 'TTO',
    name: 'Trinidad and Tobago',
    dialCode: '+1868',
    imageCode: 'tt',
  },
  { id: 788, code: 'TUN', name: 'Tunisia', dialCode: '+216', imageCode: 'tn' },
  { id: 792, code: 'TUR', name: 'Turkey', dialCode: '+90', imageCode: 'tr' },
  {
    id: 795,
    code: 'TKM',
    name: 'Turkmenistan',
    dialCode: '+993',
    imageCode: 'tm',
  },
  {
    id: 796,
    code: 'TCA',
    name: 'Turks and Caicos Islands',
    dialCode: '+1649',
    imageCode: 'tc',
  },
  { id: 798, code: 'TUV', name: 'Tuvalu', dialCode: '+688', imageCode: 'tv' },
  { id: 800, code: 'UGA', name: 'Uganda', dialCode: '+256', imageCode: 'ug' },
  { id: 804, code: 'UKR', name: 'Ukraine', dialCode: '+380', imageCode: 'ua' },
  {
    id: 784,
    code: 'ARE',
    name: 'United Arab Emirates',
    dialCode: '+971',
    imageCode: 'ae',
  },
  { id: 858, code: 'URY', name: 'Uruguay', dialCode: '+598', imageCode: 'uy' },
  {
    id: 860,
    code: 'UZB',
    name: 'Uzbekistan',
    dialCode: '+998',
    imageCode: 'uz',
  },
  { id: 548, code: 'VUT', name: 'Vanuatu', dialCode: '+678', imageCode: 'vu' },
  {
    id: 862,
    code: 'VEN',
    name: 'Venezuela, Bolivarian Republic of',
    dialCode: '+58',
    imageCode: 've',
  },
  { id: 704, code: 'VNM', name: 'Viet Nam', dialCode: '+84', imageCode: 'vn' },
  {
    id: 92,
    code: 'VGB',
    name: 'Virgin Islands, British',
    dialCode: '+1284',
    imageCode: 'vg',
  },
  {
    id: 850,
    code: 'VIR',
    name: 'Virgin Islands, U.S.',
    dialCode: '+1340',
    imageCode: 'vi',
  },
  {
    id: 876,
    code: 'WLF',
    name: 'Wallis and Futuna',
    dialCode: '+681',
    imageCode: 'wf',
  },
  {
    id: 732,
    code: 'ESH',
    name: 'Western Sahara',
    dialCode: '+212',
    imageCode: 'eh',
  },
  { id: 887, code: 'YEM', name: 'Yemen', dialCode: '+967', imageCode: 'ye' },
  { id: 894, code: 'ZMB', name: 'Zambia', dialCode: '+260', imageCode: 'zm' },
  { id: 716, code: 'ZWE', name: 'Zimbabwe', dialCode: '+263', imageCode: 'zw' },
];
