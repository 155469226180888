import { useEffect, useState } from 'react';
import { ModalLayout, Text, useTranslate } from 'decryption_component-kit';

import { useLocation } from 'react-router';
import { useGetCountryInfo } from 'decryption_protocol_hooks';
import { CountryInfo } from 'decryption_protocol/dist/lib/store/user/types';

import {
  getActiveRegistrationFormSelector,
  getRegistrationTabsSelector,
  setActiveRegistrationFormAction,
} from 'stores/forms/signUp/selectors';
import { useZustandRegistrationStore } from 'stores/forms/signUp/store';
import { ERegistrationForm } from 'stores/forms/signUp/types';

import ComponentKitTabs from 'components/ui/KitComponents/Tab';
import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';
import OneClickKit from './components/Forms/OneClickKit';
import Email from './components/Forms/Email';
import Phone from './components/Forms/Phone';

import { useZustandModalStore } from 'stores/modal/store';
import {
  closeAndOpenModalAction,
  getModalAdditionalInfoSelector,
} from 'stores/modal/selectors';
import { ModalTypes } from 'stores/modal/types';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './SignUp.module.css';

const SignUp = () => {
  const activeForm = useZustandRegistrationStore(
    getActiveRegistrationFormSelector
  );

  const setRegistrationType = useZustandRegistrationStore(
    setActiveRegistrationFormAction
  );

  const countryInfo = useHookFabric({
    fn: useGetCountryInfo,
  }) as CountryInfo | null;

  const setModal = useZustandModalStore(closeAndOpenModalAction);

  const { getText } = useTranslate('', '');

  const tabs = useZustandRegistrationStore(getRegistrationTabsSelector);

  const { pathname } = useLocation();

  const data = useZustandModalStore(getModalAdditionalInfoSelector) || {};

  const [actualTabs, setActualTabs] = useState<
    {
      label: string;
      value: ERegistrationForm;
    }[]
  >([]);

  const { bonusSignUp = false } = data;

  const openSignIn = () => {
    setModal(ModalTypes.SignIn);
  };

  const onClose = () => {
    setModal(ModalTypes.Unknown);
  };

  useEffect(() => {
    if (tabs.length && activeForm === null) {
      setRegistrationType(tabs[0].value);
    }
  }, [activeForm, tabs]);

  useEffect(() => {
    if (!countryInfo) {
      return;
    }
    let baseTabs = tabs;

    // TODO TURKEY ( remove reg by Phone )
    if (countryInfo?.countryId === 792) {
      baseTabs = baseTabs.filter((t) => t.value !== ERegistrationForm.Phone);
    }

    setActualTabs(baseTabs);
  }, [countryInfo, pathname, tabs]);

  return (
    <ModalLayout
      classes={styles.wrapperReferralFailure}
      popUpHeaderProps={
        !bonusSignUp
          ? {
              transparent: true,
              title: getText('registrations.title'),
            }
          : undefined
      }
      buttonClose={!bonusSignUp ? onClose : undefined}
    >
      <div className={styles.wrapperSignUpModal}>
        {bonusSignUp && (
          <div className={styles.bonusText}>
            <div className={styles.textTitle}>
              <Text idT="wheelTwo.secondPopUp.title" />
            </div>

            <div className={styles.subText}>
              <Text idT="wheelTwo.secondPopUp.subTitle" />
            </div>

            <Text idT="wheelTwo.secondPopUp.bonus" />
          </div>
        )}

        <div className={styles.wrapperTabsAndForm}>
          {activeForm !== null && actualTabs.length !== 1 && (
            <ComponentKitTabs
              activeValue={activeForm}
              data={actualTabs}
              onChange={setRegistrationType}
              classes={styles.tabs}
              type="underline"
            />
          )}

          <div className={styles.zIndex}>
            {activeForm === ERegistrationForm.Email && (
              <Email isSelected={pathname.includes('landing')} />
            )}

            {activeForm === ERegistrationForm.Phone && (
              <Phone isSelected={pathname.includes('landing')} />
            )}
            {activeForm === ERegistrationForm.PhoneWithoutCode && (
              <Phone isSelected={pathname.includes('landing')} withoutCode />
            )}

            {activeForm === ERegistrationForm.OneClick && (
              <OneClickKit isSelected={pathname.includes('landing')} />
            )}
          </div>
        </div>

        <div className={styles.dash} />

        <ComponentKitBaseButton
          size="--button-full"
          color="dark"
          buttonHeight="--button-height-full"
          text="registrations.subTitle"
          classes={styles.signIn}
          handleClick={openSignIn}
          centreText
        />
      </div>
    </ModalLayout>
  );
};

export default SignUp;
