import {
  useZustandLanguagesKitStore,
  languageSelectors,
} from 'decryption_component-kit';

import { initializeApp } from '@firebase/app';
import { getFirestore } from '@firebase/firestore';
import { getMessaging, getToken, onMessage } from '@firebase/messaging';
import { setPushTokenAction } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';

import {
  addUsersToCollection,
  isUserAlreadyInDoc,
  setDataToPushUsers,
} from 'utils/firestore-db';

const firebaseConfig = {
  apiKey: 'AIzaSyBldGMfniRfG9s3bL3e9lADO0ruvX8mt28',
  authDomain: 'todoapp-244bc.firebaseapp.com',
  projectId: 'todoapp-244bc',
  storageBucket: 'todoapp-244bc.appspot.com',
  messagingSenderId: '1000809559189',
  appId: '1:1000809559189:web:0de87040d4a9abba7d579c',
  measurementId: 'G-EZB9G626JM',
};

let firebaseApp = null;

export let firebaseData = null;

export const initFirebase = () => {
  try {
    firebaseApp = initializeApp(firebaseConfig);
    firebaseData = {
      firebaseApp,
      db: getFirestore(firebaseApp),
      messaging: getMessaging(firebaseApp),
    } as any;
  } catch (error) {
    console.error('🚀 ~ initFirebase ~ error:', error);
  }
};

export const setupNotifications = async () => {
  if (!firebaseData) {
    return;
  }
  const { messaging } = firebaseData;

  if (!messaging) {
    return;
  }
  try {
    // Request permission for notifications
    const permission = await Notification.requestPermission();

    if (permission === 'granted') {
      console.warn('Notification permission granted.');

      const token = await getToken(messaging);

      getTokenCb(token);

      console.warn('FCM Token:', token);
    } else {
      console.warn('Notification permission denied.');
    }
    // Handle foreground notifications
    onMessage(messaging, (payload: any) => {
      console.warn('Foreground Message:', payload);
      // Handle the notification or update your UI
    });
  } catch (error) {
    console.error('Error setting up notifications:', error);
  }
};

export const getTokenCb = async (token: any) => {
  if (!token) {
    return;
  }

  const language = languageSelectors.getActiveLanguageNameKitSelector(
    useZustandLanguagesKitStore.getState()
  );

  const action = setPushTokenAction(useZustandUserStore.getState());

  action(token);

  const { exists, _data } = (await isUserAlreadyInDoc(token)) || ({} as any);

  const { lastInteractionTime } = _data || {};

  if (exists) {
    const dateNow = new Date();

    if (!lastInteractionTime) {
      await setDataToPushUsers(token, {
        lastInteractionTime: dateNow.getTime(),
        createTime: _data?.createTime || dateNow.getTime(),
        platform: 'web',
        version: '1.0.2',
        language,
      });

      return;
    }

    const date = new Date(lastInteractionTime);

    const timeDifference = dateNow.getTime() - date.getTime();

    const halfDayInMilliseconds = 12 * 60 * 60 * 1000;

    if (timeDifference > halfDayInMilliseconds) {
      await setDataToPushUsers(token, {
        lastInteractionTime: dateNow.getTime(),
        platform: 'web',
      });
    }

    return;
  }

  const now = new Date().getTime();

  await addUsersToCollection(token, {
    createTime: now,
    token,
    lastInteractionTime: now,
    platform: 'web',
    version: '1.0.2',
    language,
  });
};
