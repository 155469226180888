import { Outlet } from 'react-router-dom';

import NavigationBar from 'components/NavigationBar';
import { HOCUiTypes } from 'components/HOCs/HOCUiTypes';
import Header from 'components/Header';

import styles from './MobileLayout.module.css';

const MobileLayout = () => (
  <HOCUiTypes>
    <>
      <Header />

      <div className={styles.wrapperContent}>
        <Outlet />
      </div>

      <NavigationBar />
    </>
  </HOCUiTypes>
);

export default MobileLayout;
