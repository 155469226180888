import { ZustandCalendarFilterStoreStore } from './types';

export const getSelectedRangeSelector = (
  state: ZustandCalendarFilterStoreStore
) => state.times;

// ACTIONS

export const setTimeRangeAction = (state: ZustandCalendarFilterStoreStore) =>
  state.setRange;
