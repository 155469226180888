import cln from 'classnames';

import styles from './StakeInfo.module.css';

type Props = {
  classes?: string;
  stake: string | number;
  winAmount: number;
  acceptedOdd?: number;
};

const StakeInfo = ({ classes, stake, winAmount, acceptedOdd }: Props) => (
  <div className={cln(styles.wrapperStakeInfo, classes)}>
    <div>
      <div className={styles.title}>T.Stake</div>
      <div className={styles.value}>{stake}</div>
    </div>
    {acceptedOdd && (
      <div>
        <div className={styles.title}>T.acceptedOdd</div>
        <div className={styles.value}>{acceptedOdd}</div>
      </div>
    )}
    <div>
      <div className={styles.title}>T.max return</div>
      <div className={styles.value}>{winAmount}</div>
    </div>
  </div>
);

export default StakeInfo;
