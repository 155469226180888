import cln from 'classnames';
import ReactDOM from 'react-dom';

import styles from './BankListPopUp.module.css';
import animation from 'styles/animation.module.css';
import { useEffect, useState } from 'react';
import ModalTitle from 'components/ui/ModalTitle';
import { BANK_IDS, TURKISH_BANKS } from '../../constants';
import { BankIdInfo, TurkishBanks } from '../../types';
import HOCImageWithDefault from 'components/HOCs/HOCImageWithDefault';

type Props = {
  isShow: boolean;
  closeList: () => void;
  activeId: number;
  setSelectedBankId: (d: number) => void;
  banks: (TurkishBanks | BankIdInfo)[];
};

const BankListPopUp = (props: Props) => {
  const { isShow, closeList, activeId, setSelectedBankId, banks } = props;

  const [firstRender, setFirstRender] = useState(true);

  const preventFn = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.preventDefault();

    if (isShow) {
      closeList();
    }
  };

  const handleSetActiveId = (id: number) => () => {
    setSelectedBankId(id);
    closeList();
  };

  useEffect(() => {
    if (isShow) {
      setFirstRender(false);
    }
  }, [isShow]);

  return ReactDOM.createPortal(
    <div
      className={cln(styles.wrapperBankListPopUp, {
        [animation.showPopUpBankList]: isShow,
        [animation.hidePopUpBankList]: !isShow && !firstRender,
      })}
    >
      <div
        className={cln(styles.content, {
          // [animation.modalOpenMobileAnimation]: isShow,
          // [animation.modalCloseMobilePopUpAnimation]: !isShow && !firstRender,
        })}
      >
        <ModalTitle
          name="walletPopUp.forms.chooseBank"
          closeBtnFn={preventFn}
        />

        <div className={styles.list}>
          {banks.map((i, inx) => (
            <div
              key={i.id}
              className={styles.item}
              onClick={handleSetActiveId(i.id)}
            >
              <div
                className={cln(styles.name, {
                  [styles.activeName]: activeId === i.id,
                })}
              >
                {i.name}{' '}
                {!!i.maxLimit && (
                  <div
                    className={styles.limits}
                  >{`${i?.minLimit}-${i?.maxLimit}`}</div>
                )}
              </div>
              {i.src && (
                <HOCImageWithDefault classes={styles.img} src={i.src}>
                  <div className={styles.img} />
                </HOCImageWithDefault>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default BankListPopUp;
