import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  CasinoSubCategoriesButton,
  useTranslate,
} from 'decryption_component-kit';
import { useCasinoSubCategories } from 'decryption_protocol_hooks';

import { ERouteNames } from 'Routes/types';

import { useHookFabric } from 'hooks/useHookFabric';

// TODO: use from LIB
type CasinoSubCategory = {
  categoryId: number;
  gameIds: number[];
  name: string;
  sortId: number;
  subCategoryId: number;
};

const CasinoSubCategories = () => {
  const { subCategoryId = 'all', categoryId = null } = useParams();

  const { getText } = useTranslate('', '');

  const subCategories = useHookFabric({
    fn: useCasinoSubCategories,
    customProps: {
      categoryId,
    },
  }) as CasinoSubCategory[];

  const navigate = useNavigate();

  const handleSetActiveSubCategory = (id: string | number | null) => () => {
    navigate(
      ERouteNames.CasinoCategories.replaceAll(':categoryId', `${categoryId}`)
        .replace(':subCategoryId', `${id || 'all'}`)
        .replaceAll(':page', '1')
        .replaceAll(':scroll', '0')
    );
  };

  const isAll = subCategoryId === 'all';

  const data = useMemo(
    () =>
      subCategories.map((c) => ({
        ...c,
        id: c.subCategoryId,
        name: getText(c.name),
      })),
    [subCategories, getText]
  );

  return (
    <CasinoSubCategoriesButton
      handleItemClick={handleSetActiveSubCategory}
      data={data}
      activeId={isAll ? null : subCategoryId}
      withAll
    />
  );
};

export default CasinoSubCategories;
