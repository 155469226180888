import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import {
  ProviderNameButton,
  WagerSvg,
  TournamentWagerSvg,
} from 'decryption_component-kit';
import { useWagerBonuses } from 'decryption_protocol_hooks';
import { EAdaptedGameSpecialLabel } from 'decryption_protocol/dist/lib/store/casino/entities/games/types';

import { IS_VERTICAL } from 'constants/ui';

import { ERouteNames } from 'Routes/types';

import { useHookFabric } from 'hooks/useHookFabric';

type Props = {
  providerName: string;
  classes?: string;
  providerId: number;
  justIcon?: boolean;
  specialLabel?: EAdaptedGameSpecialLabel;
};

const ProviderNameKit = (props: Props) => {
  const hasAnyWagerBonuses = useHookFabric({ fn: useWagerBonuses });

  const {
    providerId,
    providerName,
    classes,
    specialLabel,
    justIcon = IS_VERTICAL,
  } = props;

  const navigate = useNavigate();

  const jumpTo = () => {
    navigate(
      ERouteNames.CasinoProviderGames.replaceAll(':id', `${providerId}`)
    );
  };

  const isSpecialIcon =
    hasAnyWagerBonuses && specialLabel !== EAdaptedGameSpecialLabel.Unknown;

  const Icon = useMemo(() => {
    if (!isSpecialIcon || !specialLabel) {
      return null;
    }

    const WAGER_INFO = [
      EAdaptedGameSpecialLabel.MultiWagering,
      EAdaptedGameSpecialLabel.WagerBonus,
    ];

    if (WAGER_INFO.includes(specialLabel)) {
      return WagerSvg;
    }

    return TournamentWagerSvg;
  }, [isSpecialIcon, specialLabel]);

  return (
    <ProviderNameButton
      classes={classes}
      jumpTo={jumpTo}
      SpecIcon={Icon}
      providerName={justIcon ? '' : providerName} //
    />
  );
};

export default ProviderNameKit;
