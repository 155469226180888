import { ModalLayout, Text, useTranslate } from 'decryption_component-kit';

import { ModalTypes } from 'stores/modal/types';
import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';

import styles from './Rules.module.css';

const DEFAULT_RULES_KEY = new Array(11).fill('');

const TournamentRules = () => {
  const { name: title } = useTranslate('tournament.rules.title', '');

  const closeModal = useZustandModalStore(closeAndOpenModalAction);

  const onClose = () => {
    closeModal(ModalTypes.Unknown);
  };

  return (
    <ModalLayout
      popUpHeaderProps={{
        title,
      }}
      buttonClose={onClose}
    >
      <>
        <div className={styles.ruleContent}>
          {DEFAULT_RULES_KEY.map((_, indx) => (
            <div key={indx} className={styles.ruleBlock}>
              <div className={styles.point}>{indx + 1}.</div>
              <div className={styles.ruleText}>
                <Text idT={`tournament.rules.${indx + 1}`} />
              </div>
            </div>
          ))}
        </div>
      </>
    </ModalLayout>
  );
};

export default TournamentRules;
