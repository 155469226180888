import React, { useMemo, useState } from 'react';
import { useTransportControllers } from 'decryption_protocol_hooks';
import { ModalLayout, useTranslate } from 'decryption_component-kit';
import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';

import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';
import Input from 'components/ui/Input/Base';
import HOCRequestLoader from 'components/HOCs/HOCRequestLoader';

import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { useZustandModalStore } from 'stores/modal/store';

import { ModalTypes } from 'stores/modal/types';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './SetNewPassword.module.css';

const SetNewPassword = () => {
  const [newPassword, setNewPassword] = useState<string>('');

  const { sendCommand } = useHookFabric({ fn: useTransportControllers });

  const { name: title } = useTranslate(
    'profile.content.myAccount.form.setNewPassword',
    ''
  );

  const closeModal = useZustandModalStore(closeAndOpenModalAction);

  const handleCloseModal = () => {
    closeModal(ModalTypes.Unknown);
  };

  const isValidPsw = useMemo(() => newPassword.length > 8, [newPassword]);

  const handleApplyPsw = () => {
    sendCommand('21x', {
      newPassword,
      requestId: ERequestIds.SetNewPassword,
    });
  };

  return (
    <ModalLayout
      popUpHeaderProps={{
        transparent: true,
        title,
      }}
      buttonClose={handleCloseModal}
    >
      <>
        <Input
          placeholder="profile.content.myAccount.form.newPassword"
          size="full"
          onChange={setNewPassword}
          classes={styles.marginB}
          type="password"
        />

        <HOCRequestLoader
          command="\\21x"
          requestId={ERequestIds.SetNewPassword}
        >
          <ComponentKitBaseButton
            disabled={!isValidPsw}
            text="profile.content.myAccount.form.submit"
            size="--button-full"
            color="primary"
            buttonHeight="--button-height-l"
            handleClick={handleApplyPsw}
            centreText
          />
        </HOCRequestLoader>
      </>
    </ModalLayout>
  );
};

export default SetNewPassword;
