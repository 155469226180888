export const LANGUAGES = 'en'; // key for getting languages from local storage

export const TOKEN = 'token';

export const TOKEN_OLD_SUPPORT = 'at1';

export const DEVELOPMENT_MODE_NAME = 'developmentMode';

export const CUSTOM_SOCKET_NAME = 'custom-socket-name';

export const MANUAL_BET = 'manual-bet';

export const MOCK_PAYMENT = 'mock-payments';

export const CUSTOM_FAST_CURRENCY_ID = 'custom_currency_id_fast_reg'; // 356 INR

export const PWA = 'pwa';

export const PWA_CLEAR = 'pwa-clear'; // key important value it will version

export const INSTA_STORIES = 'insId';
