import cln from 'classnames';

import styles from './Presets.module.css';
import { getUserCurrencyNameSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';

type Props = {
  min: number;
  max: number;
  handleSetAmount: (s: string) => void;
  activeAmount: string;
};

const Presets = (props: Props) => {
  const { min, max, activeAmount, handleSetAmount } = props;

  const currencyName = useZustandUserStore(getUserCurrencyNameSelector);

  const handleApply = (v: number) => () => {
    handleSetAmount(`${v}`);
  };

  return (
    <div className={styles.wrapperPresets}>
      <div
        className={cln(styles.preset, {
          [styles.active]: +activeAmount === min,
        })}
        onClick={handleApply(min)}
      >
        {currencyName} {min}
      </div>

      <div
        className={cln(styles.preset, {
          [styles.active]: +activeAmount === max,
        })}
        onClick={handleApply(max)}
      >
        {currencyName} {max}
      </div>
    </div>
  );
};

export default Presets;
