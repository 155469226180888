import { EKYCStatus, ZustandUserStore } from './types';

export const getUserInfoSelector = (state: ZustandUserStore) => state.userInfo;

export const isAuthorizedUserSelector = (state: ZustandUserStore) =>
  Boolean(getUserInfoSelector(state));

export const getUserCurrencyIdSelector = (state: ZustandUserStore) =>
  getUserInfoSelector(state)?.currencyId;

export const getUserCurrencyNameSelector = (state: ZustandUserStore) =>
  getUserInfoSelector(state)?.currencyCode || '$';

export const getClientIdSelector = (state: ZustandUserStore) =>
  getUserInfoSelector(state)?.clientId;

export const getUserTokenSelector = (state: ZustandUserStore) =>
  getUserInfoSelector(state)?.token;

export const getUserModifyPropertySelector = (state: ZustandUserStore) => {
  const user = getUserInfoSelector(state);

  const property = { isEmail: false, isPassword: false, isPhone: false };

  if (!user) {
    return property;
  }

  return { isEmail: !user.email, isPassword: true, isPhone: !user.phoneNumber };
};

export const getUserKYCStatusSelector = (state: ZustandUserStore) =>
  state.kycStatus;

export const isNeedToKycSelector = (state: ZustandUserStore) => {
  const status = getUserKYCStatusSelector(state);

  return (
    status === EKYCStatus.NeedKYC ||
    status === EKYCStatus.Rejected ||
    status === EKYCStatus.Pending
  );
};
export const isIgnoreKycStatusSelector = (state: ZustandUserStore) => {
  const status = getUserKYCStatusSelector(state);

  return status === EKYCStatus.Ignore;
};

export const getUserNameSelector = (state: ZustandUserStore) => {
  const user = getUserInfoSelector(state);

  if (!user?.name) {
    return user?.clientId;
  }

  return `${user?.name || ''} ${user?.surname || ''}`;
};

export const getTokenForAuthorizationSelector = (state: ZustandUserStore) =>
  state.permanentToken;

export const getPushTokenSelector = (state: ZustandUserStore) =>
  state.pushToken;

// =========================== Action ===========================

export const setPushTokenAction = (state: ZustandUserStore) =>
  state.setPushToken;

export const setUserInfoAction = (state: ZustandUserStore) => state.setUserInfo;

export const logoutAction = (state: ZustandUserStore) => state.logout;

export const updateUserInfoAction = (state: ZustandUserStore) =>
  state.updateUserInfo;

export const setPermanentTokenAction = (state: ZustandUserStore) =>
  state.setPermanentToken;
