import React from 'react';
import { ModalLayout, useTranslate } from 'decryption_component-kit';

import styles from './JackpotsRules.module.css';
import { useZustandModalStore } from 'stores/modal/store';
import { setModalInfoAction } from 'stores/modal/selectors';
import { ModalTypes } from 'stores/modal/types';

const JackpotsRules = () => {
  const { name: title } = useTranslate('kgames.jackpotRules.title', '');

  const closeModal = useZustandModalStore(setModalInfoAction);

  const onCloseModal = () => {
    closeModal(ModalTypes.Unknown);
  };

  return (
    <ModalLayout
      classes={styles.wrapperJackpotsRules}
      popUpHeaderProps={{
        title,
        classes: styles.disableMarginB,
      }}
      buttonClose={onCloseModal}
    >
      <div>Jackpots</div>
    </ModalLayout>
  );
};

export default JackpotsRules;
