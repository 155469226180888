import { useEffect, useState } from 'react';
import * as brazil from 'cpf-cnpj-validator';

import AmountInput from 'components/ui/Input/AmountInput';
import Input from 'components/ui/Input/Base';
import { DefaultInputType } from 'components/ui/Input/Base/types';
import { DEFAULT_INPUT_VALUE } from 'components/ui/Input/Base/constant';
import { Selector } from 'decryption_component-kit';

import { setFormsInfoAction } from 'stores/money/forms/selectors';
import { useZustandFormsStore } from 'stores/money/forms/store';
import { setPaymentsCurrentStepAction } from 'stores/money/payments/selectors';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';
import { Steps } from 'stores/money/payments/types';

import {
  composeValidations,
  isFilledForm,
  isRequired,
  validationBankCode,
} from 'utils/validation';

import { FIRST_SELECTOR, SECOND_SELECTOR } from './constants';

import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';

import { PaymentChannelType } from 'decryption_protocol/dist/lib/store/user/types';

import styles from '../Withdraw.module.css';
import PixAccountInput from 'components/ui/Input/PixInput';
import { EPixSelector } from '../types';
import InputUserInfo from 'components/ui/Input/InputUserInfo';

type Props = {
  min: number;
  max: number;
};

const PixBankTransferWithdraw = (props: Props) => {
  const { min, max } = props;

  const setFormInfo = useZustandFormsStore(setFormsInfoAction);

  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  const currencyId = useZustandUserStore(getUserCurrencyIdSelector);

  const [activeFirstSelectorValue, setFirstSelectorValue] = useState(
    EPixSelector.Cpf
  );

  const [activeSecondSelectorValue, setSecondSelectorValue] = useState(
    EPixSelector.Savings
  );

  const [amount, setAmount] = useState('');

  const [name, setName] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [sureName, setSurname] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [document, setDocumentId] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [pixAccount, setPixAccount] = useState<string>('');

  const [bankCode, setBankCode] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [bankBranchCode, setBankBranchCode] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [bankAccount, setBankAccount] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const validationDocument = (value: string, type: EPixSelector) => {
    const validFn =
      type === EPixSelector.Cpf ? brazil.cpf.isValid : brazil.cnpj.isValid;

    const isValid = validFn(value);

    setDocumentId({
      value,
      errorText: !isValid ? 'errors.invalidValue' : '',
    });
  };

  const revalidate = (t: EPixSelector) => {
    setFirstSelectorValue(t);
    validationDocument(document.value, t);
  };

  const handleChangeInput =
    (
      type:
        | 'document'
        | 'pixAccount'
        | 'bankCode'
        | 'bankBranchCode'
        | 'bankAccount'
    ) =>
    (value: string) => {
      if (type === 'document') {
        return validationDocument(value, activeFirstSelectorValue);
      }

      if (type === 'bankCode') {
        setBankCode(validationBankCode(value, 'errors.invalidValue'));
      }

      if (type === 'bankBranchCode') {
        const errorText = composeValidations([
          isRequired({ value, errorText: 'errors.required' }),
        ]);

        setBankBranchCode({
          value,
          errorText,
        });
      }

      if (type === 'bankAccount') {
        const errorText = composeValidations([
          isRequired({ value, errorText: 'errors.required' }),
        ]);

        setBankAccount({
          value,
          errorText,
        });
      }
    };

  useEffect(() => {
    const isFilled = isFilledForm([
      amount,
      name,
      sureName,
      document,
      pixAccount,
      bankAccount,
      bankBranchCode,
      bankCode,
    ]);

    if (currencyId && isFilled) {
      setFormInfo({
        isDeposit: false,
        form: {
          currencyId: currencyId,
          channelId: PaymentChannelType.BankTransfer,
          name: name.value,
          documentId: document.value,
          documentType:
            `${EPixSelector[activeFirstSelectorValue]}`.toUpperCase(),
          account: bankAccount.value,
          accountType:
            `${EPixSelector[activeSecondSelectorValue]}`.toUpperCase(),
          bankCode: bankCode.value,
          branch: bankBranchCode.value,
          accountDigit: pixAccount,
          branchDigit: bankAccount.value,
          amount,
          requestId: ERequestIds.PixBankTransferWithdraw,
        },
      });

      setStep(Steps.FilledForm);
    } else {
      setStep(Steps.Amount);
      setFormInfo(null);
    }
  }, [
    activeFirstSelectorValue,
    activeSecondSelectorValue,
    amount,
    name,
    sureName,
    document,
    pixAccount,
    bankAccount,
    bankBranchCode,
    bankCode,
  ]);

  return (
    <div>
      <AmountInput
        isWithdraw
        placeholder="walletPopUp.forms.amount"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        size="full"
        onChange={setAmount}
        isPresets={false}
        min={min}
        max={max}
        wrapperClasses={styles.marginInputBottom}
      />
      <InputUserInfo
        userInfoKey="name"
        isIgnoreUserInfo
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.name"
        size="full"
        classes={styles.marginInputBottom}
        onChange={setName}
        field={name}
      />

      <InputUserInfo
        userInfoKey="surname"
        isIgnoreUserInfo
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.sureName"
        size="full"
        field={sureName}
        onChange={setSurname}
        classes={styles.marginInputBottom}
      />
      <Selector
        placeholder=""
        activeId={activeFirstSelectorValue}
        handleClick={revalidate}
        data={FIRST_SELECTOR}
        classes={styles.marginInputBottom}
        size="full"
      />
      <Input
        placeholder="walletPopUp.forms.document"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        size="full"
        classes={styles.marginInputBottom}
        errorText={document.errorText}
        onChange={handleChangeInput('document')}
      />
      <Input
        placeholder="walletPopUp.forms.bankCode"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        size="full"
        classes={styles.marginInputBottom}
        errorText={bankCode.errorText}
        onChange={handleChangeInput('bankCode')}
      />
      <Input
        placeholder="walletPopUp.forms.bankBranchCode"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        size="full"
        classes={styles.marginInputBottom}
        errorText={bankBranchCode.errorText}
        onChange={handleChangeInput('bankBranchCode')}
      />

      <Selector
        placeholder=""
        activeId={activeSecondSelectorValue}
        handleClick={setSecondSelectorValue}
        data={SECOND_SELECTOR}
        classes={styles.marginInputBottom}
        isOpenUp
        size="full"
      />

      <Input
        placeholder="walletPopUp.forms.bankAccount"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        size="full"
        classes={styles.marginInputBottom}
        errorText={bankAccount.errorText}
        onChange={handleChangeInput('bankAccount')}
      />

      <PixAccountInput
        onChange={setPixAccount}
        classes={styles.marginInputBottom}
      />
    </div>
  );
};

export default PixBankTransferWithdraw;
