import { ESports } from 'decryption_protocol/dist/lib/store/betting/entities/sports/types';
import { EAppType } from 'decryption_component-kit/dist/lib/types/ui';
import {
  JabiInHouseSvg,
  ElonHouseSvg,
  FSWinHouseSvg,
  // Loyalty
  BronzeSvg,
  SilverSvg,
  DiamondSvg,
  GoldSvg,
  GreenSvg,
  PlatinumSvg,
  PlatinumOneSvg,
  // Flags
  AzeSvg,
  BnSvg,
  BsSvg,
  DeSvg,
  EnSvg,
  EsSvg,
  FrSvg,
  HiSvg,
  ItSvg,
  KoSvg,
  KzSvg,
  NoSvg,
  PtSvg,
  RoSvg,
  RuSvg,
  TrSvg,
  UkrSvg,
  UzSvg,
  // BETTING
  AmericanFootballSvg,
  AussieRulesSvg,
  BadmintonSvg,
  BaseballSvg,
  BasketballSvg,
  Basketball3x3Svg,
  BeachSoccerSvg,
  BoxingSvg,
  ChessSvg,
  CricketSvg,
  DartsSvg,
  EFifaSvg,
  ENbaSvg,
  ENhlSvg,
  EsportSvg,
  FieldHockeySvg,
  FutsalSvg,
  IceHockeySvg,
  KabaddiSvg,
  MmaSvg,
  RugbySvg,
  SnookerSvg,
  SoccerSvg,
  SquashSvg,
  TableTennisSvg,
  TennisSvg,
  VolleyballSvg,
} from 'decryption_component-kit';

import { ReactComponent as XGamingSvg } from 'assets/casino/providers/1x2gaming.svg';
import { ReactComponent as PragmaticPlaySvg } from 'assets/casino/providers/progmatic-play.svg';
import { ReactComponent as AdvantPlaySvg } from 'assets/casino/providers/advantplay.svg';
import { ReactComponent as TadaSvg } from 'assets/casino/providers/tada.svg';
import { ReactComponent as MrSlottySvg } from 'assets/casino/providers/mrslotty.svg';
import { ReactComponent as BigTimeGamingBrandedSvg } from 'assets/casino/providers/bigtimegamingbranded.svg';
import { ReactComponent as EspressoGamesSvg } from 'assets/casino/providers/espressogames.svg';
import { ReactComponent as GamevySvg } from 'assets/casino/providers/gamevy.svg';
import { ReactComponent as GamesGlobalSvg } from 'assets/casino/providers/gamesglobal.svg';
import { ReactComponent as RevolverGamingPlaySvg } from 'assets/casino/providers/revolvergaming.svg';
import { ReactComponent as QuickSpinSvg } from 'assets/casino/providers/quickspin.svg';
import { ReactComponent as SkyWindLiveSvg } from 'assets/casino/providers/skywindlive.svg';
import { ReactComponent as NetentSvg } from 'assets/casino/providers/netent.svg';
import { ReactComponent as CtGamingSvg } from 'assets/casino/providers/ctgaming.svg';
import { ReactComponent as BigTimeGamingSvg } from 'assets/casino/providers/bigtimegaming.svg';
import { ReactComponent as BetSolutionSvg } from 'assets/casino/providers/betSolution.svg';
import { ReactComponent as RedRakeSvg } from 'assets/casino/providers/redrake.svg';
import { ReactComponent as WazdanSvg } from 'assets/casino/providers/wazdan.svg';
import { ReactComponent as EurasianGamingSvg } from 'assets/casino/providers/eurasiangaming.svg';
import { ReactComponent as BetradarSvg } from 'assets/casino/providers/betradar.svg';
import { ReactComponent as OneTouchSvg } from 'assets/casino/providers/onetouch.svg';
import { ReactComponent as BetGamesSvg } from 'assets/casino/providers/betgames.svg';
import { ReactComponent as PushGamingSvg } from 'assets/casino/providers/pushgaming.svg';
import { ReactComponent as High5Svg } from 'assets/casino/providers/high5.svg';
import { ReactComponent as HolleGamesSvg } from 'assets/casino/providers/holleGames.svg';
import { ReactComponent as IgTechSvg } from 'assets/casino/providers/igtech.svg';
import { ReactComponent as InfinGameSvg } from 'assets/casino/providers/infingame.svg';
import { ReactComponent as JaderabbitSvg } from 'assets/casino/providers/jaderabbit.svg';
import { ReactComponent as JdbSvg } from 'assets/casino/providers/jdb.svg';
import { ReactComponent as LuckySvg } from 'assets/casino/providers/lucky.svg';
import { ReactComponent as GrooveSvg } from 'assets/casino/providers/groove.svg';
import { ReactComponent as GamingCorpsSvg } from 'assets/casino/providers/gamingCorps.svg';
import { ReactComponent as OneSpin4WinSvg } from 'assets/casino/providers/1spin4win.svg';
import { ReactComponent as AbsoluteLiveGamingSvg } from 'assets/casino/providers/absoluteLiveGaming.svg';
import { ReactComponent as BlueBirdGamingSvg } from 'assets/casino/providers/bluebird.svg';
import { ReactComponent as QtechSvg } from 'assets/casino/providers/qtech.svg';
import { ReactComponent as FiveMenSvg } from 'assets/casino/providers/5men.svg';
import { ReactComponent as AtmosferaSvg } from 'assets/casino/providers/atmosfera.svg';
import { ReactComponent as AvatarUxSvg } from 'assets/casino/providers/avatarUx.svg';
import { ReactComponent as BeterLiveSvg } from 'assets/casino/providers/beterLive.svg';
import { ReactComponent as ZillonGamesSvg } from 'assets/casino/providers/zillonGames.svg';
import { ReactComponent as SpinzaSvg } from 'assets/casino/providers/spinza.svg';
import { ReactComponent as ReevoSvg } from 'assets/casino/providers/reevo.svg';
import { ReactComponent as PlayNGoSvg } from 'assets/casino/providers/playNGo.svg';
import { ReactComponent as PlaytechSvg } from 'assets/casino/providers/playtech.svg';
import { ReactComponent as PopipaySvg } from 'assets/casino/providers/popipay.svg';
import { ReactComponent as AmaticSvg } from 'assets/casino/providers/amatic.svg';
import { ReactComponent as AmigoGamingSvg } from 'assets/casino/providers/amigoGaming.svg';
import { ReactComponent as AmusnetSvg } from 'assets/casino/providers/amusnet.svg';
import { ReactComponent as AviatrixSvg } from 'assets/casino/providers/aviatrix.svg';
import { ReactComponent as BGamingSvg } from 'assets/casino/providers/b-gaming.svg';
import { ReactComponent as BelatraSvg } from 'assets/casino/providers/belatra.svg';
import { ReactComponent as BetSoftSvg } from 'assets/casino/providers/bet-soft.svg';
import { ReactComponent as BluePrintSvg } from 'assets/casino/providers/blueprint.svg';
import { ReactComponent as BoomingGamesSvg } from 'assets/casino/providers/booming-games.svg';
import { ReactComponent as CaletaSvg } from 'assets/casino/providers/caleta.svg';
import { ReactComponent as CasinoTechnologySvg } from 'assets/casino/providers/casinoTechnology.svg';
import { ReactComponent as CharismaticSvg } from 'assets/casino/providers/charismatic.svg';
import { ReactComponent as ConceptGamingSvg } from 'assets/casino/providers/concept-gaming.svg';
import { ReactComponent as ElBetSvg } from 'assets/casino/providers/el-bet.svg';
import { ReactComponent as ElysiumStudiosSvg } from 'assets/casino/providers/elysiumStudios.svg';
import { ReactComponent as EndorfinaSvg } from 'assets/casino/providers/endorfina.svg';
import { ReactComponent as EurasianGamingBingoSvg } from 'assets/casino/providers/eurasian-gaming-bingo.svg';
import { ReactComponent as EurasianGamingSlotsSvg } from 'assets/casino/providers/eurasian-gaming-slots.svg';
import { ReactComponent as EvolutionSvg } from 'assets/casino/providers/evolution.svg';
import { ReactComponent as EvoplaySvg } from 'assets/casino/providers/evoplay.svg';
import { ReactComponent as EzugiSvg } from 'assets/casino/providers/ezugi.svg';
import { ReactComponent as FachaiSvg } from 'assets/casino/providers/fachai.svg';
import { ReactComponent as FantasmaSvg } from 'assets/casino/providers/fantasma.svg';
import { ReactComponent as FastspinSvg } from 'assets/casino/providers/fastspin.svg';
import { ReactComponent as FaziSvg } from 'assets/casino/providers/fazi.svg';
import { ReactComponent as GamanzaSvg } from 'assets/casino/providers/gamanza.svg';
import { ReactComponent as GameArtSvg } from 'assets/casino/providers/game-art.svg';
import { ReactComponent as GameBetSvg } from 'assets/casino/providers/game-bet.svg';
import { ReactComponent as GamzixSvg } from 'assets/casino/providers/gamzix.svg';
import { ReactComponent as GoldenRaceSvg } from 'assets/casino/providers/goldenRace.svg';
import { ReactComponent as HabeneroSvg } from 'assets/casino/providers/habenero.svg';
import { ReactComponent as HacksawGamingSvg } from 'assets/casino/providers/hacksawgaming.svg';
import { ReactComponent as HgSvg } from 'assets/casino/providers/hg.svg';
import { ReactComponent as IgrosoftSvg } from 'assets/casino/providers/igrosoft.svg';
import { ReactComponent as JetGamesSvg } from 'assets/casino/providers/jetGames.svg';
import { ReactComponent as JiliSvg } from 'assets/casino/providers/jili.svg';
import { ReactComponent as KaGamingSvg } from 'assets/casino/providers/kaGaming.svg';
import { ReactComponent as KalambaSvg } from 'assets/casino/providers/kalamba.svg';
import { ReactComponent as LeapGamingVirtualSportsSvg } from 'assets/casino/providers/leap-gaming-virtual-sports.svg';
import { ReactComponent as LeapGamingSvg } from 'assets/casino/providers/leap-gaming.svg';
import { ReactComponent as LottoInstanSvg } from 'assets/casino/providers/lotto-instan.svg';
import { ReactComponent as MacawGamingSvg } from 'assets/casino/providers/macawGaming.svg';
import { ReactComponent as MancalaSvg } from 'assets/casino/providers/mancala.svg';
import { ReactComponent as MascotGamingSvg } from 'assets/casino/providers/mascot-gaming.svg';
import { ReactComponent as MicrogamingBrandedSvg } from 'assets/casino/providers/microgaming-branded.svg';
import { ReactComponent as MicrogamingSvg } from 'assets/casino/providers/microgaming.svg';
import { ReactComponent as MplaySvg } from 'assets/casino/providers/mplay.svg';
import { ReactComponent as NetEntTableSvg } from 'assets/casino/providers/net-ent-table.svg';
import { ReactComponent as NetGameSvg } from 'assets/casino/providers/net-game.svg';
import { ReactComponent as NetGamingSvg } from 'assets/casino/providers/net-gaming.svg';
import { ReactComponent as NetTentBrandedSvg } from 'assets/casino/providers/net-tent-branded.svg';
import { ReactComponent as NetentSSvg } from 'assets/casino/providers/netent-s.svg';
import { ReactComponent as NoLimitCitySvg } from 'assets/casino/providers/no-limit-city.svg';
import { ReactComponent as NucleusSvg } from 'assets/casino/providers/nucleus.svg';
import { ReactComponent as OnlyplaySvg } from 'assets/casino/providers/onlyplay.svg';
import { ReactComponent as PgsoftSvg } from 'assets/casino/providers/pgsoft.svg';
import { ReactComponent as PlatipusSvg } from 'assets/casino/providers/platipus.svg';
import { ReactComponent as PlaysonPremiumSvg } from 'assets/casino/providers/playson-premium.svg';
import { ReactComponent as PlaysonSvg } from 'assets/casino/providers/playson.svg';
import { ReactComponent as ProgmaticPlayBrandedSvg } from 'assets/casino/providers/progmatic-play-branded.svg';
import { ReactComponent as ProgmaticPlayLiveSvg } from 'assets/casino/providers/progmatic-play-live.svg';
import { ReactComponent as RedTigerPremiumSvg } from 'assets/casino/providers/red-tiger-premium.svg';
import { ReactComponent as RedTigerSlotsSvg } from 'assets/casino/providers/red-tiger-slots.svg';
import { ReactComponent as RelaxSvg } from 'assets/casino/providers/relax.svg';
import { ReactComponent as RetroSvg } from 'assets/casino/providers/retro.svg';
import { ReactComponent as RtgSlotsSvg } from 'assets/casino/providers/rtg-slots.svg';
import { ReactComponent as SkilrockSvg } from 'assets/casino/providers/skilrock.svg';
import { ReactComponent as SlotmillSvg } from 'assets/casino/providers/slotmill.svg';
import { ReactComponent as SmartSoftSvg } from 'assets/casino/providers/smartSoft.svg';
import { ReactComponent as SpadegamingSvg } from 'assets/casino/providers/spadegaming.svg';
import { ReactComponent as SpearheadSvg } from 'assets/casino/providers/spearhead.svg';
import { ReactComponent as SpifbetSvg } from 'assets/casino/providers/spifbet.svg';
import { ReactComponent as SpinmaticSvg } from 'assets/casino/providers/spinmatic.svg';
import { ReactComponent as SpinomenalSvg } from 'assets/casino/providers/spinomenal.svg';
import { ReactComponent as SpribeSvg } from 'assets/casino/providers/spribe.svg';
import { ReactComponent as SuperLottoFastSvg } from 'assets/casino/providers/super-lotto-fast.svg';
import { ReactComponent as SuperLottoSlotsSvg } from 'assets/casino/providers/super-lotto-slots.svg';
import { ReactComponent as SuperlottoTVSvg } from 'assets/casino/providers/superlottoTV.svg';
import { ReactComponent as SuperSpadeSvg } from 'assets/casino/providers/superSpade.svg';
import { ReactComponent as SwinttSvg } from 'assets/casino/providers/swintt.svg';
import { ReactComponent as ThreeOaksPremiumSvg } from 'assets/casino/providers/three-oaks-premium.svg';
import { ReactComponent as ThreeOaksSvg } from 'assets/casino/providers/three-oaks.svg';
import { ReactComponent as ThunderkickSvg } from 'assets/casino/providers/thunderkick.svg';
import { ReactComponent as TomhornSvg } from 'assets/casino/providers/tomhorn.svg';
import { ReactComponent as TripleCherrySvg } from 'assets/casino/providers/triple-cherry.svg';
import { ReactComponent as TripleProfitsGamesSvg } from 'assets/casino/providers/triple-profits-games.svg';
import { ReactComponent as TruelabSvg } from 'assets/casino/providers/truelab.svg';
import { ReactComponent as TurboGamesSvg } from 'assets/casino/providers/turbo-games.svg';
import { ReactComponent as VibragamingSvg } from 'assets/casino/providers/vibragaming.svg';
import { ReactComponent as VibragamingpremiumSvg } from 'assets/casino/providers/vibragamingpremium.svg';
import { ReactComponent as VivogamingSvg } from 'assets/casino/providers/vivogaming.svg';
import { ReactComponent as XProgamingSvg } from 'assets/casino/providers/XProgaming.svg';
import { ReactComponent as YggdasilSvg } from 'assets/casino/providers/yggdasil.svg';
import { ReactComponent as ZeusplaySvg } from 'assets/casino/providers/zeusplay.svg';
import { ReactComponent as DragoonSoftSvg } from 'assets/casino/providers/dragoonSoft.svg';
import { ReactComponent as ElkSvg } from 'assets/casino/providers/elk.svg';

import { getProviderName } from './shared';

import { APP_TYPE } from 'constants/ui';

export const getFlagIcon = (lang: string, classes?: string) => {
  switch (lang) {
    case 'pt':
      return <PtSvg classes={classes} />;
    case 'ro':
      return <RoSvg classes={classes} />;
    case 'fr':
      return <FrSvg classes={classes} />;
    case 'bs':
      return <BsSvg classes={classes} />;
    case 'no':
      return <NoSvg classes={classes} />;
    case 'en':
      return <EnSvg classes={classes} />;
    case 'ru':
      return <RuSvg classes={classes} />;
    case 'hi':
      return <HiSvg classes={classes} />;
    case 'es':
      return <EsSvg classes={classes} />;
    case 'tr':
      return <TrSvg classes={classes} />;
    case 'ukr':
      return <UkrSvg classes={classes} />;
    case 'de':
      return <DeSvg classes={classes} />;
    case 'az':
      return <AzeSvg classes={classes} />;
    case 'kz':
      return <KzSvg classes={classes} />;
    case 'bn':
      return <BnSvg classes={classes} />;
    case 'uz':
      return <UzSvg classes={classes} />;
    case 'it':
      return <ItSvg classes={classes} />;

    default:
      break;
  }
};

export const getSportIcon = (sportId: number) => {
  switch (sportId) {
    case ESports.Soccer:
    case ESports.HandBall: // handball
      return <SoccerSvg />;
    case ESports.Tennis:
      return <TennisSvg />;
    case ESports.Cricket:
      return <CricketSvg />;
    case ESports.IceHockey:
      return <IceHockeySvg />;
    case ESports.Basketball:
      return <BasketballSvg />;
    case ESports.TableTennis:
      return <TableTennisSvg />;
    case ESports.Volleyball:
    case ESports.BeachVolleyball:
      return <VolleyballSvg />;
    case ESports.CyberSports:
      return <EsportSvg />;
    case ESports.Fifa:
      return <EFifaSvg />;
    case ESports.ENHL:
      return <ENhlSvg />;
    case ESports.ENBA:
      return <ENbaSvg />;
    case ESports.Basketbal3X3:
      return <Basketball3x3Svg />;
    //
    case ESports.Futsal:
      return <FutsalSvg />;
    case ESports.Snooker:
      return <SnookerSvg />;
    case ESports.Badminton:
      return <BadmintonSvg />;
    case ESports.Squash:
      return <SquashSvg />;
    case ESports.Kabaddi:
      return <KabaddiSvg />;
    case ESports.Baseball:
      return <BaseballSvg />;
    case ESports.AmericanFootball:
      return <AmericanFootballSvg />;
    case ESports.AussieRules:
      return <AussieRulesSvg />;
    case ESports.Rugby:
      return <RugbySvg />;
    case ESports.BeachSoccer:
      return <BeachSoccerSvg />;
    case ESports.Darts:
      return <DartsSvg />;
    case ESports.Boxing:
      return <BoxingSvg />;
    case ESports.Waterpolo:
      return <VolleyballSvg />;
    case ESports.FieldHockey:
      return <FieldHockeySvg />;
    case ESports.Chess:
      return <ChessSvg />;
    case ESports.Pesapallo:
      return <VolleyballSvg />;
    case ESports.MMA:
      return <MmaSvg />;

    default:
      return <div>{sportId}</div>;
  }
};

export const getCasinoProviderIcon = (name: string, classes = '') => {
  try {
    const key = getProviderName(name);

    const Icon = getCasinoInHouseIcon();

    switch (key) {
      case '1x2gaming':
        return <XGamingSvg className={classes} />;
      case 'advantplay':
        return <AdvantPlaySvg className={classes} />;
      case 'tada':
        return <TadaSvg className={classes} />;
      case 'mrslotty':
        return <MrSlottySvg className={classes} />;
      case 'bigtimegamingbranded':
        return <BigTimeGamingBrandedSvg className={classes} />;
      case 'espressogames':
        return <EspressoGamesSvg className={classes} />;
      case 'gamevy':
      case 'gamevybranded':
        return <GamevySvg className={classes} />;
      case 'gamesglobal':
        return <GamesGlobalSvg className={classes} />;
      case 'revolvergaming':
        return <RevolverGamingPlaySvg className={classes} />;
      case 'quickspin':
        return <QuickSpinSvg className={classes} />;
      case 'skywindlive':
        return <SkyWindLiveSvg className={classes} />;
      case 'netent':
        return <NetentSvg className={classes} />;
      case 'ctgaming':
        return <CtGamingSvg className={classes} />;
      case 'bigtimegaming':
        return <BigTimeGamingSvg className={classes} />;
      case 'betsolutions':
        return <BetSolutionSvg className={classes} />;
      case 'redrake':
        return <RedRakeSvg className={classes} />;
      case 'skywind':
        return <SkyWindLiveSvg className={classes} />;
      case 'wazdan':
        return <WazdanSvg className={classes} />;
      case 'eurasiangaming':
        return <EurasianGamingSvg className={classes} />;
      case 'betradar':
        return <BetradarSvg className={classes} />;
      case 'onetouch':
        return <OneTouchSvg className={classes} />;
      case 'betgames':
        return <BetGamesSvg className={classes} />;
      case 'pushgaming':
        return <PushGamingSvg className={classes} />;
      case 'high5':
        return <High5Svg className={classes} />;
      case 'hollegames':
        return <HolleGamesSvg className={classes} />;
      case 'igtech':
        return <IgTechSvg className={classes} />;
      case 'infingame':
        return <InfinGameSvg className={classes} />;
      case 'jaderabbit':
        return <JaderabbitSvg className={classes} />;
      case 'jdb':
        return <JdbSvg className={classes} />;
      case 'lucky':
        return <LuckySvg className={classes} />;
      case 'groove':
        return <GrooveSvg className={classes} />;
      case 'gamingcorps':
        return <GamingCorpsSvg className={classes} />;
      case '1spin4win':
        return <OneSpin4WinSvg className={classes} />;
      case 'absolutelylivegaming':
        return <AbsoluteLiveGamingSvg className={classes} />;
      case 'bluebirdgaming':
        return <BlueBirdGamingSvg className={classes} />;
      case 'qtech':
        return <QtechSvg className={classes} />;
      case '5men':
        return <FiveMenSvg className={classes} />;
      case 'atmosfera':
        return <AtmosferaSvg className={classes} />;
      case 'avatarux':
        return <AvatarUxSvg className={classes} />;
      case 'beter.live':
        return <BeterLiveSvg className={classes} />;
      case 'zillion':
        return <ZillonGamesSvg className={classes} />;
      case 'spinza':
        return <SpinzaSvg className={classes} />;
      case 'reevo':
        return <ReevoSvg className={classes} />;
      case 'play_ngo':
        return <PlayNGoSvg className={classes} />;
      case 'playtech':
        return <PlaytechSvg className={classes} />;
      case 'popiplay':
        return <PopipaySvg className={classes} />;
      case 'amatic':
        return <AmaticSvg className={classes} />;
      case 'amigogaming':
        return <AmigoGamingSvg className={classes} />;
      case 'amusnet':
        return <AmusnetSvg className={classes} />;
      case 'aviatrix':
        return <AviatrixSvg className={classes} />;
      case 'bgaming':
        return <BGamingSvg className={classes} />;
      case 'belatragames':
        return <BelatraSvg className={classes} />;
      case 'betsoft':
        return <BetSoftSvg className={classes} />;
      case 'blueprint':
        return <BluePrintSvg className={classes} />;
      //------ not
      case 'boominggames':
        return <BoomingGamesSvg className={classes} />;
      case 'caleta':
        return <CaletaSvg className={classes} />;
      case 'casinoTechnology':
        return <CasinoTechnologySvg className={classes} />;
      case 'charismatic':
        return <CharismaticSvg className={classes} />;
      case 'conceptgaming':
        return <ConceptGamingSvg className={classes} />;
      case 'elbet':
        return <ElBetSvg className={classes} />;
      case 'elysiumstudios':
        return <ElysiumStudiosSvg className={classes} />;
      case 'endorphina':
        return <EndorfinaSvg className={classes} />;
      case 'eurasiangamingbingo':
        return <EurasianGamingBingoSvg className={classes} />;
      // Vae
      case 'eurasiangamingslots':
        return <EurasianGamingSlotsSvg className={classes} />;
      case 'evolution':
        return <EvolutionSvg className={classes} />;
      case 'evoplay':
        return <EvoplaySvg className={classes} />;
      case 'ezugi':
        return <EzugiSvg className={classes} />;
      case 'fachai':
        return <FachaiSvg className={classes} />;
      case 'fantasma':
        return <FantasmaSvg className={classes} />;
      case 'fastspin':
        return <FastspinSvg className={classes} />;
      case 'fazi':
        return <FaziSvg className={classes} />;
      case 'gamanza':
        return <GamanzaSvg className={classes} />;
      case 'gameart':
        return <GameArtSvg className={classes} />;
      case 'gamebeat':
        return <GameBetSvg className={classes} />;
      case 'gamzix':
        return <GamzixSvg className={classes} />;
      case 'goldenrace':
        return <GoldenRaceSvg className={classes} />;
      case 'habanero':
        return <HabeneroSvg className={classes} />;
      case 'hacksawgaming':
        return <HacksawGamingSvg className={classes} />;
      // aoskd
      case 'hogaming':
        return <HgSvg className={classes} />;
      case 'igrosoft':
        return <IgrosoftSvg className={classes} />;
      case 'jetgames':
        return <JetGamesSvg className={classes} />;
      case 'jili':
        return <JiliSvg className={classes} />;
      case 'kagaming':
        return <KaGamingSvg className={classes} />;
      case 'kalamba':
        return <KalambaSvg className={classes} />;
      // /asld/
      case 'leapgamingvirtualsports':
        return <LeapGamingVirtualSportsSvg className={classes} />;
      case 'leapgaming':
        return <LeapGamingSvg className={classes} />;
      case 'lottoinstantwin':
        return <LottoInstanSvg className={classes} />;
      case 'macawgaming':
        return <MacawGamingSvg className={classes} />;
      case 'mancala':
        return <MancalaSvg className={classes} />;
      case 'mascot':
        return <MascotGamingSvg className={classes} />;
      case 'microgamingbranded':
        return <MicrogamingBrandedSvg className={classes} />;
      case 'microgaming':
        return <MicrogamingSvg className={classes} />;
      case 'mplay':
        return <MplaySvg className={classes} />;
      case 'net-ent-table':
        return <NetEntTableSvg className={classes} />;
      case 'netgame':
        return <NetGameSvg className={classes} />;
      case 'netgaming':
      case 'netgamingbranded':
        return <NetGamingSvg className={classes} />;
      case 'netentbranded':
        return <NetTentBrandedSvg className={classes} />;
      case 'netentstandard':
        return <NetentSSvg className={classes} />;
      case 'nolimitcity':
        return <NoLimitCitySvg className={classes} />;
      case 'nucleus':
        return <NucleusSvg className={classes} />;
      case 'onlyplay':
        return <OnlyplaySvg className={classes} />;
      case 'pgsoft':
        return <PgsoftSvg className={classes} />;
      case 'platipus':
        return <PlatipusSvg className={classes} />;
      case 'playsonpremium':
        return <PlaysonPremiumSvg className={classes} />;
      case 'playson':
        return <PlaysonSvg className={classes} />;
      // asd
      case 'pragmaticplaybranded':
        return <ProgmaticPlayBrandedSvg className={classes} />;
      case 'pragmaticplaylive':
        return <ProgmaticPlayLiveSvg className={classes} />;
      case 'pragmaticplay':
        return <PragmaticPlaySvg className={classes} />;
      case 'redtigerpremium':
        return <RedTigerPremiumSvg className={classes} />;
      case 'redtigerslots':
      case 'redtigerslot':
      case 'redtiger':
        return <RedTigerSlotsSvg className={classes} />;
      case 'relaxgaming':
        return <RelaxSvg className={classes} />;
      case 'retrogaming':
        return <RetroSvg className={classes} />;
      case 'rtgslots':
        return <RtgSlotsSvg className={classes} />;
      case 'skilrock':
        return <SkilrockSvg className={classes} />;
      case 'slotmill':
        return <SlotmillSvg className={classes} />;
      case 'smartsoft':
        return <SmartSoftSvg className={classes} />;
      case 'spadegaming':
        return <SpadegamingSvg className={classes} />;
      case 'spearheadstudios':
        return <SpearheadSvg className={classes} />;
      case 'spiffbetgames':
        return <SpifbetSvg className={classes} />;
      case 'spinmatic':
        return <SpinmaticSvg className={classes} />;
      case 'spinomenal':
        return <SpinomenalSvg className={classes} />;
      case 'spribe':
        return <SpribeSvg className={classes} />;
      case 'superlottofast':
        return <SuperLottoFastSvg className={classes} />;
      case 'superlottoslots':
      case 'superlotto':
        return <SuperLottoSlotsSvg className={classes} />;
      case 'superlottotv':
        return <SuperlottoTVSvg className={classes} />;
      case 'superspadegames':
        return <SuperSpadeSvg className={classes} />;
      case 'swintt':
        return <SwinttSvg className={classes} />;
      case 'swinttpremium':
        return <SwinttSvg className={classes} />;
      case 'threeoakspremium':
        return <ThreeOaksPremiumSvg className={classes} />;
      case '3oaks':
        return <ThreeOaksSvg className={classes} />;
      case 'thunderkick':
        return <ThunderkickSvg className={classes} />;
      case 'tomhorn':
        return <TomhornSvg className={classes} />;
      case 'triplecherry':
        return <TripleCherrySvg className={classes} />;
      case 'tripleprofitsgames':
        return <TripleProfitsGamesSvg className={classes} />;
      case 'truelab':
        return <TruelabSvg className={classes} />;
      case 'turbogames':
        return <TurboGamesSvg className={classes} />;
      case 'vibragaming':
        return <VibragamingSvg className={classes} />;
      case 'vibragamingpremium':
        return <VibragamingpremiumSvg className={classes} />;
      case 'vivogaming':
        return <VivogamingSvg className={classes} />;
      case 'xprogaming':
        return <XProgamingSvg className={classes} />;
      case 'yggdrasil':
        return <YggdasilSvg className={classes} />;
      case 'zeusplay':
        return <ZeusplaySvg className={classes} />;
      case 'dragoonsoft':
        return <DragoonSoftSvg className={classes} />;
      case 'elk':
        return <ElkSvg className={classes} />;

      case 'in-housegames':
        return <Icon classes={classes} />;

      default:
        return <div className={classes}>{key}</div>;
    }
  } catch (error) {
    console.error('_____ ERROR WITH PROVIDER ICONS', name, error);
  }
};

export const getVipClubIcon = (level: number, classes?: string) => {
  //   GreenSvg
  // BronzeSvg
  // DiamonSvg
  // PlatinumOneSvg
  // PlatinumSvg
  // GoldSvg
  switch (level) {
    case 0:
      return <GreenSvg classes={classes} />;
    case 1:
      return <BronzeSvg classes={classes} />;
    case 2:
      return <SilverSvg classes={classes} />;
    case 3:
      return <GoldSvg classes={classes} />;
    case 4:
    case 5:
    case 6:
      return <PlatinumOneSvg classes={classes} />;
    case 7:
    case 8:
    case 9:
      return <PlatinumSvg classes={classes} />;
    case 10:
      <DiamondSvg classes={classes} />;
      break;

    default:
      return <DiamondSvg classes={classes} />;
  }
};

export const getCasinoInHouseIcon = () => {
  switch (APP_TYPE) {
    case EAppType.Elon:
      return ElonHouseSvg;
    case EAppType.FsWin:
      return FSWinHouseSvg;
    case EAppType.JabiBet:
      return JabiInHouseSvg;

    default:
      return () => <svg />;
  }
};
