import cln from 'classnames';

import { CloseSvg } from 'decryption_component-kit';

import { useZustandBetSlipStore } from 'stores/betting/betslip/store';
import {
  getActiveBetSlipTabSelector,
  setActiveBetSlipTabAction,
} from 'stores/betting/betslip/selectors';
import { EBetSlipTabs } from 'stores/betting/betslip/types';

import styles from './BetSlipTabs.module.css';

type Props = {
  handleToggleState: () => void;
};

const BetSlipTabs = ({ handleToggleState }: Props) => {
  const setBetSlip = useZustandBetSlipStore(setActiveBetSlipTabAction);

  const activeTab = useZustandBetSlipStore(getActiveBetSlipTabSelector);

  const handleSetNewTabState = (t: EBetSlipTabs) => () => {
    setBetSlip(t);
  };

  return (
    <div className={styles.wrapperBetSlipTabs}>
      <div
        className={cln(styles.tab, {
          [styles.activeTab]: activeTab === EBetSlipTabs.Risk,
        })}
        onClick={handleSetNewTabState(EBetSlipTabs.Risk)}
      >
        t.bet
      </div>
      <div
        className={cln(styles.tab, {
          [styles.activeTab]: activeTab === EBetSlipTabs.Win,
        })}
        onClick={handleSetNewTabState(EBetSlipTabs.Win)}
      >
        t.win
      </div>

      <CloseSvg classes={styles.closeIcon} handleClick={handleToggleState} />
    </div>
  );
};

export default BetSlipTabs;
