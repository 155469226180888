import { useEffect } from 'react';
import cln from 'classnames';

import {
  Text,
  HOCOutsideClick,
  CashSvg,
  CoinSvg,
} from 'decryption_component-kit';

import {
  getActiveBalanceTypeSelector,
  getBalanceInfoSelector,
} from 'stores/user/balance/selectors';
import { useZustandBalanceStore } from 'stores/user/balance/store';
import { useZustandUserStore } from 'stores/user/personal/store';
import { getUserCurrencyNameSelector } from 'stores/user/personal/selectors';

import styles from './BalanceList.module.css';

type Props = {
  handleClose: (e: React.MouseEvent<HTMLDivElement>) => void;
};

const BalanceList = ({ handleClose }: Props) => {
  const activeType = useZustandBalanceStore(getActiveBalanceTypeSelector);

  const { mainBalance, casinoBonusBalance } =
    useZustandBalanceStore(getBalanceInfoSelector) || {};

  const currencyName = useZustandUserStore(getUserCurrencyNameSelector);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <HOCOutsideClick
      classes={styles.wrapperBalanceInfo}
      handleFunc={handleClose}
    >
      <>
        <div
          className={cln(styles.balanceWrapper, {
            [styles.active]: activeType === 'mainBalance',
          })}
        >
          <div className={styles.title}>
            <Text idT="balance.mainBalance" />
          </div>

          <div className={styles.rowInfo}>
            <CashSvg />

            <div className={styles.value}>
              {mainBalance} {currencyName}
            </div>
          </div>
        </div>

        <div
          className={cln(styles.balanceWrapper, {
            [styles.active]: activeType === 'bonusBalance',
          })}
        >
          <div className={styles.title}>
            <Text idT="balance.casinoBalance" />
          </div>
          <div className={styles.rowInfo}>
            <CoinSvg /> <div className={styles.value}>{casinoBonusBalance}</div>
          </div>
        </div>
      </>
    </HOCOutsideClick>
  );
};

export default BalanceList;
