import cln from 'classnames';
import { useAuth } from 'decryption_protocol_hooks';
import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';

import { ArrowSvg } from 'decryption_component-kit';

import AuthButtons from './components/AuthButtons';
import ProfileInfo from './components/ProfileInfo';
import HOCRequestLoader from 'components/HOCs/HOCRequestLoader';
import HeaderLeftSection from './components/LeftSection';
import JivoChat from 'components/JivoChat';
import SkeletLoader from 'components/ui/Loaders/SkeletLoader';
import BonusButton from 'components/ui/Buttons/BonusButton';

import { useZustandCasinoGamesStore } from 'stores/casino/games/store';
import { setCasinoGameUrlAction } from 'stores/casino/games/selectors';
import { useZustandUiStore } from 'stores/ui/store';
import { getHeaderTypeSelector } from 'stores/ui/selectors';
import { EHeaderType } from 'stores/ui/types';
import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

import styles from './Header.module.css';

type Props = {
  isCasinoGameHeader?: boolean;
};

const Header = ({ isCasinoGameHeader }: Props) => {
  const headerType = useZustandUiStore(getHeaderTypeSelector);

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const isAuth = useAuth({ isConnectedApp });

  const setUrl = useZustandCasinoGamesStore(setCasinoGameUrlAction);

  const handleCloseGame = () => {
    setUrl('');
  };

  if (headerType === EHeaderType.Hidden) {
    return null;
  }

  return (
    <div className={cln(styles.wrapperHeader, styles.row)}>
      {isCasinoGameHeader ? (
        <ArrowSvg classes={styles.arrowBack} handleClick={handleCloseGame} />
      ) : (
        <HeaderLeftSection />
      )}

      <div className={styles.row}>
        <HOCRequestLoader
          command={isAuth ? 'x00' : 'x01'}
          requestId={
            !isAuth ? ERequestIds.AuthorizeByToken : ERequestIds.RequestBalance
          }
          customLoader={
            <div className={styles.loaderWrapper}>
              <SkeletLoader />
            </div>
          }
        >
          <>
            {headerType === EHeaderType.SignUp && <AuthButtons />}

            {headerType === EHeaderType.Authorized && (
              <ProfileInfo isCasinoGameHeader={Boolean(isCasinoGameHeader)} />
            )}
          </>
        </HOCRequestLoader>

        {isCasinoGameHeader ? (
          <>
            <BonusButton />

            <div className={cln(styles.jivoBtn, styles.smlMargin)}>
              <JivoChat isDisableText />
            </div>
          </>
        ) : (
          <div className={styles.jivoBtn}>
            <JivoChat isDisableText />
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
