import { ERegistrationForm, ZustandRegistrationStore } from './types';

export const getAvailableCurrenciesSelector = (
  state: ZustandRegistrationStore
) => state.availableCurrencies;

export const getAvailableRegistrationsSelector = (
  state: ZustandRegistrationStore
) => state.availableRegistrationForm;

export const getRegisteredLoginSelector = (state: ZustandRegistrationStore) =>
  state.registeredLogin;

export const getRegistrationTabsSelector = (
  state: ZustandRegistrationStore
) => {
  const types = getAvailableRegistrationsSelector(state);

  return types.map((t) => ({
    label:
      t !== ERegistrationForm.PhoneWithoutCode
        ? `registrations.tabs.${ERegistrationForm[t]}`
        : 'registrations.tabs.Phone',
    value: t,
  }));
};

export const getActiveRegistrationFormSelector = (
  state: ZustandRegistrationStore
) => state.activeForm;

export const getActiveCurrencyIdSelector = (state: ZustandRegistrationStore) =>
  state.activeCurrencyId;

export const getActiveCountryIdSelector = (state: ZustandRegistrationStore) =>
  state.activeCountryId;
// ================================= ACTIONS =================================

export const setAvailableCurrenciesAction = (state: ZustandRegistrationStore) =>
  state.setCurrencies;
export const setActiveRegistrationFormAction = (
  state: ZustandRegistrationStore
) => state.setActiveRegistrationForm;

export const setActiveCurrencyIdAction = (state: ZustandRegistrationStore) =>
  state.setActiveCurrencyId;

export const setActiveCountryIdAction = (state: ZustandRegistrationStore) =>
  state.setActiveCountryId;

export const setRegisteredLoginAction = (state: ZustandRegistrationStore) =>
  state.setRegisteredLogin;
