import React, { useEffect, useState } from 'react';
import cln from 'classnames';

import { setFormsInfoAction } from 'stores/money/forms/selectors';
import { useZustandFormsStore } from 'stores/money/forms/store';
import { setPaymentsCurrentStepAction } from 'stores/money/payments/selectors';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';

import Input from 'components/ui/Input/Base';
import { isFilledForm, replaceValueByMask } from 'utils/validation';
import { DefaultInputType } from 'components/ui/Input/Base/types';
import { DEFAULT_INPUT_VALUE } from 'components/ui/Input/Base/constant';
import AmountInput from 'components/ui/Input/AmountInput';
import TurkishBankInput from 'components/ui/Input/TurkishBankInput';

import { Steps } from 'stores/money/payments/types';
import { PaymentChannelType } from 'decryption_protocol/dist/lib/store/user/types';
import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';

import withdrawStyles from '../../Withdraw.module.css';
import InputUserInfo from 'components/ui/Input/InputUserInfo';

const PASSPORT_DATE_MASK = 'XXXX:XX:XX';

type Props = {
  min: number;
  max: number;
};

type InputType = 'passportTime' | 'passportNumber' | 'accountNumber';

const ExpressHavale = ({ max, min }: Props) => {
  const setFormInfo = useZustandFormsStore(setFormsInfoAction);

  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  const currencyId = useZustandUserStore(getUserCurrencyIdSelector);

  const [accountNumber, setAccountNumber] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [passportTime, setPassportTime] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [passportNumber, setPassportNumber] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [name, setName] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [familyName, setFamilyName] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [bankInfo, setBankInfo] = useState<{
    iban: string;
    bankId: number;
    swiftCode: string;
  }>({ iban: '', bankId: 0, swiftCode: '' });

  const [amount, setAmount] = useState<string>('');

  const handleValidateForm = (inputName: InputType) => (value: string) => {
    if (inputName === 'accountNumber') {
      const errorText =
        value.length >= 7 && value.length <= 17 ? '' : 'errors.invalidValue';

      setAccountNumber({ value, errorText });

      return;
    }

    if (inputName === 'passportTime') {
      const errorText =
        value.length === PASSPORT_DATE_MASK.length ? '' : 'errors.invalidValue';

      setPassportTime({ value, errorText });

      return;
    }

    if (inputName === 'passportNumber') {
      const errorText = value ? '' : 'errors.required';

      setPassportNumber({ value, errorText });

      return;
    }
  };

  useEffect(() => {
    const isFilled = isFilledForm([
      accountNumber,
      passportTime,
      passportNumber,
      name,
      familyName,
      bankInfo,
      amount,
    ]);

    if (isFilled && currencyId) {
      setFormInfo({
        isDeposit: false,
        form: {
          currencyId,
          amount,
          passportDate: passportTime.value,
          accountNumber: accountNumber.value,

          firstName: name.value,
          familyName: familyName.value,
          username: `${name.value} ${familyName.value}`,

          ...bankInfo,
          channelId: PaymentChannelType.TurkishExpressHavale,
          requestId: ERequestIds.WithdrawPapara,
        },
      });

      setStep(Steps.FilledForm);
    } else {
      setStep(Steps.TypingData);
    }
  }, [
    currencyId,
    accountNumber,
    passportTime,
    passportNumber,
    name,
    familyName,
    bankInfo,
    amount,
  ]);

  return (
    <div className={withdrawStyles.marginInputBottom}>
      <AmountInput
        placeholder="walletPopUp.forms.amount"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        isWithdraw
        size="full"
        onChange={setAmount}
        isPresets={false}
        min={min}
        max={max}
        classes={withdrawStyles.amountInputMargin}
        wrapperClasses={withdrawStyles.marginInputBottom}
      />

      <InputUserInfo
        isIgnoreUserInfo
        userInfoKey="name"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.name"
        size="full"
        classes={withdrawStyles.marginInputBottom}
        onChange={setName}
        field={name}
      />

      <InputUserInfo
        isIgnoreUserInfo
        userInfoKey="surname"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.sureName"
        size="full"
        field={familyName}
        onChange={setFamilyName}
        classes={withdrawStyles.marginInputBottom}
      />

      {/* срокдействия */}
      <Input
        inputLabelType="labelUp"
        inputStyleType="border-colorful"
        // 7 - 17
        placeholder="walletPopUp.forms.bankAccountNumber"
        size="full"
        errorText={accountNumber.errorText}
        onChange={handleValidateForm('accountNumber')}
        classes={withdrawStyles.marginInputBottom}
      />

      <Input
        inputLabelType="labelUp"
        inputStyleType="border-colorful"
        placeholder="walletPopUp.forms.passportNumber"
        size="full"
        errorText={passportNumber.errorText}
        onChange={handleValidateForm('passportNumber')}
        classes={withdrawStyles.marginInputBottom}
      />

      <Input
        inputLabelType="labelUp"
        inputStyleType="border-colorful"
        placeholder="walletPopUp.forms.validTime"
        size="full"
        errorText={passportTime.errorText}
        onChange={handleValidateForm('passportTime')}
        patternFn={replaceValueByMask(PASSPORT_DATE_MASK)}
        classes={withdrawStyles.marginInputBottom}
      />

      {/* swift */}
      <TurkishBankInput
        withSwift
        onChange={setBankInfo}
        classesWrapper={withdrawStyles.marginInputBottom}
        classesInput={withdrawStyles.marginInputBottom}
        classesSelector={withdrawStyles.marginInputBottom}
      />
    </div>
  );
};

export default ExpressHavale;
