import { useEffect, useState } from 'react';
import cln from 'classnames';
import { PaymentChannelType } from 'decryption_protocol/dist/lib/store/user/types';
import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';

import Checkbox from 'components/ui/Checkbox';
import { Text } from 'decryption_component-kit';
import AmountInput from 'components/ui/Input/AmountInput';
import InputUserInfo from 'components/ui/Input/InputUserInfo';

import { DefaultInputType } from 'components/ui/Input/Base/types';
import { DEFAULT_INPUT_VALUE } from 'components/ui/Input/Base/constant';
import { setFormsInfoAction } from 'stores/money/forms/selectors';
import { useZustandFormsStore } from 'stores/money/forms/store';
import { setPaymentsCurrentStepAction } from 'stores/money/payments/selectors';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';
import { Steps } from 'stores/money/payments/types';

import { isFilledForm } from 'utils/validation';

import depositStyles from '../../Deposit.module.css';
import styles from './TurkishHavaleHera.module.css';

type Props = {
  limit: { min: number; max: number };
};

const TurkishHavaleHera = ({ limit }: Props) => {
  const setFormInfo = useZustandFormsStore(setFormsInfoAction);

  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  const currencyId = useZustandUserStore(getUserCurrencyIdSelector);

  const [amount, setAmount] = useState<string>('');

  const [name, setName] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [familyName, setFamilyName] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [isAgree, setAgreeState] = useState<boolean>(false);

  const toggleAgreementState = () => {
    setAgreeState(!isAgree);
  };

  useEffect(() => {
    const isFilled = isFilledForm([name, familyName, amount]);

    if (isFilled && currencyId && isAgree) {
      setFormInfo({
        isDeposit: true,
        form: {
          username: `${name.value} ${familyName.value}`,
          currencyId, // turkishLira
          channelId: PaymentChannelType.HavaleHera,
          amount,
          name: name.value,
          familyName: familyName.value,
          requestId: ERequestIds.DepositPayHera,
        },
      });

      setStep(Steps.FilledForm);
    } else {
      setStep(Steps.TypingData);
    }
  }, [currencyId, name, familyName, isAgree, amount]);

  return (
    <div className={depositStyles.marginInputBottom}>
      <AmountInput
        placeholder="walletPopUp.forms.amount"
        size="full"
        onChange={setAmount}
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        min={limit.min}
        max={limit.max}
        classes={depositStyles.amountInputMargin}
        wrapperClasses={depositStyles.marginInputBottom}
      />

      <InputUserInfo
        userInfoKey="name"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.name"
        size="full"
        classes={depositStyles.marginInputBottom}
        onChange={setName}
        field={name}
      />

      <InputUserInfo
        userInfoKey="surname"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.sureName"
        size="full"
        field={familyName}
        onChange={setFamilyName}
        classes={depositStyles.marginInputBottom}
      />

      <div
        className={cln(depositStyles.marginInputBottom, styles.wrapperCheckbox)}
      >
        <Checkbox checked={isAgree} toggleState={toggleAgreementState} />

        <div className={styles.checkboxDescriptions}>
          <Text idT="walletPopUp.forms.onlyOurWebSite" />
        </div>
      </div>
    </div>
  );
};

export default TurkishHavaleHera;
