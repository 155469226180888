import { create } from 'zustand';
import { ModalTypes, ZustandModalStore } from './types';
import { sleep } from 'utils/shared';
import {
  INITIAL_MODAL,
  INITIAL_MODAL_ADDITION_INFO,
} from 'constants/marketingTags';

export const useZustandModalStore = create<ZustandModalStore>((set, get) => ({
  modalType: INITIAL_MODAL,
  additionalInfo: INITIAL_MODAL_ADDITION_INFO,
  oldType: null,

  setModalInfo: (modalType, additionalInfo = null) => {
    set({ modalType, additionalInfo });
  },
  closeAndOpen: async (type, additionalInfo = null) => {
    const { modalType } = get();

    set({ oldType: modalType });

    await sleep(100);

    set({ modalType: ModalTypes.CloseModal });

    await sleep(500);

    set({ modalType: type, additionalInfo });
  },
}));
