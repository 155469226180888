import React from 'react';
import { getLicenseInfosSelector } from 'stores/configuration/selectors';
import { useZustandWebConfiguration } from 'stores/configuration/store';
import { ELicense } from 'stores/configuration/types';
import CuracauLicense from './components/CuracauLicense';
import AnguanLicense from './components/AnguanLicense';

const LicenseKit = () => {
  const licenseInfo = useZustandWebConfiguration(getLicenseInfosSelector);

  const { type = 'Unknown' } = licenseInfo || {};

  const licenseType = ELicense[type as keyof typeof ELicense];

  return (
    <>
      {licenseType === ELicense.Curacau && <CuracauLicense />}

      {licenseType === ELicense.Anguan && <AnguanLicense />}
    </>
  );
};

export default LicenseKit;
