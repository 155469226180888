import { useEffect, useState } from 'react';

import {
  AdaptedProviderTournament,
  ProviderTournament,
  ProviderTournaments,
  ReturnHookType,
} from './types';
import {
  useZustandLanguagesKitStore,
  languageSelectors,
} from 'decryption_component-kit';

import { useZustandUserStore } from 'stores/user/personal/store';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';

const useGetProviderTournaments = (): ReturnHookType | null => {
  const [providerTournaments, setProviderTournaments] =
    useState<ReturnHookType | null>(null);

  const languageName = useZustandLanguagesKitStore(
    languageSelectors.getActiveLanguageNameKitSelector
  );

  const currencyId = useZustandUserStore(getUserCurrencyIdSelector);

  const getProviderTournaments = async (currencyId?: number) => {
    if (!languageName) {
      return;
    }
    try {
      const pathname =
        process.env.NODE_ENV !== 'production'
          ? '/mock/provider-tournaments.json'
          : '/Scripts/provider_tournaments.json';

      const response = await fetch(pathname, {
        method: 'GET',
      });
      const data = (await response.json()) as ProviderTournament[];

      const activeTournaments: ProviderTournaments = [];

      const finishedTournaments: ProviderTournaments = [];

      const currentTime = new Date().getTime();

      data.forEach((t: ProviderTournament) => {
        const finishTime = new Date(t.endDate).getTime();
        const startDate = new Date(t.startDate).getTime();

        if (startDate > currentTime) {
          return true;
        }
        const adaptedTournament: AdaptedProviderTournament = {
          ...t,
          image: t.images[languageName] || t.images['en'] || '',
          rules: t.rules[languageName] || t.rules['en'] || [],
          startDate: new Date(t.startDate).getTime(),
          prize: t.prizePool[`${currencyId}`] || t.prizePool['default'] || 0,
          endDate: finishTime,
        };

        if (finishTime > currentTime) {
          activeTournaments.push(adaptedTournament);

          return true;
        }

        finishedTournaments.push(adaptedTournament);
      });

      setProviderTournaments({
        activeTournaments,
        finishedTournaments,
      });
    } catch (error) {
      console.error('🚀 ~ error:', error);
      // addNotification({
      //   uid:
      // })
    }
  };

  useEffect(() => {
    if (!languageName) {
      return;
    }

    getProviderTournaments(currencyId);
  }, [languageName, currencyId]);

  return providerTournaments;
};

export default useGetProviderTournaments;
