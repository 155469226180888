import { create } from 'zustand';
import { ZustandCalendarFilterStoreStore } from './types';

const INITIAL_TIMES: [number, number] = [
  new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime(),
  new Date().getTime(),
];

export const useZustandCalendarFilterStore =
  create<ZustandCalendarFilterStoreStore>((set, get) => ({
    times: INITIAL_TIMES,

    setRange: (times) => {
      set({ times });
    },
  }));
