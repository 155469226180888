import { create } from 'zustand';
import { ZustandInstaNewsStore } from './types';
import { getFromLocalStorage, setToLocalStorage } from 'utils/localstorage';
import { INSTA_STORIES } from 'constants/localstoreKeys';

const initLastTime = getFromLocalStorage(INSTA_STORIES);

export const useZustandInstaNewsStore = create<ZustandInstaNewsStore>(
  (set) => ({
    lastTimeStoryId: initLastTime ? +initLastTime : null,
    isDisabled: false,

    setProperty(key, value) {
      if (key === 'lastTimeStoryId' && value) {
        setToLocalStorage(INSTA_STORIES, `${value}`);
      }
      set({ [key]: value });
    },
  })
);
