import { ERegistrationForm } from 'stores/forms/signUp/types';
import { Banner } from 'decryption_component-kit/dist/lib/components/Banners/types';
import { ERouteNames } from 'Routes/types';

export interface ZustandConfigurationStore
  extends ZustandConfigurationEntities {
  getWebConfiguration: () => void;
}

interface ZustandConfigurationEntities {
  webConfiguration: null | WebConfiguration;
  colors: Color[];
}

export type Color = {
  key: string;
  value: string;
};

export enum EPath {
  Registration = 'v1/auth/register',
}

export type WebConfiguration = {
  registration: RegistrationSettings;
  version: string;
  categories: {
    providerCategoryNames: string;
    translateKey: string;
    iconId: number;
  };
  promotion?: Promotion;
  apps: {
    ios: string;
    android: string;
  };
  banners: CustomBannerInfo[];
  bonusImagePaths: BonusCards;
  transport: TransportSettings;
  languages: LanguageInfo[];
  docs: {
    [order: string]: {
      link: string;
      translateKey: string;
    };
  };
  social: {
    twitter: string;
    instagram: string;
    telegram: string;
    facebook: string;
  };
  translateSpecialKeys: {
    [translateSpecKey: string]: string;
  };
  KYCLink: string;
  partnersUrl: string;
  support: {
    mail: string;
    jivoKey: string;
    secret: string;
  };
  betting: {
    isFrame: boolean;
    isBetting: boolean;
    frameDomain: string;
  };
  license: {
    domains: string[];
    type: keyof typeof ELicense;
    validatorSite: string;
    validatorLinks: string[];
  };
};

export enum ELicense {
  Unknown,
  Curacau,
  Anguan,
}

export type CustomBannerInfo = Banner & {
  depositDependency?: number;
  customColor?: string;
  redirect?: {
    default: string;
    [key: string]: string;
  };
};

type LanguageInfo = {
  shortName: string;
  name: string;
};

type RegistrationSettings = {
  forms: (keyof typeof ERegistrationForm)[];
  popUpAfterRegistration: boolean;
  landing: (keyof typeof ERegistrationForm)[];
};

type TransportSettings = {
  address: string;
};

type BonusCards = {
  weekly: string;
  monthly: string;
  rakeback: string;
  rakebackBoost: string;
  support: string;
  retention: string;
  battle: string;
};

type Promotion = {
  path: ERouteNames;
  banner: CustomBannerInfo;
  drawDate: string; // UTC
  video?: string; // UTC
  giveAwayResults: CustomBannerInfo;
  step: PromoStep;
  give: {
    main: GiveBase;
    two: GiveBase;
    three: GiveBase;
    fourth: GiveBase;
  };
};

type GiveBase = {
  desktop: string;
  mobile: string;
  text: string;
  subTitle: string;
};

export type PromoStep = {
  1: BasePromoStepText;
  2: BasePromoStepText;
  3: BasePromoStepText;
};

export type BasePromoStepText = {
  base: string;
  auth: string;
  basePath: string;
  authPath: string;
};
