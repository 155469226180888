export const initializingPixel = () => {
  if (
    !process.env.REACT_APP_PIXEL ||
    process.env.NODE_ENV !== 'production' ||
    !window.fbq
  ) {
    console.warn('PIXEL KEY NOT FOUND');

    return;
  }

  try {
    window.fbq('init', process.env.REACT_APP_PIXEL);
    window.fbq('track', 'PageView');
  } catch (error) {
    console.error('PIXEL initialization ERROR');
  }
};
