import { useEffect } from 'react';
import cln from 'classnames';

import { POST_DELAY } from '../../constants';

import styles from './Dash.module.css';

type Props = {
  classes?: string;
  isActive: boolean;
  callback: () => void;
};

const Dash = ({ classes, isActive, callback }: Props) => {
  useEffect(() => {
    if (!isActive) {
      return;
    }
    const timer = setTimeout(() => {
      callback();
    }, POST_DELAY);

    // return () => {
    //   if (timer) {
    //     console.info('CLEAR');
    //     clearTimeout(timer);
    //   }
    // };
  }, [isActive]);

  return (
    <div className={cln(styles.wrapper, classes)}>
      {isActive && (
        <div
          className={styles.filled}
          style={{ animationDuration: `${POST_DELAY / 1000}s` }}
        />
      )}
    </div>
  );
};

export default Dash;
