import { ZustandUiStore } from './types';

export const getHeaderTypeSelector = (store: ZustandUiStore) =>
  store.headerType;

export const getFooterTypeSelector = (store: ZustandUiStore) =>
  store.footerType;

// Actions

export const setHeaderTypeAction = (store: ZustandUiStore) =>
  store.setHeaderType;

export const setFooterTypeAction = (store: ZustandUiStore) =>
  store.setFooterType;
