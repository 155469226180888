import {
  useGetWeeklyBonus,
  useGetRakeBack,
  useGetAvailableBonuses,
} from 'decryption_protocol_hooks';
import {
  ReturnTypeRakeBake,
  ReturnTypeWeeklyBonus,
} from 'decryption_protocol/dist/lib/store/operations/entities/vipClub/types';

import { isAuthorizedUserSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';
import { useZustandModalStore } from 'stores/modal/store';
import { setModalInfoAction } from 'stores/modal/selectors';

import { ModalTypes } from 'stores/modal/types';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './BonusButton.module.css';

const BonusButton = () => {
  const setModal = useZustandModalStore(setModalInfoAction);

  const isAuth = useZustandUserStore(isAuthorizedUserSelector);

  const { bonusOnDeposit = [], freeSpins = [] } = useHookFabric({
    fn: useGetAvailableBonuses,
  }) as any;

  const weekly = useHookFabric({
    fn: useGetWeeklyBonus,
  }) as null | ReturnTypeWeeklyBonus;

  const rakebackInfo = useHookFabric({
    fn: useGetRakeBack,
  }) as null | ReturnTypeRakeBake;

  const openModal = (t: ModalTypes) => () => {
    setModal(t);
  };
  const count =
    Number(Boolean(rakebackInfo?.rakeback)) +
    Number(Boolean(weekly?.amount)) +
    bonusOnDeposit.length +
    freeSpins.length;

  // handleClick={openModal(ModalTypes.Bonuses)}

  if (!isAuth) {
    return null;
  }

  return (
    <div
      onClick={openModal(ModalTypes.Bonuses)}
      className={styles.wrapperBonusButton}
    >
      <img src="/icons-elon/bonuses/treasure.webp" />
      {!!count && <div className={styles.count}>{count}</div>}
    </div>
  );
};

export default BonusButton;
