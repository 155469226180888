import { create } from 'zustand';
import { ZustandDeviceStore } from './types';
import { handleDeviceDetection } from './utils';

export const useZustandDeviceStore = create<ZustandDeviceStore>((set) => ({
  device: handleDeviceDetection(),

  setDeviceType: (device) => {
    set({ device });
  },
}));
