import { getFromLocalStorage } from 'utils/localstorage';
import { EAppType } from 'decryption_component-kit/dist/lib/types/ui';
import { DEVELOPMENT_MODE_NAME, MOCK_PAYMENT } from './localstoreKeys';
import { getValueByIdFromUrl } from 'utils/urlParser';
import { getPastSaturday } from 'utils/date';
import { detectBrowser } from 'utils/browser';

export const APP_TYPE = Number(process.env.REACT_APP_TYPE) || -2;

export const INFINITY_SCROLL_OFFSET = window.innerHeight + 350;

export const INFINITY_SCROLL_BUTTON_TOP_OFFSET = -350;

export const SIGN_UP_URL_CURRENCY_NAME = getValueByIdFromUrl(
  'currency',
  window.location.href
);

export const SIGN_UP_URL_COUNTRY_ID = +getValueByIdFromUrl(
  'countryId',
  window.location.href
);

export const CUSTOM_THEME_NAME = getValueByIdFromUrl(
  'c-t',
  window.location.href
);

export const IS_WEBP = process.env.REACT_APP_CASINO_WEBP === 'true';

export const IS_DEVELOPMENT_MODE =
  Boolean(getFromLocalStorage(DEVELOPMENT_MODE_NAME)) ||
  process.env.NODE_ENV === 'development';

export const IS_VERTICAL = true;

export const PREV_SUNDAY_TIMESTAMP = getPastSaturday();

export const CRAZY_TIME_ID = 38368; // SOFT SWISS
// 7451; - SLOTEGRATOR

export const GATE_OF_OLYMPUS = 5527;

export const IS_MOCK_PAYMENT = getFromLocalStorage(MOCK_PAYMENT);

export const IS_DISABLED_SPEC_PAYMENT_LOGIC = Boolean(
  getValueByIdFromUrl('base-payment-logic', window.location.href)
);

export const WHEEL_EDIT_TEXT_INSIDE_WHEEL = Boolean(
  getValueByIdFromUrl('type', window.location.href)
);

export const WHEEL_IMMEDIATELY_OPEN = Boolean(
  getValueByIdFromUrl('immediately', window.location.href)
);

export const MODIFY_TEXT = Boolean(
  +getValueByIdFromUrl('text-type-2', window.location.href) === 1
);

export const IS_SCRATCH_CONTENT = Boolean(
  +getValueByIdFromUrl('is-scratch', window.location.href) === 1
);

export const IS_ELON = APP_TYPE === EAppType.Elon;

export const REFERRAL_KEY = 'referral_code';

export const REFERRAL_CODE = getValueByIdFromUrl(
  REFERRAL_KEY,
  window.location.href
);

const TELEGRAM_APP_KEY = 'telegram_app';

export const TELEGRAM_CHAT_ID = getValueByIdFromUrl(
  TELEGRAM_APP_KEY,
  window.location.href
);

export const BROWSER_TYPE = detectBrowser();

export const IS_TG_DEBUG =
  getValueByIdFromUrl('tg-debug', window.location.href) === '1';
