export interface ZustandRegistrationStore extends ZustandRegistrationEntities {
  setCurrencies: (currencies: Currency[]) => void;
  setAvailableRegistrationTypes: (
    d: (keyof typeof ERegistrationForm)[]
  ) => void;
  setActiveRegistrationForm: (t: ERegistrationForm) => void;
  setActiveCurrencyId: (c: number | null) => void;
  setActiveCountryId: (c: number | null) => void;
  setRegisteredLogin: (c: string) => void;
}

interface ZustandRegistrationEntities {
  availableCurrencies: Currency[];
  availableRegistrationForm: ERegistrationForm[];
  activeForm: ERegistrationForm | null;
  registeredLogin: string;

  activeCurrencyId: number | null;
  activeCountryId: number | null;
}

export enum ERegistrationForm {
  Email = 1,
  Phone,
  OneClick,
  PhoneWithoutCode,
}

export type Currency = {
  code: number;
  id: number;
  name: string;
  shortView: string;
};
