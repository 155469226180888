import { useTransportControllers } from 'decryption_protocol_hooks';
import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';

import { Text, CloseSvg } from 'decryption_component-kit';

import { useZustandModalStore } from 'stores/modal/store';
import { setModalInfoAction } from 'stores/modal/selectors';

import { ModalTypes } from 'stores/modal/types';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './CancelBonus.module.css';

type Props = {
  classes?: string;
  operationId: number;
  isImg?: boolean;
  isImmediately?: boolean;
  nameBonusKey: string;
};

const ButtonCancelBonus = (props: Props) => {
  const {
    classes,
    operationId,
    nameBonusKey,
    isImg,
    isImmediately = false,
  } = props;

  const { sendCommand } = useHookFabric({ fn: useTransportControllers });

  const setModal = useZustandModalStore(setModalInfoAction);

  const toggleCancelState = () => {
    if (isImmediately) {
      handleCancelOperation();

      return;
    }

    setModal(ModalTypes.ClaimConfirmation, {
      id: operationId,
    });
  };

  const handleCancelOperation = () => {
    sendCommand(
      '23x',
      {
        operationId,
        requestId: ERequestIds.CancelOperation,
      },
      true
    );
  };

  return (
    <div className={classes}>
      <div className={styles.cancelCanceling} onClick={toggleCancelState}>
        {isImg ? <CloseSvg /> : <Text idT={nameBonusKey} />}
      </div>
    </div>
  );
};

export default ButtonCancelBonus;
