import AmountInput from 'components/ui/Input/AmountInput';
import Input from 'components/ui/Input/Base';
import { DEFAULT_INPUT_VALUE } from 'components/ui/Input/Base/constant';
import { DefaultInputType } from 'decryption_component-kit/dist/lib/components/ui/Inputs/BaseInput/types';
import { PaymentChannelType } from 'decryption_protocol/dist/lib/store/user/types';
import { useEffect, useState } from 'react';
import { setFormsInfoAction } from 'stores/money/forms/selectors';
import { useZustandFormsStore } from 'stores/money/forms/store';
import { setPaymentsCurrentStepAction } from 'stores/money/payments/selectors';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';

import withdrawStyles from '../../Withdraw.module.css';
import { isFilledForm, upperCase } from 'utils/validation';
import { Steps } from 'stores/money/payments/types';
import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';
import InputUserInfo from 'components/ui/Input/InputUserInfo';

type Props = {
  channelId:
    | PaymentChannelType.INRIMPS
    | PaymentChannelType.INRNEFTS
    | PaymentChannelType.INRRGTS;
  min: number;
  max: number;
};

const InrOctopay = ({ min, max, channelId }: Props) => {
  const [amount, setAmount] = useState<string>('');

  const [name, setName] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [sureName, setSurName] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [bankCode, setBankCode] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [accountNumber, setAccountNumber] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const currencyId = useZustandUserStore(getUserCurrencyIdSelector);

  const setFormInfo = useZustandFormsStore(setFormsInfoAction);

  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  const handleInputChange =
    (name: 'accountNumber' | 'bankCode') => (v: string) => {
      const data = { value: v, errorText: '' };

      if (name === 'bankCode') {
        setBankCode(data);

        return;
      }

      if (name === 'accountNumber') {
        setAccountNumber(data);

        return;
      }
    };

  useEffect(() => {
    const data = [amount, name, sureName, bankCode, accountNumber];

    const isFilled = isFilledForm(data);

    if (isFilled && currencyId) {
      setFormInfo({
        isDeposit: false,
        form: {
          amount,
          channelId,
          name: name.value,
          sureName: sureName.value,
          bankCode: bankCode.value,
          accountNumber: accountNumber.value,
          // transaction: vpa.value,
          currencyId,
          requestId: ERequestIds.INRWithdrawOctoPay,
        },
      });
      setStep(Steps.FilledForm);
    } else {
      setStep(Steps.TypingData);
    }
  }, [currencyId, bankCode, accountNumber, amount, channelId, name, sureName]);

  return (
    <>
      <AmountInput
        placeholder="walletPopUp.forms.amount"
        isWithdraw
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        size="full"
        onChange={setAmount}
        isPresets={false}
        min={min}
        max={max}
        wrapperClasses={withdrawStyles.marginInputBottom}
      />
      <InputUserInfo
        userInfoKey="name"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.name"
        size="full"
        classes={withdrawStyles.marginInputBottom}
        onChange={setName}
        field={name}
      />
      <InputUserInfo
        userInfoKey="surname"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.sureName"
        size="full"
        classes={withdrawStyles.marginInputBottom}
        onChange={setSurName}
        field={sureName}
      />

      <Input
        placeholder="walletPopUp.forms.bankCode"
        size="full"
        errorText={bankCode.errorText}
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        patternFn={upperCase}
        onChange={handleInputChange('bankCode')}
        classes={withdrawStyles.marginInputBottom}
      />
      <Input
        placeholder="walletPopUp.forms.bankAccount"
        size="full"
        errorText={accountNumber.errorText}
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        onChange={handleInputChange('accountNumber')}
        classes={withdrawStyles.marginInputBottom}
      />
    </>
  );
};

export default InrOctopay;
