import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';

import {
  Footer,
  useZustandLanguagesKitStore,
  languageSelectors,
  useTranslate,
} from 'decryption_component-kit';

import LicenseKit from '../LicenseKit';
import { DEVELOPMENT_MODE_NAME } from 'constants/localstoreKeys';

import { useZustandWebConfiguration } from 'stores/configuration/store';
import { getDocsSelector } from 'stores/configuration/selectors';

import {
  getFromLocalStorage,
  removeFromLocalStorage,
  setToLocalStorage,
} from 'utils/localstorage';

import { ERouteNames } from 'Routes/types';
import { APP_TYPE } from 'constants/ui';

import styles from './FooterComponentKit.module.css';

const FooterComponentKit = () => {
  const [devMode, setDevMode] = useState(0);

  const { getText } = useTranslate('', '');

  const docs = useZustandWebConfiguration(getDocsSelector) || {};

  const preparedDocs = useMemo(() => {
    const obj: typeof docs = {};

    Object.keys(docs).forEach((key) => {
      const data = docs[key];

      obj[key] = {
        ...data,
        translateKey: getText(data.translateKey),
      };
    });

    return obj;
  }, [docs]);

  const { pathname } = useLocation();

  const activeLangName = useZustandLanguagesKitStore(
    languageSelectors.getActiveLanguageNameKitSelector
  );

  const handleClick = () => {
    if (getFromLocalStorage(DEVELOPMENT_MODE_NAME)) {
      removeFromLocalStorage(DEVELOPMENT_MODE_NAME);
      alert('removed dev mode');

      return;
    }
    setDevMode(devMode + 1);
  };

  useEffect(() => {
    if (devMode === 20) {
      setToLocalStorage(DEVELOPMENT_MODE_NAME, 'true');
      alert('dev mode turn on');

      setDevMode(0);
    }
  }, [devMode]);

  if (
    pathname.includes('landing') ||
    pathname.includes(ERouteNames.FrameBetting)
  ) {
    return null;
  }

  return (
    <Footer
      firstText={getText('footer.firstText')}
      secondText={getText('footer.secondText')}
      docTitle={getText('footer.documents')}
      License={LicenseKit}
      ageImagePath="/icons-elon/footer/age.svg"
      // frameLicense="https://licensing.gaming-curacao.com/validator/?lh=207c49a55131024ee4ed64649035be91&amp;template=tseal"
      docs={{
        docs: preparedDocs,
        doc1Title: getText('docs.group1'),
        doc2Title: getText('docs.group2'),
        doc3Title: getText('docs.group3'),
        activeLangName,
      }}
      footerLastTextCallback={handleClick}
      logoProps={{
        appType: APP_TYPE,
        isMobile: false,
        classes: styles.logoSize,
      }}
    />
  );
};

export default FooterComponentKit;
