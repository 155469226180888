import { useEffect } from 'react';
import { useGetCountryInfo } from 'decryption_protocol_hooks';
import cln from 'classnames';
import { CountryInfo } from 'decryption_protocol/dist/lib/store/user/types';

import { Selector } from 'decryption_component-kit';

import { COUNTRY_MOCK } from './constants';

import { useZustandRegistrationStore } from 'stores/forms/signUp/store';
import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';
import {
  getActiveCountryIdSelector,
  setActiveCountryIdAction,
} from 'stores/forms/signUp/selectors';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from '../../SignUp.module.css';

type Props = {
  classes?: string;
};

const CountrySelector = ({ classes }: Props) => {
  const activeCountryId = useZustandRegistrationStore(
    getActiveCountryIdSelector
  );

  const setActiveCountryId = useZustandRegistrationStore(
    setActiveCountryIdAction
  );

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const countryInfo = useHookFabric({
    fn: useGetCountryInfo,
  }) as CountryInfo | null;

  useEffect(() => {
    if (activeCountryId || !countryInfo) {
      return;
    }

    const isExist = COUNTRY_MOCK.find((c) => c.id === countryInfo.countryId);

    if (isExist) {
      setActiveCountryId(countryInfo.countryId);

      return;
    }

    setActiveCountryId(COUNTRY_MOCK[0].id);
  }, [activeCountryId, countryInfo]);

  return (
    <Selector
      // isOpenUp
      size="full"
      classes={cln(styles.marginB, classes)}
      activeId={activeCountryId}
      handleClick={setActiveCountryId}
      placeholder="registrations.selectors.country"
      data={COUNTRY_MOCK}
    />
  );
};

export default CountrySelector;
