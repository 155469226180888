import cln from 'classnames';

import animation from 'styles/animation.module.css';

import PhoneInput from 'components/ui/Input/Phone';

type Props = {
  classes?: string;
  onChange: (v: string) => void;
};

const PhoneSignInForm = ({ classes, onChange }: Props) => (
  <div className={cln(animation.animationFadeIn, classes)}>
    <PhoneInput
      isHidePlaceholder
      withConfirm={false}
      placeholder="walletPopUp.forms.phone"
      size="full"
      onChange={onChange}
    />
  </div>
);

export default PhoneSignInForm;
