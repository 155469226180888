import { setCookie } from 'utils/cookie';
import { getValueByIdFromUrl } from 'utils/urlParser';

export const setExistingValueToCookie = (key: string, url: string) => {
  const value = getValueByIdFromUrl(key, url);

  if (value) {
    setCookie(key, value, 28);
  }

  return value;
};
