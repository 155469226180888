import { useMemo, useState } from 'react';

import {
  InfoSvg,
  HOCOutsideClick,
  NotificationErrorSvg,
} from 'decryption_component-kit';

import styles from './Tooltip.module.css';

type Props = {
  isError: boolean;
  src: string;
};

const InputTooltip = ({
  isError,
  src = '/icons-elon/payments/banks/turkish/akbank.svg',
}: Props) => {
  const [tooltipState, setTooltipState] = useState(false);

  const toggleShowState = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    setTooltipState(!tooltipState);
  };

  return (
    <div className={styles.inputTooltip}>
      {/* {isError && <NotificationErrorSvg />} */}

      <InfoSvg classes={styles.grayColor} handleClick={toggleShowState} />

      {tooltipState && (
        <HOCOutsideClick
          classes={styles.tooltipWrapper}
          handleFunc={toggleShowState}
        >
          <img src={src} className={styles.bankLogo} />

          <div className={styles.example}>TR46 0004 60.. .... .... .... 58</div>
        </HOCOutsideClick>
      )}
    </div>
  );
};

export default InputTooltip;
