import { useNavigate, useParams } from 'react-router';
import { useCasinoGames } from 'decryption_protocol_hooks';
import { CasinoGamesResponse } from 'decryption_protocol/dist/lib/store/casino/entities/games/types';

import CasinoGridGames from 'components/ui/CasinoGridGames';
import NotFoundData from 'components/ui/DataNotFound';

import { CASINO_LIMIT_GAMES } from 'constants/transport';

import { useZustandCasinoSearch } from 'stores/casino/search';
import { getCasinoSearchValueSelector } from 'stores/casino/search/selectors';

import { ERouteNames } from 'Routes/types';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './CasinoGridGames.module.css';

const GridGames = ({
  providerId = null,
  basePath = ERouteNames.CasinoCategories,
}: {
  providerId?: number | null;
  basePath?: string;
}) => {
  const {
    categoryId = 0,
    subCategoryId = 0,
    page = 1,
    scroll = 0,
  } = useParams();

  const search = useZustandCasinoSearch(getCasinoSearchValueSelector);

  const navigate = useNavigate();

  const { games = [], isLastPage = true } = useHookFabric({
    fn: useCasinoGames,
    customProps: {
      categoryId: +categoryId ? +categoryId : null,
      subCategoryId: +subCategoryId ? +subCategoryId : null,
      limit: CASINO_LIMIT_GAMES,
      providerId,
      page,
      search,
    },
  }) as CasinoGamesResponse;

  const customGameHandler = (div: HTMLDListElement) => {
    const path = basePath
      .replaceAll(':categoryId', `${categoryId}`)
      .replaceAll(':subCategoryId', `${subCategoryId || 'all'}`)
      .replaceAll(':page', `${page}`)
      .replaceAll(':scroll', `${div.offsetTop - 130}`);

    navigate(path);
  };

  const handleShowMore = () => {
    const top = window.pageYOffset || document.documentElement.scrollTop;

    const newPage = +page + 1;

    const path = basePath
      .replaceAll(':categoryId', `${categoryId}`)
      .replaceAll(':subCategoryId', `${subCategoryId || 'all'}`)
      .replaceAll(':page', `${newPage}`)
      .replaceAll(':scroll', `${top}`);

    navigate(path);
  };

  return (
    <div className={styles.wrapperCasinoGridGames}>
      <CasinoGridGames
        games={games}
        isLastPage={isLastPage}
        handleClickShowMore={handleShowMore}
        top={+scroll || 0}
        customGameHandler={customGameHandler}
      />

      {!games.length && <NotFoundData />}
    </div>
  );
};

export default GridGames;
