import { create } from 'zustand';
import { EBetSlipTabs, ZustandBetSlipStore } from './types';

export const useZustandBetSlipStore = create<ZustandBetSlipStore>((set) => ({
  activeTab: EBetSlipTabs.Win,

  setActiveTab: (activeTab) => {
    set({ activeTab });
  },
}));
