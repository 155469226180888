import { create } from 'zustand';
import { EActiveRightSideTab, ZustandRightSideStore } from './types';

export const useZustandRightSideStore = create<ZustandRightSideStore>(
  (set, get) => ({
    isFullView: false,
    activeTab: EActiveRightSideTab.ActiveBattle,

    setRightSideTab: (activeTab) => {
      set({ activeTab });
    },

    setRightSideDrawerView: (isFullView) => {
      if (isFullView === undefined) {
        const { isFullView } = get();

        return set({ isFullView: !isFullView });
      }

      return set({ isFullView });
    },
  })
);
