import { create } from 'zustand';
import { ZustandBettingTimeFilterStore } from './types';

export const useZustandEventTimeFilterStore =
  create<ZustandBettingTimeFilterStore>((set) => ({
    time: null,

    setActiveTime: (time) => {
      set({ time });
    },
  }));
