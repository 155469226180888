import React, { useEffect, useState } from 'react';

import AmountInput from 'components/ui/Input/AmountInput';

import { PaymentChannelType } from 'decryption_protocol/dist/lib/store/user/types';

import styles from '../Withdraw.module.css';
import Input from 'components/ui/Input/Base';
import { setPaymentsCurrentStepAction } from 'stores/money/payments/selectors';
import { useZustandFormsStore } from 'stores/money/forms/store';
import { setFormsInfoAction } from 'stores/money/forms/selectors';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';
import { Steps } from 'stores/money/payments/types';
import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';
import { DefaultInputType } from 'components/ui/Input/Base/types';
import { DEFAULT_INPUT_VALUE } from 'components/ui/Input/Base/constant';
import { validationTRC } from 'utils/validation';

type Props = {
  min: number;
  max: number;
  channelId:
    | PaymentChannelType.TRC
    | PaymentChannelType.SOLANA
    | PaymentChannelType.BEP
    | PaymentChannelType.ERC;
};

const CriptoWithdraw = (props: Props) => {
  const { min, max, channelId } = props;

  const setFormInfo = useZustandFormsStore(setFormsInfoAction);

  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  const currencyId = useZustandUserStore(getUserCurrencyIdSelector);

  const [amount, setAmount] = useState('');

  const [TRC20Address, setTrcAddress] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const validationTrc = (value: string) => {
    setTrcAddress(validationTRC(value, 'errors.invalidValue'));
  };

  useEffect(() => {
    if (amount && currencyId && TRC20Address.value && !TRC20Address.errorText) {
      setFormInfo({
        isDeposit: false,
        form: {
          amount,
          channelId,
          TRC20Address: TRC20Address.value,
          currencyId,
          requestId: ERequestIds.CriptoWithdraw,
        },
      });

      setStep(Steps.FilledForm);
    } else {
      setStep(Steps.Amount);
      setFormInfo(null);
    }
  }, [amount, currencyId, TRC20Address, channelId]);

  return (
    <div>
      <AmountInput
        isWithdraw
        placeholder="walletPopUp.forms.amount"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        size="full"
        onChange={setAmount}
        isPresets={false}
        min={min}
        max={max}
        wrapperClasses={styles.marginInputBottom}
      />

      <Input
        placeholder="walletPopUp.forms.address"
        errorText={TRC20Address.errorText}
        onChange={validationTrc}
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        size="full"
      />
    </div>
  );
};

export default CriptoWithdraw;
