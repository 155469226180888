import { RecoveryFormType } from './types';

export const RECOVERY_TABS = [
  {
    label: 'recoveryPsw.tabs.phone',
    value: RecoveryFormType.Phone,
  },
  {
    label: 'recoveryPsw.tabs.email',
    value: RecoveryFormType.Email,
  },
];
