export interface ZustandBetSlipStore extends ZustandBetSlipStoreEntities {
  setActiveTab: (tab: EBetSlipTabs) => void;
}
interface ZustandBetSlipStoreEntities {
  activeTab: EBetSlipTabs;
}

export enum EBetSlipTabs {
  Win,
  Risk,
}
