import { useEffect, useState } from 'react';

import AmountInput from 'components/ui/Input/AmountInput';
import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';

import { PaymentChannelType } from 'decryption_protocol/dist/lib/store/user/types';

import styles from '../Withdraw.module.css';
import Input from 'components/ui/Input/Base';
import { setPaymentsCurrentStepAction } from 'stores/money/payments/selectors';
import { useZustandFormsStore } from 'stores/money/forms/store';
import { setFormsInfoAction } from 'stores/money/forms/selectors';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';

import CoinPayInputWallet from 'components/ui/Input/CoinpayWallet';
import { Steps } from 'stores/money/payments/types';

type Props = {
  min: number;
  max: number;
  channelId:
    | PaymentChannelType.BTC
    | PaymentChannelType.LTC
    | PaymentChannelType.BCH
    | PaymentChannelType.ADA
    | PaymentChannelType.ETH
    | PaymentChannelType.DOGE
    | PaymentChannelType.XRP
    | PaymentChannelType.USDTE
    | PaymentChannelType.USDTT
    | PaymentChannelType.BNB
    | PaymentChannelType.BNBBSC
    | PaymentChannelType.BUSD
    | PaymentChannelType.USDC
    | PaymentChannelType.TRX
    | PaymentChannelType.DAI;
};

const CoinCriptoWithdraw = (props: Props) => {
  const { min, max, channelId } = props;

  const setFormInfo = useZustandFormsStore(setFormsInfoAction);

  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  const currencyId = useZustandUserStore(getUserCurrencyIdSelector);

  const [amount, setAmount] = useState('');

  const [tag, setTag] = useState('');

  const [wallet, setWallet] = useState('');

  const isFormWithTag =
    channelId === PaymentChannelType.BNB ||
    channelId === PaymentChannelType.XRP;

  useEffect(() => {
    const inputs = [amount, wallet];

    if (isFormWithTag) {
      inputs.push(tag);
    }

    if (currencyId && !inputs.includes('')) {
      setFormInfo({
        isDeposit: false,
        form: {
          amount,
          channelId,
          currencyId,
          tag,
          address: wallet,
          requestId: ERequestIds.WithdrawCoinPay,
        },
      });
      setStep(Steps.FilledForm);
    } else {
      setStep(Steps.Amount);
      setFormInfo(null);
    }
  }, [amount, wallet, isFormWithTag, tag, currencyId, channelId]);

  return (
    <div>
      <AmountInput
        isWithdraw
        placeholder="walletPopUp.forms.amount"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        size="full"
        onChange={setAmount}
        isPresets={false}
        min={min}
        max={max}
        wrapperClasses={styles.marginInputBottom}
      />

      {/* 1BvBMSEYstWetqTFn5Au4m4GFg7xJaNVN2 */}
      <CoinPayInputWallet
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        size="full"
        classes={styles.marginInputBottom}
        onChange={setWallet}
        placeholder="walletPopUp.forms.walletNumber"
        channelId={channelId}
      />

      {isFormWithTag && (
        <Input
          inputStyleType="border-colorful"
          inputLabelType="labelUp"
          size="full"
          placeholder="walletPopUp.forms.tag"
          onChange={setTag}
          isHidePlaceholder
        />
      )}
    </div>
  );
};

export default CoinCriptoWithdraw;
