import { getValueByIdFromUrl } from 'utils/urlParser';
import { PWA, PWA_CLEAR } from './localstoreKeys';
import { ERegistrationForm } from 'stores/forms/signUp/types';
import { ModalTypes } from 'stores/modal/types';

export const REGISTRATION_UTM_MEDIUM = 'utm_medium';

export const REGISTRATION_UTM_SOURCE = 'utm_source';

export const REGISTRATION_UTM_CAMPAING = 'utm_campaign';

export const REGISTRATION_CLICK_ID_CAMPAING = 'clickid';

export const LANGUAGE_NAME_URL = getValueByIdFromUrl(
  'lang',
  window.location.href
);

export const MONTHLY_BONUS_AVAILABLE =
  getValueByIdFromUrl('monthly_bonus_activation', window.location.href) ===
  'dagdagdafsdfsfdsfs';

// TODO for retation send after signin
export const UTM_CONTENT = 'utm_content';

export const VALID_MOBILE_APP_PARAM = 'is_valid_mobile';

export const IS_VALID_MOBILE_APP = Boolean(
  getValueByIdFromUrl(VALID_MOBILE_APP_PARAM, window.location.href)
);

export const OPEN_BONUSES = 'is_open_bonuses_pop_up';

export const IS_BONUS_SHOULD_OPEN =
  getValueByIdFromUrl(OPEN_BONUSES, window.location.href) === '1';

const ENCRYPT_KEY = 'sessionId';

export const ENCRYPT_DATA = getValueByIdFromUrl(
  ENCRYPT_KEY,
  window.location.href
);

export const IS_PWA = true; //Boolean(getValueByIdFromUrl(PWA, window.location.href));

export const IS_CLEAR_PWA = Boolean(
  getValueByIdFromUrl(PWA_CLEAR, window.location.href)
);

// DEBAGGING

export const INR_WITH_VPA =
  getValueByIdFromUrl('vpa', window.location.href) === '1';

// ======================== REGISTRATION ========================

export const GET_AVAILABLE_REGISTRATION_FORM = (function () {
  try {
    const value = getValueByIdFromUrl('r-t', window.location.href);

    const keys = value.split(',');

    const availableRegistrationForm: ERegistrationForm[] = [];

    keys.forEach((key: string) => {
      if (key in ERegistrationForm) {
        availableRegistrationForm.push(
          ERegistrationForm[key as keyof typeof ERegistrationForm]
        );
      }
    });

    return availableRegistrationForm;
  } catch (error) {
    return [];
  }
})();

export const GET_INITIAL_REGISTRATION_FORM = (function () {
  try {
    const value = getValueByIdFromUrl('r-i-t', window.location.href);

    if (value in ERegistrationForm && typeof value === 'string') {
      return ERegistrationForm[value as keyof typeof ERegistrationForm];
    }

    return null;
  } catch (error) {
    return null;
  }
})();

// ======================== END ========================

export const INITIAL_MODAL = (function () {
  try {
    const value = getValueByIdFromUrl('i-m', window.location.href);

    if (value in ModalTypes) {
      return ModalTypes[value as keyof typeof ModalTypes];
    }

    return ModalTypes.Unknown;
  } catch (error) {
    return ModalTypes.Unknown;
  }
})();
export const INITIAL_MODAL_ADDITION_INFO = (function () {
  try {
    const value = getValueByIdFromUrl('i-m-a-i', window.location.href);

    const keys = value.split(',');
    const obj: { [key: string]: boolean } = {};

    keys.forEach((k) => {
      obj[k] = true;
    });

    return obj;
  } catch (error) {
    console.log('🚀 ~ error:', error);

    return null;
  }
})();
