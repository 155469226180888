import { create } from 'zustand';
import { ZustandCasinoDrawerStore } from './types';

export const useZustandCasinoDrawerStore = create<ZustandCasinoDrawerStore>(
  (set, get) => ({
    isFullView: true,

    setCasinoDrawerView: (isFullView) => {
      if (isFullView === undefined) {
        const { isFullView } = get();

        return set({ isFullView: !isFullView });
      }

      return set({ isFullView });
    },
  })
);
