import { useCallback, useMemo } from 'react';
import { useGetProviders } from 'decryption_protocol_hooks';
import { IProvider } from 'decryption_protocol/dist/lib/store/casino/entities/providers/types';

import ProviderThree from '../ProviderThree';
import ProviderItem from '../ProviderItem';

import { useZustandModalStore } from 'stores/modal/store';
import { getModalAdditionalInfoSelector } from 'stores/modal/selectors';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './ProviderList.module.css';

type Props = {
  searchValue: string;
  onlyWagering?: boolean;
};

const ProviderList = ({ searchValue, onlyWagering = false }: Props) => {
  const { categoryId, subCategoryId } =
    useZustandModalStore(getModalAdditionalInfoSelector) || {};

  const providers = useHookFabric({
    fn: useGetProviders,
    customProps: {
      withGameCounters: true,
      categoryId,
      subCategoryId,
      sortFn: useCallback(
        () => (p1: IProvider, p2: IProvider) => p1.name.localeCompare(p2.name),
        []
      ),
    },
  }) as (IProvider & { gameCounter: number })[];

  const { searchedProviders, modifyProviders } = useMemo(() => {
    if (!providers.length) {
      return { searchedProviders: [], modifyProviders: {} };
    }

    let searchedProviders: (IProvider & { gameCounter: number })[] = [];

    if (searchValue) {
      searchedProviders = providers.filter((provider) =>
        provider.name
          .toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase())
      );
    }

    const modifyProviders: {
      [letter: string]: (IProvider & { gameCounter: number })[];
    } = {};

    providers.forEach((p) => {
      if (modifyProviders[p.name[0]]) {
        modifyProviders[p.name[0]].push(p);
      } else {
        modifyProviders[p.name[0]] = [p];
      }
    });

    return { searchedProviders, modifyProviders };
  }, [providers, searchValue]);

  return (
    <div className={styles.wrapperProviderList}>
      {searchValue.length ? (
        searchedProviders.map((provider) => (
          <ProviderItem
            key={provider.id}
            name={provider.name}
            id={provider.id}
            gameCounter={provider.gameCounter}
          />
        ))
      ) : (
        <ProviderThree providerThree={modifyProviders} />
      )}
    </div>
  );
};

export default ProviderList;
