export interface ZustandRightSideStore extends ZustandRightSideStoreEntities {
  setRightSideDrawerView: (view?: boolean | undefined) => void;
  setRightSideTab: (activeTab: EActiveRightSideTab) => void;
}

interface ZustandRightSideStoreEntities {
  isFullView: boolean;
  activeTab: EActiveRightSideTab;
}

export enum EActiveRightSideTab {
  ActiveBattle,
  ProviderBattles,
}
