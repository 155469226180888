import { create } from 'zustand';
import { UpdateStatus, ZustandProfileStore } from './types';

export const useZustandProfileStore = create<ZustandProfileStore>(
  (set, get) => ({
    errorText: '',
    status: UpdateStatus.Unknown,

    setError: (errorText) => {
      set({ errorText });
    },
    setStatus: (status) => {
      let errorText = get().errorText;

      if (status !== UpdateStatus.Rejected) {
        errorText = '';
      }
      set({ status, errorText });
    },
  })
);
