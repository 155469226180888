import { create } from 'zustand';
import { EAuthFormType, ZustandSignInStore } from './types';

export const useZustandSignInStore = create<ZustandSignInStore>((set, get) => ({
  formType: EAuthFormType.Email,

  setFormType: (formType) => {
    set({ formType });
  },
}));
