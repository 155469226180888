import { BaseInputProps } from 'decryption_component-kit/dist/lib/components/ui/Inputs/BaseInput/types';
import { BaseInput, useTranslate } from 'decryption_component-kit';

const ComponentKitBaseInput = (props: BaseInputProps) => {
  const { placeholder } = props;

  const { name, getText } = useTranslate(placeholder, '');

  return (
    <BaseInput
      {...props}
      placeholder={name}
      errorText={props.errorText ? getText(props.errorText) : props.errorText}
    />
  );
};

export default ComponentKitBaseInput;
