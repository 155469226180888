import { BaseButton, useTranslate } from 'decryption_component-kit';

import { BaseButtonProps } from 'decryption_component-kit/dist/lib/components/ui/Buttons/BaseButton/types';

const ComponentKitBaseButton = (props: BaseButtonProps) => {
  const { text = '' } = props;

  const { name } = useTranslate(text, '');

  return <BaseButton {...props} text={name} />;
};

export default ComponentKitBaseButton;
