import { useAuth } from 'decryption_protocol_hooks';

import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';
import { isMobileDeviceSelector } from 'stores/device/selectors';
import { useZustandDeviceStore } from 'stores/device/store';

type Props = {
  fn: any;
  customProps?: any;
};

export const useHookFabric = (props: Props) => {
  const { fn, customProps } = props;

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const isAuthInfo = useAuth({
    isConnectedApp,
  });

  const isMobile = useZustandDeviceStore(isMobileDeviceSelector);

  // Notice: sometimes need to get desktop entity
  const fnProps = {
    isMobile,
    isConnectedApp,
    isAuth: Boolean(isAuthInfo),
    ...customProps,
  };

  return fn(fnProps);
};
