import { useEffect, useRef } from 'react';
import InitialRoutes from 'Routes';

import { getWebConfigurationAction } from 'stores/configuration/selectors';
import { useZustandWebConfiguration } from './stores/configuration/store';
import {
  useZustandLanguagesKitStore,
  languageSelectors,
} from 'decryption_component-kit';
import {
  CASINO_INSIDE_CASINO,
  MOBILE_GET_KEYCHAIN_DATA,
} from 'constants/transport';

import HOCDevice from 'components/HOCs/HOCDevice';
import { LANGUAGES, TOKEN } from 'constants/localstoreKeys';
import HOCTelegram from 'components/HOCs/HOCTelegram';
import { HOCSocket } from './components/HOCs/HOCSocket';

import { useZustandMarketingTabsStore } from 'stores/marketing/store';
import { isValidMobileAppSelector } from 'stores/marketing/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';
import { setPermanentTokenAction } from 'stores/user/personal/selectors';

import { initFirebase, setupNotifications } from 'services/firebase';

import { usePush } from 'hooks/push/usePush';
import useVisibilityChange from 'hooks/push/useVisibilityChange';

import { APP_TYPE, BROWSER_TYPE } from 'constants/ui';

import { BrowserType } from 'utils/browser';
import { LANGUAGE_NAME_URL } from 'constants/marketingTags';
import { getFromLocalStorage } from 'utils/localstorage';
import { useZustandModalStore } from 'stores/modal/store';

const App = () => {
  const token = usePush();

  const isForeground = useVisibilityChange();

  const isMobileApp = useZustandMarketingTabsStore(isValidMobileAppSelector);

  const getWebConfigurations = useZustandWebConfiguration(
    getWebConfigurationAction
  );
  const setInitLanguages = useZustandLanguagesKitStore(
    languageSelectors.setInitialLanguageKitAction
  );

  const disableStrickMode = useRef<boolean>(false);

  const isValidMobileApp = useZustandMarketingTabsStore(
    isValidMobileAppSelector
  );

  const setToken = useZustandUserStore(setPermanentTokenAction);

  const getMessage = ({ data }: { data: any }) => {
    try {
      const { payload } = data;
      const { key, value } = payload;

      if (key === TOKEN) {
        setToken(value);
      }
    } catch (error) {
      console.error('ERROR getMessage_Mobile');
    }
  };

  useEffect(() => {
    if (!isValidMobileApp) {
      return;
    }
    window.addEventListener('message', getMessage, false);

    return () => {
      window.removeEventListener('message', getMessage, false);
    };
  }, [isValidMobileApp]);

  useEffect(() => {
    if (disableStrickMode.current) {
      return;
    }

    window?.parent?.postMessage(CASINO_INSIDE_CASINO, '*');

    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: MOBILE_GET_KEYCHAIN_DATA,
        payload: {
          key: TOKEN,
        },
      })
    );

    disableStrickMode.current = true;
    getWebConfigurations();

    setInitLanguages(
      APP_TYPE,
      LANGUAGE_NAME_URL || getFromLocalStorage(LANGUAGES)
    );
  }, [getWebConfigurations, setInitLanguages]);

  useEffect(() => {
    if (!isMobileApp && BROWSER_TYPE !== BrowserType.Iphone) {
      initFirebase();
      setupNotifications();
    }
  }, [isMobileApp]);

  return (
    <HOCSocket>
      <HOCDevice>
        <HOCTelegram>
          <InitialRoutes />
        </HOCTelegram>
      </HOCDevice>
    </HOCSocket>
  );
};

export default App;

// @ts-ignore
window.modal = useZustandModalStore;
