import { Text } from 'decryption_component-kit';

import {
  getBonusBalanceValueSelector,
  getMainBalanceValueSelector,
} from 'stores/user/balance/selectors';
import { useZustandBalanceStore } from 'stores/user/balance/store';

import styles from './BalanceBlock.module.css';

const BalanceBlock = () => {
  const bonusBalance = useZustandBalanceStore(getBonusBalanceValueSelector);

  const mainBalance = useZustandBalanceStore(getMainBalanceValueSelector);

  return (
    <>
      <div className={styles.balanceBlock}>
        <div>
          <Text idT="profile.content.myAccount.balance.mainBalance" />
        </div>
        <b>{mainBalance}</b>
      </div>
      <div className={styles.balanceBlock}>
        <div>
          <Text idT="profile.content.myAccount.balance.bonusBalance" />
        </div>

        <b>{bonusBalance}</b>
      </div>
    </>
  );
};

export default BalanceBlock;
