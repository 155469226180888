import cln from 'classnames';

import styles from './SkeletLoader.module.css';

type Props = {
  classes?: string;
};

const SkeletLoader = ({ classes }: Props) => (
  <div className={cln(styles.wrapperSkeletLoader, classes)}>
    <div className={styles.loader} />
  </div>
);

export default SkeletLoader;
