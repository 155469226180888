import { Outlet, useLocation } from 'react-router-dom';
import cln from 'classnames';

import ProfileTabs from 'components/ProfileComponents/ProfileTabs';
import SignIn from 'components/Alerts/Modal/components/SignIn';

import { isAuthorizedUserSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';

import { ERouteNames } from 'Routes/types';

import animation from 'styles/animation.module.css';
import styles from './HOCProtectedRoute.module.css';

const HOCProtectedRoute = () => {
  const isAuthorized = useZustandUserStore(isAuthorizedUserSelector);

  const { pathname } = useLocation();

  if (!isAuthorized) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SignIn />
      </div>
    );
  }

  return (
    <div className={cln(animation.animationFadeIn, styles.wrapper)}>
      {pathname !== ERouteNames.PromotionMonthly &&
        pathname !== ERouteNames.PromoCode && <ProfileTabs />}

      <Outlet />
    </div>
  );
};

export default HOCProtectedRoute;
