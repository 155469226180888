import { useParams } from 'react-router';
import { EAdaptedGameSpecialLabel } from 'decryption_protocol/dist/lib/store/casino/entities/games/types';

import ProviderName from '../CasinoProviderName';
import GameActions from '../GameActions';

import styles from './CasinoGameHeaderActions.module.css';

type Props = {
  providerName: string;
  providerId: number;
  specialLabel: EAdaptedGameSpecialLabel;
};

const CasinoGameHeaderActions = ({
  providerName,
  providerId,
  specialLabel,
}: Props) => {
  const { gameId = -1 } = useParams();

  return (
    <div className={styles.wrapperCasinoGameHeader}>
      <ProviderName
        providerName={providerName}
        providerId={providerId}
        specialLabel={specialLabel}
        justIcon={false}
      />

      <GameActions gameId={+gameId} />
    </div>
  );
};

export default CasinoGameHeaderActions;
