import cln from 'classnames';

import {
  FirstBonusSvg,
  SecondBonusSvg,
  ThirdBonusSvg,
} from 'decryption_component-kit';

import { AdaptedPack, PackName } from 'hooks/useBonusPack';

import styles from './Tabs.module.css';

type Props = {
  activePackName: PackName;
  changeInx: (inx: PackName) => () => void;
  packs: AdaptedPack[];
};

const Tabs = ({ activePackName, changeInx, packs }: Props) => (
  <div className={styles.wrapperTabs}>
    {packs.map((pack) => (
      <div
        key={pack.packName}
        className={cln(styles.wrapperTab, {
          [styles.activeTab]: pack.packName === activePackName,
        })}
        onClick={changeInx(pack.packName)}
      >
        {pack.packName === 'bronze' && <FirstBonusSvg />}
        {pack.packName === 'silver' && <SecondBonusSvg />}
        {pack.packName === 'gold' && <ThirdBonusSvg />}
      </div>
    ))}
  </div>
);

export default Tabs;
