import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ERouteNames } from 'Routes/types';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes('/casino/categories')) {
      return;
    }
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      // behavior: 'instant',
    });
  }, [pathname]);

  return null;
}
