import cln from 'classnames';

import { IProvider } from 'decryption_protocol/dist/lib/store/casino/entities/providers/types';

import ProviderBox from 'components/ui/Buttons/ProviderButtons';
import CasinoHomeRowHeader from './components/ProviderRowHeader';

import { useGetProviders } from 'decryption_protocol_hooks';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './ProviderRow.module.css';

type Props = {
  classes?: string;
};

const ProviderRow = ({ classes }: Props) => {
  const providers = useHookFabric({ fn: useGetProviders }) as IProvider[];

  return (
    <div className={cln(styles.wrapperProviderRows, classes)}>
      <CasinoHomeRowHeader categoryName="drawers.casino.providerBtn" />

      <div className={styles.providerRowsWrapper}>
        {providers.map((p) => (
          <ProviderBox name={p.name} id={p.id} key={p.id} />
        ))}
      </div>
    </div>
  );
};

export default ProviderRow;
