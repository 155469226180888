import { useRef, useState } from 'react';
import cln from 'classnames';
import { Ordinar } from 'decryption_protocol/dist/lib/store/betting/entities/tickets/types';
import { useInView } from 'decryption_component-kit';

import CashOutButton from 'components/ui/Buttons/CashOutButton';
import TicketEventInfo from '../Templates/TicketEventInfo';
import TicketTitle from '../Templates/TicketTitle';
import StakeInfo from '../Templates/StakeInfo';
import CashoutRecords from '../Templates/CashoutRecords';

import ticketStyles from '../../Tickets.module.css';
import MatchStatus from '../Templates/MatchStatus';

type Props = Ordinar & {
  ticketId: number;
  cashoutRecords: any[];
  timestamp: number;
  winAmount: number;
  stake: number;
}; // todo types

const OrdinarTicket = (props: Props) => {
  const {
    acceptedOdd,
    eventHomeName,
    eventAwayName,
    marketName,
    cashoutRecords,
    ticketId,
    timestamp,
    outcomeName,
    stake,
    winAmount,
    status,
    settlementOdd,
  } = props;

  const wrapperContainer = useRef<HTMLDivElement | null>(null);

  const [isVisible, setVisibleState] = useState<boolean>(true);

  const isInView = useInView(wrapperContainer, {
    rootMargin: '150px',
  });

  return (
    <div
      className={cln(ticketStyles.ticketWrapper, {
        [ticketStyles.isHiddenContend]: !isVisible,
        [ticketStyles.disabledPaddingBtn]: cashoutRecords.length,
      })}
      ref={wrapperContainer}
    >
      <TicketTitle
        timestamp={timestamp}
        ticketId={ticketId}
        classes={ticketStyles.horizontalPadding}
        toggleState={setVisibleState}
        isVisible={isVisible}
        ticketTitle="ordinar"
      />

      {/* isInView && */}
      {isVisible && (
        <>
          <div className={ticketStyles.ordinarInfo}>
            <TicketEventInfo
              eventHomeName={eventHomeName}
              eventAwayName={eventAwayName}
              marketName={marketName}
              outcomeName={outcomeName}
            />

            <div className={ticketStyles.rightColumn}>
              <div className={ticketStyles.acceptedOdd}>{acceptedOdd}</div>

              <MatchStatus status={status} isWin={Boolean(settlementOdd)} />
            </div>
          </div>

          <StakeInfo
            stake={stake}
            winAmount={winAmount}
            classes={ticketStyles.marginB}
          />

          <CashOutButton
            ticketId={ticketId}
            classes={cln(ticketStyles.horizontalPadding, ticketStyles.marginB)}
          />

          {!!cashoutRecords.length && (
            <CashoutRecords cashoutRecords={cashoutRecords} />
          )}
        </>
      )}
    </div>
  );
};

export default OrdinarTicket;
