import styles from './OddAnimation.module.css';

type Props = {
  odd: number;
};

const OddAnimation = (props: Props) => {
  const { odd } = props;

  return <div className={styles.wrapperOddAnimation}>{odd || '-'}</div>;
};

export default OddAnimation;
