export enum BankIds {
  IsBankasu = 1,
  GarantiBank = 2,
  Akbank = 3,
  Vakifbank = 4,
  Ziraat = 5,
  Yapi = 6,
  Denizbank = 7,
  Finansbank = 8,
  Sekerbank = 9,
  Kuveyt = 10,
  TEB = 11,
  Albaraka = 12,
  Halkbank = 13,
  Odea = 15,
  TurkiyeFinans = 16,
  Fibabank = 19,
  ING = 20,
  Vakif = 21,
  NKolay = 22,
  FastEft = 23,
  ZiraatKatilim = 42,
}
export type BankIdInfo = {
  id: BankIds;
  name: string;
  rank: number;
  minLimit: string | number;
  maxLimit: string | number;
  src: string;
};
export type TurkishBanks = {
  name: string;
  src: string;
  id: number;
  minLimit: number;
  maxLimit: number;
};
