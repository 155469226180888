import {
  useGetWeeklyBonus,
  useGetActiveBonuses,
  useGetRakeBack,
  useAuth,
} from 'decryption_protocol_hooks';
import {
  ReturnTypeRakeBake,
  ReturnTypeWeeklyBonus,
} from 'decryption_protocol/dist/lib/store/operations/entities/vipClub/types';
import {
  DrawerButton,
  useTranslate,
  BonusGiftSvg,
} from 'decryption_component-kit';

import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { useZustandCasinoDrawerStore } from 'stores/drawers/casino/store';
import { isFullCasinoDrawerSelector } from 'stores/drawers/casino/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

import { ModalTypes } from 'stores/modal/types';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './BonusButtons.module.css';

const BonusButtons = () => {
  const closeDrawer = useZustandModalStore(closeAndOpenModalAction);

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const isAuth = useAuth({ isConnectedApp });

  const isFullView = useZustandCasinoDrawerStore(isFullCasinoDrawerSelector);

  const { getText } = useTranslate('', '');

  const { bonusOnDeposit = [], freeSpins = [] } = useHookFabric({
    fn: useGetActiveBonuses,
  }) as any;

  const weekly = useHookFabric({
    fn: useGetWeeklyBonus,
  }) as null | ReturnTypeWeeklyBonus;

  const rakebackInfo = useHookFabric({
    fn: useGetRakeBack,
  }) as null | ReturnTypeRakeBake;

  const openModal = (t: ModalTypes) => () => {
    closeDrawer(t);
  };

  const count =
    Number(Boolean(rakebackInfo && rakebackInfo.rakeback)) +
    Number(Boolean(weekly && weekly.amount)) +
    bonusOnDeposit.length +
    freeSpins.length;

  return (
    <DrawerButton
      size="full"
      text={getText('drawers.casino.bonuses')}
      handleClick={openModal(isAuth ? ModalTypes.Bonuses : ModalTypes.SignIn)}
      startIcon={<BonusGiftSvg classes={styles.giftSvg} />}
      classes={styles.marginBottom}
      isFullView={isFullView}
    >
      {count ? <div className={styles.red}>{count}</div> : undefined}
    </DrawerButton>
  );
};

export default BonusButtons;
