export enum BrowserType {
  Unknown,
  Chrome,
  Firefox,
  Safari,
  Opera,
  Edge,
  Android,
  Iphone,
}

export const detectBrowser = () => {
  const userAgent = navigator.userAgent || '';

  if (/android/i.test(userAgent)) {
    return BrowserType.Android;
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return BrowserType.Iphone;
  }

  // if (userAgent.match(/chrome|chromium|crios/i)) {
  //   return BrowserType.Chrome;
  // }

  // if (userAgent.match(/firefox|fxios/i)) {
  //   return BrowserType.Firefox;
  // }

  // if (userAgent.match(/safari/i)) {
  //   return BrowserType.Safari;
  // }

  // if (userAgent.match(/opr\//i)) {
  //   return BrowserType.Opera;
  // }

  // if (userAgent.match(/edg/i)) {
  //   return BrowserType.Edge;
  // }

  // if (userAgent.match(/android/i)) {
  //   return BrowserType.Android;
  // }

  // if (userAgent.match(/iphone/i)) {
  //   return BrowserType.Iphone;
  // }

  return BrowserType.Unknown;
};
