import { UserInfo } from 'decryption_protocol/dist/lib/store/user/types';

export interface ZustandUserStore extends ZustandUserEntities {
  setUserInfo: (userInfo: UserInfo | null) => void;
  logout: () => void;
  updateUserInfo: (key: keyof UserInfo, value: string | number | null) => void;
  setPermanentToken: (token: string) => void;
  setKycStatus: (kys: EKYCStatus) => void;
  setPushToken: (t: string | null) => void;
}

interface ZustandUserEntities {
  userInfo: UserInfo | null;
  pushToken: string | null;
  permanentToken: string;
  kycStatus: EKYCStatus;
}

export enum EKYCStatus {
  Unknown = -1,
  NotVerified,
  NeedKYC,
  Pending,
  Accepted,
  Rejected,
  Ignore,
}
