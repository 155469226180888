import cln from 'classnames';

import { ReactComponent as SliderArrowSvg } from 'assets/base/arrow-back.svg';

import styles from './BonusSliderArrow.module.css';

type Props = {
  isRight?: boolean;
  disabled?: boolean;
  handleClick: () => void;
};

const BonusSlideArrow = (props: Props) => {
  const { isRight = false, handleClick, disabled } = props;

  return (
    <div
      className={cln(styles.sliderButton, {
        [styles.rotateArrow]: isRight,
        [styles.disabled]: disabled,
      })}
      onClick={handleClick}
    >
      <SliderArrowSvg className={styles.sliderArrowSvg} />
    </div>
  );
};

export default BonusSlideArrow;
