export interface ZustandSignInStore extends ZustandSignInEntities {
  setFormType: (id: EAuthFormType) => void;
}

interface ZustandSignInEntities {
  formType: EAuthFormType;
}

export enum EAuthFormType {
  // Unknown = 'unknown', // todo for configuration //
  Phone,
  Email,
  UserName,
}
