import { BaseButtonProps } from 'decryption_component-kit/dist/lib/components/ui/Buttons/BaseButton/types';

import { useZustandWebConfiguration } from 'stores/configuration/store';
import { getKYCLinkSelector } from 'stores/configuration/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';
import {
  getUserTokenSelector,
  isIgnoreKycStatusSelector,
} from 'stores/user/personal/selectors';
import { addNotificationAction } from 'stores/notification/selectors';
import { useZustandNotificationStore } from 'stores/notification/store';
import { ENotificationType } from 'stores/notification/types';

import {
  useZustandLanguagesKitStore,
  languageSelectors,
  useTranslate,
} from 'decryption_component-kit';
import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';

type Props = BaseButtonProps;

const KycButton = (props: Props) => {
  const { handleClick } = props;

  const { name } = useTranslate('profile.content.myAccount.kyc', '');

  const isIgnoreKyc = useZustandUserStore(isIgnoreKycStatusSelector);

  const kycUrl = useZustandWebConfiguration(getKYCLinkSelector);

  const activeLang = useZustandLanguagesKitStore(
    languageSelectors.getActiveLanguageNameKitSelector
  );

  const token = useZustandUserStore(getUserTokenSelector);

  const addNotification = useZustandNotificationStore(addNotificationAction);

  const handleGetKyc = async () => {
    if (!token || !kycUrl || !activeLang) {
      return;
    }
    handleClick?.();

    const url = kycUrl.replace('[LANG]', activeLang);

    try {
      const response: any = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (data.status === 'ERROR') {
        let errorKey = '';

        if (data.code === 8) {
          errorKey = 'errors.771';
        }

        if (data.code === 7) {
          errorKey = 'errors.770';
        }

        if (data.code === 1) {
          errorKey = 'errors.769';
        }

        throw new Error(errorKey);
      }

      const a = document.createElement('a');

      a.setAttribute('href', data.form_url);
      a.click();
    } catch (error: any) {
      addNotification({
        uid: `KYC_${new Date().getTime()}`,
        text: error.message,
        type: ENotificationType.Error,
      });
    }
  };

  if (isIgnoreKyc) {
    return null;
  }

  return (
    <ComponentKitBaseButton
      {...props}
      text={name}
      centreText
      handleClick={handleGetKyc}
    />
  );
};

export default KycButton;
