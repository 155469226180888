import { useState } from 'react';
import { ModalLayout, useTranslate } from 'decryption_component-kit';
import { usePromoCodeControllers } from 'decryption_protocol_hooks';

import ComponentKitBaseInputUi from 'components/ui/KitComponents/Inputs/ComponentKitInputUi';
import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';

import { ModalTypes } from 'stores/modal/types';
import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './PromoCodeModal.module.css';

const PromoCodeModal = () => {
  const [promoCode, setPromoCode] = useState('');

  const setModal = useZustandModalStore(closeAndOpenModalAction);

  const { name: title } = useTranslate('promoCodePage.promoCodeInputTitle', '');

  const { handleActivate } = useHookFabric({ fn: usePromoCodeControllers });

  const onCloseModal = () => {
    setModal(ModalTypes.Unknown);
  };

  const onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = target;

    setPromoCode(value);
  };

  const handlePromoCode = () => {
    // setModal(ModalTypes.PromoCodeActivate);
    handleActivate(promoCode);
  };

  return (
    <ModalLayout
      popUpHeaderProps={{
        title,
        transparent: true,
      }}
      buttonClose={onCloseModal}
    >
      <>
        {/* <PageTitle text="T.Promo Code" classes={styles.title} /> */}

        <ComponentKitBaseInputUi
          placeholder="promoCodePage.placeholder"
          inputStyleType="border-colorful"
          handleChange={onChange}
          value={promoCode}
          size="full"
          classes={styles.marginB}
        />

        <ComponentKitBaseButton
          text="promoCodePage.apply"
          color="primary"
          centreText
          disabled={!promoCode}
          size="--button-full"
          buttonHeight="--button-height-xl"
          handleClick={handlePromoCode}
          marginB="base-double"
        />
      </>
    </ModalLayout>
  );
};

export default PromoCodeModal;
