import { create } from 'zustand';
import { ZustandCasinoGameStore } from './types';

export const useZustandCasinoGamesStore = create<ZustandCasinoGameStore>(
  (set, get) => ({
    page: 1,
    casinoGameUrl: '',

    activeProviderId: null,

    setCasinoGameUrl: (casinoGameUrl) => {
      set({ casinoGameUrl });
    },
    setActiveProviderId: (activeProviderId) => {
      set({ activeProviderId });
    },

    setPage: (page) => {
      const currentPage = get().page;

      if (currentPage === page) {
        return;
      }
      set({ page });
    },
  })
);
