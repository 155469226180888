import cln from 'classnames';
import { useNavigate } from 'react-router';
import { useAnyActiveBonuses } from 'decryption_protocol_hooks';
import {
  PaymentTypes,
  Payment,
} from 'decryption_protocol/dist/lib/store/user/types';

import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';
import { Text } from 'decryption_component-kit';
import KycButton from 'components/ui/Buttons/KycButton';
import List from '../List';

import { useZustandUserStore } from 'stores/user/personal/store';
import {
  getUserCurrencyNameSelector,
  isNeedToKycSelector,
} from 'stores/user/personal/selectors';
import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { useZustandBalanceStore } from 'stores/user/balance/store';
import { getMainBalanceValueSelector } from 'stores/user/balance/selectors';

import { ModalTypes } from 'stores/modal/types';
import { ActiveWalletTab } from 'stores/money/payments/types';

import { sendAnalyticEvent } from 'services/apk';
import { useHookFabric } from 'hooks/useHookFabric';

import styles from './Methods.module.css';

type Props = {
  activeTab: ActiveWalletTab;
  setActiveMethod: (d: Payment) => void;
  // TODO rewrite type when will be add limits
  listInfo: PaymentTypes;
  activeChannelId: number | null;
};

const Methods = (props: Props) => {
  const { activeTab, listInfo, setActiveMethod } = props;

  const navigate = useNavigate();

  const setModal = useZustandModalStore(closeAndOpenModalAction);

  const isNeedToKyc = useZustandUserStore(isNeedToKycSelector);

  const isAvailableBonuses = useHookFabric({
    fn: useAnyActiveBonuses,
  }) as boolean;

  const currencyName = useZustandUserStore(getUserCurrencyNameSelector);

  const withdrawAmount =
    useZustandBalanceStore(getMainBalanceValueSelector) || 0;

  const isDisabledWithdraw = isNeedToKyc || isAvailableBonuses;

  const handleJumpTo = () => {
    setModal(ModalTypes.Unknown);

    setModal(ModalTypes.Bonuses);
  };

  const handleMethodEvents =
    (type: 'deposit' | 'withdraw') => (info: Payment) => {
      setActiveMethod(info);

      sendAnalyticEvent({
        eventName: type,
        eventBody: { id: info.id },
      });
    };

  const isDeposit = activeTab === 'deposit';

  const isAvailableListInfo = isDeposit ? true : !isDisabledWithdraw;

  const listData = isDeposit ? listInfo.deposit : listInfo.withdraw;

  return (
    <div className={styles.wrapperMethods}>
      <div className={styles.methods}>
        <div className={styles.title}>
          <Text idT="walletPopUp.chooseMethods" />

          {!isDeposit && !withdrawAmount && !isDisabledWithdraw && (
            <div className={styles.subTitle}>
              <Text
                idT="walletPopUp.availableForWithdraw"
                specValue={`${withdrawAmount} ${currencyName}`}
              />
            </div>
          )}
        </div>

        {isAvailableListInfo && (
          <List
            data={listData}
            setActiveMethod={handleMethodEvents(
              isDeposit ? 'deposit' : 'withdraw'
            )}
          />
        )}

        {!isDeposit && isNeedToKyc && !isAvailableBonuses && (
          <div className={styles.kycBlock}>
            <div className={cln(styles.title, styles.kycTitle)}>
              <Text idT="walletPopUp.needToKyc" />
            </div>
            <KycButton
              text=" "
              color="primary"
              size="--button-full"
              buttonHeight="--button-height-full"
              handleClick={console.info}
              classes={styles.kycBtn}
            />
          </div>
        )}

        {!isDeposit && isAvailableBonuses && (
          <div className={styles.maxHeight}>
            <div className={cln(styles.title, styles.kycTitle)}>
              <Text idT="walletPopUp.youShouldCancelActiveBonuses" />
            </div>

            <ComponentKitBaseButton
              text="walletPopUp.openBonusCabinet"
              color="primary"
              size="--button-full"
              buttonHeight="--button-height-l"
              handleClick={handleJumpTo}
              centreText
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Methods;
