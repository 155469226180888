import cln from 'classnames';
import { useParams } from 'react-router';

import CasinoCategories from 'components/CasinoComponents/CasinoCategories';
import CasinoSearchInput from 'components/CasinoComponents/CasinoSearchInput';
import CasinoProviderSelector from 'components/CasinoComponents/CasinoProviderSelector';
import CasinoGridGames from 'components/CasinoComponents/CasinoGridGames';

import { useZustandCasinoGamesStore } from 'stores/casino/games/store';
import { getActiveProviderIdSelector } from 'stores/casino/games/selectors';

import styles from './CasinoCategoryGames.module.css';
import animation from 'styles/animation.module.css';

const CasinoCategoryGames = () => {
  const { categoryId = 0, subCategoryId = 0 } = useParams();

  const activeProviderId = useZustandCasinoGamesStore(
    getActiveProviderIdSelector
  );

  return (
    <div
      className={cln(
        styles.wrapperCasinoCategoryGames,
        animation.animationFadeIn
      )}
    >
      <CasinoCategories type="buttons" />

      <div className={styles.wrapperFilterItems}>
        <CasinoSearchInput classes={styles.widthAndHeight} />

        <CasinoProviderSelector
          classes={styles.widthAndHeight}
          categoryId={+categoryId}
          subCategoryId={+subCategoryId}
        />
      </div>

      <div className={styles.gridGamesWrapper}>
        <CasinoGridGames providerId={activeProviderId} />
      </div>
    </div>
  );
};

export default CasinoCategoryGames;
