export interface ZustandNotificationStore extends ZustandNotificationEntities {
  addNotification: (data: Notification) => void;

  removeNotification: (key: string) => void;
}

interface ZustandNotificationEntities {
  notifications: Notifications;
}

export type Notifications = {
  [notificationId: string]: Notification;
};

export type Notification = {
  text: string;
  type: ENotificationType;
  uid: string;
};

export enum ENotificationType {
  Default,
  Warning,
  Success,
  Error,
}
