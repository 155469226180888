import { useRef } from 'react';
import cln from 'classnames';
import { useUserAvatar } from 'decryption_protocol_hooks';
import {
  AvatarCollectionType,
  AvatarType,
} from 'decryption_protocol/dist/lib/store/user/entities/avatar/types';

import { Text, useInView } from 'decryption_component-kit';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './AvatarCollection.module.css';

type Props = AvatarCollectionType & {
  handleChoose: (id: AvatarType) => () => void;
  selectedAvatarId: number;
};

const AvatarCollection = (props: Props) => {
  const { name, avatars, handleChoose, selectedAvatarId } = props;

  const wrapperContainer = useRef<HTMLDivElement | null>(null);

  const avatar = useHookFabric({ fn: useUserAvatar }) as { id: number } | null;

  const isInView = useInView(wrapperContainer, {
    rootMargin: '250px',
  });

  return (
    <div className={styles.wrapperAvatarCollection} ref={wrapperContainer}>
      <div className={styles.title}>
        <span>
          <Text idT="kgames.avatars.collectionName" />
        </span>{' '}
        {name}
      </div>

      <div className={styles.grid}>
        {isInView &&
          avatars.map((a) => (
            <div
              key={a.id}
              className={cln(styles.avatarBox, {
                [styles.active]: selectedAvatarId === a.id,
                [styles.currentAvatar]: avatar?.id === a.id,
              })}
              onClick={handleChoose(a)}
            >
              {a.isBlocked && (
                <div className={styles.lock}>
                  <img
                    src="/icons-elon/k-games/lock.webp"
                    className={styles.icon}
                  />
                </div>
              )}
              <img
                src={`/icons-elon/k-games/avatars/${a.id}.webp`}
                className={styles.img}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default AvatarCollection;
