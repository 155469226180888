import { useEffect, useState } from 'react';

import AmountInput from 'components/ui/Input/AmountInput';
import TurkishBankInput from 'components/ui/Input/TurkishBankInput';
import InputUserInfo from 'components/ui/Input/InputUserInfo';

import { setFormsInfoAction } from 'stores/money/forms/selectors';
import { useZustandFormsStore } from 'stores/money/forms/store';
import { setPaymentsCurrentStepAction } from 'stores/money/payments/selectors';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';
import { DefaultInputType } from 'components/ui/Input/Base/types';
import { DEFAULT_INPUT_VALUE } from 'components/ui/Input/Base/constant';
import { Steps } from 'stores/money/payments/types';
import { PaymentChannelType } from 'decryption_protocol/dist/lib/store/user/types';
import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';

import { isFilledForm } from 'utils/validation';
import { TURKISH_ANINDA_BANK_IDS } from './constants';

import withdrawStyles from '../../Withdraw.module.css';

type Props = {
  min: number;
  max: number;
};

const TurkishAnindaHavale = ({ min, max }: Props) => {
  const setFormInfo = useZustandFormsStore(setFormsInfoAction);

  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  const currencyId = useZustandUserStore(getUserCurrencyIdSelector);

  const [name, setName] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [familyName, setFamilyName] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [bankInfo, setBankInfo] = useState<{
    iban: string;
    bankId: number;
  }>({ iban: '', bankId: 0 });

  const [amount, setAmount] = useState<string>('');

  useEffect(() => {
    const isFilled = isFilledForm([
      amount,
      name,
      familyName,
      { ...bankInfo, swiftCode: '1' },
    ]);

    if (isFilled && currencyId) {
      setFormInfo({
        isDeposit: false,
        form: {
          currencyId,
          amount,
          firstName: name.value,
          familyName: familyName.value,

          ...bankInfo,

          channelId: PaymentChannelType.AnindaHavale,
          requestId: ERequestIds.WithdrawAninda,
        },
      });

      setStep(Steps.FilledForm);
    } else {
      setStep(Steps.TypingData);
    }
  }, [currencyId, name, familyName, amount, bankInfo]);

  return (
    <div className={withdrawStyles.marginInputBottom}>
      <AmountInput
        placeholder="walletPopUp.forms.amount"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        isWithdraw
        size="full"
        onChange={setAmount}
        isPresets={false}
        min={min}
        max={max}
        classes={withdrawStyles.amountInputMargin}
        wrapperClasses={withdrawStyles.marginInputBottom}
      />
      <InputUserInfo
        isIgnoreUserInfo
        userInfoKey="name"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.name"
        size="full"
        classes={withdrawStyles.marginInputBottom}
        onChange={setName}
        field={name}
      />

      <InputUserInfo
        isIgnoreUserInfo
        userInfoKey="surname"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.sureName"
        size="full"
        field={familyName}
        onChange={setFamilyName}
        classes={withdrawStyles.marginInputBottom}
      />

      <TurkishBankInput
        onChange={setBankInfo}
        customBankIds={TURKISH_ANINDA_BANK_IDS}
        classesWrapper={withdrawStyles.marginInputBottom}
        classesSelector={withdrawStyles.marginInputBottom}
      />
    </div>
  );
};

export default TurkishAnindaHavale;
