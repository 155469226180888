export enum EGettingInfoStatus {
  Idle = 'idle',
  Pending = 'pending',
  Success = 'success',
  Error = 'error',
}

export enum DeviceType {
  Desktop,
  Mobile,
  Tablet,
}
