import { MainLoader } from 'decryption_component-kit';

import { useZustandMainLoaderStore } from 'stores/mainLoader/store';
import { getMainLoaderPercentSelector } from 'stores/mainLoader/selectors';

const MainLoaderComponentKit = () => {
  const percent = useZustandMainLoaderStore(getMainLoaderPercentSelector);

  return (
    <MainLoader
      percent={percent}
      appType={+(process.env.REACT_APP_TYPE || 0)}
    />
  );
};

export default MainLoaderComponentKit;
