import { ESocketCallbackStep } from 'decryption_protocol/dist/lib/socket/types';
import { ZustandConnectionStore } from './types';

export const getConnectionStatusSelector = (state: ZustandConnectionStore) =>
  state.status;

export const isAvailableTransportSelector = (state: ZustandConnectionStore) =>
  getConnectionStatusSelector(state) === ESocketCallbackStep.Connected;

// ============================== ACTIONS ==============================

export const setConnectionStatusAction = (state: ZustandConnectionStore) =>
  state.setStatus;
