export interface ZustandModalStore extends ZustandModalEntities {
  setModalInfo: (type: ModalTypes, additionalInfo?: any) => void;

  closeAndOpen: (type: ModalTypes, additionalInfo?: any) => void;
}

interface ZustandModalEntities {
  modalType: ModalTypes;
  additionalInfo: any;
  oldType: ModalTypes | null;
}
export enum ModalTypes {
  Unknown,
  CloseModal,

  // betting
  InGameTickets,
  // end betting

  SignUp,
  SignIn,
  Credentials,
  LostPassword,
  SetNewPassword,

  MoneyOperations,
  Languages,

  DrawerCasinoModal,
  DrawerBettingModal,

  CasinoTournament,
  CasinoTournamentPlayers,
  TournamentRules,

  CasinoProviders,

  CasinoFavorite,
  BonusPack,
  Bonuses,

  BettingTransaction,
  TransactionDetails,
  TransactionFilters,

  ShortStories,
  // Filters
  Calendar,

  // Referrals
  ReferralCopyContent,
  ReferralWithdraw,
  ReferralFailure,
  ReferralSuccess,

  Wheel,
  JackpotInfo,
  JackpotsRules,
  LootBoxGame,
  Hamster,
  Avatars,
  KGamesDaily,
  PromoCode,
  PromoCodeActivate,
  TeslaPrize,
  MoneyComingPrize,
  CancelBonus,
  // Confirm bonus cancel or claim
  ClaimConfirmation,

  // EXPERIMANTALS
  InstaShorts,

  // Landing
  AviatrixBonusOnDep,
  AviatrixSpins,
}
