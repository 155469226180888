import React, { useState } from 'react';

type Props = {
  children: React.ReactElement;
  src: string;
  classes?: string;
};

const HOCImageWithDefault = (props: Props) => {
  const { classes, src, children } = props;

  const [imgError, setImageState] = useState<boolean>(false);

  const handleCatchError = () => {
    setImageState(true);
  };

  if (imgError) {
    return children;
  }

  return <img src={src} className={classes} onError={handleCatchError} />;
};

export default HOCImageWithDefault;
