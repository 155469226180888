import { useState } from 'react';
import { ModalLayout } from 'decryption_component-kit';

import ComponentKitTabs from 'components/ui/KitComponents/Tab';
import LostPasswordEmailForm from './components/LostPasswordEmailForm';
import LostPasswordPhoneForm from './components/LostPasswordPhoneForm';

import { RECOVERY_TABS } from './constants';
import { RecoveryFormType } from './types';

import styles from './LostPassword.module.css';
import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { ModalTypes } from 'stores/modal/types';

const LostPassword = () => {
  const closeModal = useZustandModalStore(closeAndOpenModalAction);

  const [activeValue, setActiveValue] = useState(RecoveryFormType.Email);

  const handleCloseModal = () => {
    closeModal(ModalTypes.Unknown);
  };

  return (
    <ModalLayout
      popUpHeaderProps={{
        transparent: true,
      }}
      buttonClose={handleCloseModal}
    >
      <>
        <ComponentKitTabs
          activeValue={activeValue}
          data={RECOVERY_TABS}
          onChange={setActiveValue}
          classes={styles.tabWrapper}
        />

        {activeValue === RecoveryFormType.Email && <LostPasswordEmailForm />}

        {activeValue === RecoveryFormType.Phone && <LostPasswordPhoneForm />}
      </>
    </ModalLayout>
  );
};

export default LostPassword;
