import { EAuthFormType } from 'stores/forms/signIn/types';

export const AUTH_TABS = [
  {
    label: 'authorization.tabs.phone',
    value: EAuthFormType.Phone,
  },
  {
    label: 'authorization.tabs.email',
    value: EAuthFormType.Email,
  },
  {
    label: 'authorization.tabs.id',
    value: EAuthFormType.UserName,
  },
];
