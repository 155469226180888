import { useNavigate } from 'react-router';

import { ERouteNames } from 'Routes/types';

import { getCasinoProviderIcon } from 'utils/icons';

import styles from './ProviderBox.module.css';

type Props = {
  name: string;
  id: number;
};

const ProviderBox = ({ name, id }: Props) => {
  const navigate = useNavigate();

  const jumpTo = () => {
    navigate(ERouteNames.CasinoProviderGames.replace(':id', `${id}`));
  };

  return (
    <div className={styles.providerItem} onClick={jumpTo}>
      {getCasinoProviderIcon(name, styles.icon)}
    </div>
  );
};

export default ProviderBox;
