import React from 'react';

import styles from './RenderTitles.module.css';
import { Text } from 'decryption_component-kit';

type Props = {
  subTitle: string;
};

const ReferralSubTitle = ({ subTitle }: Props) => (
  <div className={styles.subTitle}>
    <Text idT={subTitle} />
  </div>
);

export default ReferralSubTitle;
