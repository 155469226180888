export function setCookie(name: string, value: string, days: number) {
  try {
    let expires = '';

    const date = new Date();

    const convertDays = days * 24 * 60 * 60 * 1000;

    date.setTime(date.getTime() + convertDays);

    expires = 'expires=' + date.toUTCString();

    if (process.env.NODE_ENV !== 'development') {
      document.cookie = `${name}=${value};${expires};domain=.${window.location.hostname};path=/`;

      return;
    }

    document.cookie = `${name}=${value};${expires};path=/`;
  } catch (error) {
    console.error('SET COOKIE :', error);

    return null;
  }
}

export function getCookie(name: string) {
  try {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];

      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }

      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }

    return null;
  } catch (error) {
    console.error('🚀GET COOKIE :', error);

    return null;
  }
}

export function eraseCookie(name: string) {
  try {
    const hostNames = window.location.hostname;

    const url =
      process.env.NODE_ENV !== 'development' ? hostNames : 'localhost';

    document.cookie =
      name +
      `=''; Path=/; domain=.${url}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  } catch (error) {
    console.error('eraseCookie COOKIE :', error);

    return null;
  }
}
