import { ZustandBalanceStore } from './types';

export const getActiveBalanceTypeSelector = (state: ZustandBalanceStore) =>
  state.activeBalanceType;

export const isShowMoneyPopUpSelector = (state: ZustandBalanceStore) =>
  state.showMoneyPopUpState;

export const getBalanceInfoSelector = (state: ZustandBalanceStore) =>
  state.balanceInfo;

export const getCommissionsSelector = (state: ZustandBalanceStore) =>
  getBalanceInfoSelector(state)?.commission;

export const getLoyaltyWagerSelector = (state: ZustandBalanceStore) =>
  getBalanceInfoSelector(state)?.loyaltyWager;

export const getMainBalanceValueSelector = (state: ZustandBalanceStore) =>
  getBalanceInfoSelector(state)?.mainBalance;
export const getBonusBalanceValueSelector = (state: ZustandBalanceStore) =>
  getBalanceInfoSelector(state)?.bonusBalance;

export const getRolledWithTaxSelector = (state: ZustandBalanceStore) =>
  getBalanceInfoSelector(state)?.rolledWithTax;

export const getActiveBalanceInfoSelector = (state: ZustandBalanceStore) => {
  const key = getActiveBalanceTypeSelector(state);

  if (key === 'customBonusCoin') {
    return {
      balance: -1,
      type: key,
    };
  }
  // TODO: enum for balance   (keyof type to user )
  const balance = getBalanceInfoSelector(state);

  const balanceValue = balance?.[key] || -1;

  return {
    balance: !balance ? -1 : balanceValue,
    type: key,
  };
};

// =========================== Action ===========================
export const setBalanceInfoAction = (state: ZustandBalanceStore) =>
  state.setBalanceInfo;

export const setShowMoneyPopUpAction = (state: ZustandBalanceStore) =>
  state.setShowPopUpState;

export const setBalanceTypeAction = (state: ZustandBalanceStore) =>
  state.setActiveBalanceType;
