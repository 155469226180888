import { ETicketStatus } from 'decryption_protocol/dist/lib/store/betting/entities/tickets/types';
import React from 'react';

type Props = {
  status: ETicketStatus;

  isWin: boolean;
};

const MatchStatus = ({ status, isWin }: Props) => (
  <div>
    {status === ETicketStatus.Settled ? (
      <img src={`/icons-elon/betting/${!isWin ? 'lose' : 'win'}.svg`} />
    ) : (
      <img src="/icons-elon/betting/time.svg" />
    )}
  </div>
);

export default MatchStatus;
