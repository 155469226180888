import styles from './TicketEventInfo.module.css';

type Props = {
  eventHomeName: string;
  outcomeName: string;
  eventAwayName: string;
  marketName: string;
};

const TicketEventInfo = (props: Props) => {
  const { eventHomeName, eventAwayName, marketName, outcomeName } = props;

  return (
    <div className={styles.leftWrapper}>
      <div className={styles.teamBetName}>{outcomeName}</div>
      <div className={styles.teamMarketName}>{marketName}</div>
      <div className={styles.teams}>
        {eventHomeName}-{eventAwayName}
      </div>
    </div>
  );
};

export default TicketEventInfo;
