import { useNavigate } from 'react-router';
import { useGetCasinoGameById } from 'decryption_protocol_hooks';
import { AdaptedGame } from 'decryption_protocol/dist/lib/store/casino/entities/games/types';

import HOCImageWithDefault from 'components/HOCs/HOCImageWithDefault';
import DefaultGameImg from 'components/ui/DefaultGameImg';
import { ArrowSvg } from 'decryption_component-kit';

import { isMobileAppSelector } from 'stores/marketing/selectors';
import { useZustandMarketingTabsStore } from 'stores/marketing/store';

import { ERouteNames } from 'Routes/types';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './VideoBanner.module.css';

type Props = {
  gameId: number;
  source: string;
};

const VideoBanner = ({ gameId, source }: Props) => {
  const game = useHookFabric({
    fn: useGetCasinoGameById,
    customProps: {
      gameId,
    },
  }) as AdaptedGame | null;

  const navigate = useNavigate();

  const jumpToGame = () => {
    if (!game) {
      return;
    }
    navigate(ERouteNames.CasinoGame.replace(':gameId', `${game.id}`));
  };

  const isMobileApp = useZustandMarketingTabsStore(isMobileAppSelector);

  if (!game || isMobileApp) {
    return null;
  }

  return (
    <div className={styles.wrapperCrazyVideoBanner} onClick={jumpToGame}>
      <div className={styles.gameBg} />

      <video
        playsInline
        autoPlay
        muted
        loop
        className={styles.video}
        controlsList=" nofullscreen noremoteplayback"
      >
        <source src={`/icons-elon/video/${source}`} type="video/mp4" />
      </video>

      <div className={styles.gameWrapper}>
        <div className={styles.leftSide}>
          <HOCImageWithDefault src={game.imageUrl} classes={styles.gameImg}>
            <DefaultGameImg classes={styles.gameImg} gameName={game.name} />
          </HOCImageWithDefault>

          <div className={styles.gameInfo}>
            <div className={styles.gameName}>{game.name}</div>
            <div className={styles.providerName}>{game.providerName}</div>
          </div>
        </div>

        <ArrowSvg classes={styles.arrow} />
      </div>
    </div>
  );
};

export default VideoBanner;
