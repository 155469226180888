/* eslint-disable no-console */
// without-redirect
// IS_VERTICAL
// IS_WEBP
// developmentMode
// monthly_bonus_activation -- v dagdagdafsdfsfdsfs
// is_valid_mobile

import {
  CUSTOM_SOCKET_NAME,
  DEVELOPMENT_MODE_NAME,
  MANUAL_BET,
} from 'constants/localstoreKeys';
import { removeFromLocalStorage, setToLocalStorage } from 'utils/localstorage';

export const initializeWindowTools = () => {
  // @ts-ignore
  window.setDevelopmentMode = (state) => {
    if (!state) {
      removeFromLocalStorage(DEVELOPMENT_MODE_NAME);

      return;
    }
    setToLocalStorage(DEVELOPMENT_MODE_NAME, `${state}`);
  };
  // @ts-ignore
  window.setSocket = (socket: string) => {
    if (!socket) {
      removeFromLocalStorage(CUSTOM_SOCKET_NAME);

      return;
    }
    setToLocalStorage(CUSTOM_SOCKET_NAME, socket);
  };
  // @ts-ignore
  window.turnOnManualBet = (sum: number) => {
    if (!sum) {
      removeFromLocalStorage(MANUAL_BET);

      return;
    }

    setToLocalStorage(MANUAL_BET, `${sum}`);
  };
  // @ts-ignore
  window.reset = () => {
    removeFromLocalStorage(DEVELOPMENT_MODE_NAME);
    removeFromLocalStorage(CUSTOM_SOCKET_NAME);
    removeFromLocalStorage(MANUAL_BET);
  };
  // @ts-ignore
  window.showTools = () => {
    console.group('TOOLS');
    console.info(
      'IS_VERTICAL CASINO IMG:Console command -> setVerticalImgState(true) <-  ( true or false )'
    );
    console.info(
      'SET TO LOCAL STORAGE " custom_currency_id_fast_reg=[id]  "   and fat registration will ignore currencyId'
    );
    console.info(
      'developmentMode: -> setDevelopmentMode() <- ( true or false )'
    );
    console.info('manual bet: -> turnOnManualBet(0) <- ( sum:number )');
    console.info(
      '?monthly_bonus_activation=dagdagdafsdfsfdsfs   <= params for monthly bonus page'
    );

    console.group('SOCEKTS');
    console.info('wss://wss.22kk.club/21612/casino');
    console.info('wss://wss.22kk.club/21620/casino');
    console.info('wss://wss.azufront.com/casino');
    console.info('setSocket: -> setSocket(address) <- ');

    console.groupEnd();

    console.info('reset settings: -> reset() <- ');

    console.groupEnd();
  };
  console.info();
};
