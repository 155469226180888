import { create } from 'zustand';
import { ZustandCasinoSearchStore } from './types';

export const useZustandCasinoSearch = create<ZustandCasinoSearchStore>(
  (set) => ({
    search: '',

    setSearch: (search) => {
      set({ search });
    },
  })
);
