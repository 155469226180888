import { doc, getDoc, setDoc } from '@firebase/firestore';
import { firebaseData } from 'services/firebase';

const USERS_PUSH_COLLECTION = 'push-users';

export const getUsersWithDoc = async (token: any) => {
  console.info(token, 'token');

  return [];
};
// await collection(USERS_PUSH_COLLECTION).getDocs(token);

export const isUserAlreadyInDoc = async (token: any) => {
  try {
    if (!firebaseData) {
      return;
    }
    const { db } = firebaseData;

    if (!db) {
      return;
    }
    const docRef = doc(db, USERS_PUSH_COLLECTION, token);

    const docSnap = await getDoc(docRef);

    return { exists: docSnap.exists(), _data: docSnap.data() };
  } catch (error) {
    return null;
  }
};

export const setDataToPushUsers = async (
  id: string,
  dataToSet: any,
  shouldMerge = true
) => {
  try {
    if (!firebaseData) {
      return;
    }
    const { db } = firebaseData;

    if (!db) {
      return;
    }
    const docRef = doc(db, USERS_PUSH_COLLECTION, id);

    await setDoc(docRef, dataToSet, { merge: shouldMerge });
  } catch (err) {
    console.log(err);
  }
};

export const addUsersToCollection = async (id: string, dataToSet: any) => {
  try {
    if (!firebaseData) {
      return;
    }
    const { db } = firebaseData;

    if (!db) {
      return;
    }

    const docRef = doc(db, USERS_PUSH_COLLECTION, id);

    await setDoc(docRef, dataToSet);
  } catch (err) {
    console.log(err);
  }
};
