export type BaseProps = {
  operationId: number;
  amount: number;
  wager: number;
  isPack?: boolean;
  classes?: string;
  isActive?: boolean;
  maxBet: number;
  maxBonus: number;
  minBet: number;
  // rollingTime?: number;
  fullRolled?: number;
  rolled?: number;
  percent?: number;

  spins: number;
  gameId: number;
  validFrom?: number;
  validUntil?: number;
  rollingTime?: number;
  endActivationTime?: number;
};

export type Props =
  | (BaseProps & {
      isActive: true;
      fullRolled: number;
      rolled: number;

      validFrom: number;
      validUntil: number;
      rollingTime: number;
    })
  | (BaseProps & {
      isActive?: false | undefined;
      endActivationTime: number;
    });

export type InfoRow = {
  text: string;
  key: keyof BaseProps;
};
export enum EFreeSpinBonusStatusView {
  Unknown,
  Available,
  ActiveFromTime,
  ActiveForSpins,
  Rolling,
}
