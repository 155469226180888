import BaseButton from 'components/ui/KitComponents/Buttons/BaseButton';

import {
  useZustandLanguagesKitStore,
  languageSelectors,
  useTranslate,
} from 'decryption_component-kit';

import { useZustandModalStore } from 'stores/modal/store';
import { setModalInfoAction } from 'stores/modal/selectors';

import { ModalTypes } from 'stores/modal/types';

import styles from './TeslaModal.module.css';

const TeslaModal = () => {
  const lang = useZustandLanguagesKitStore(
    languageSelectors.getActiveLanguageNameKitSelector
  );

  const { getText } = useTranslate('', '');

  const setModal = useZustandModalStore(setModalInfoAction);

  const prizeTextPath =
    '/icons-elon/landings/tesla/prizeText/[LANG].png'.replace(
      '[LANG]',
      lang || ''
    );

  const handleShowRegistration = () => {
    setModal(ModalTypes.SignUp, {
      notClosed: true,
      bonusSignUp: true,
    });
  };

  return (
    <div
      className={styles.wrapperTeslaModal}
      style={{
        background: 'url(/icons-elon/landings/tesla/win.png)',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <img src={prizeTextPath} className={styles.prizeText} alt="" />

      <BaseButton
        handleClick={handleShowRegistration}
        text={getText('tesla.claimBonus')}
        color="transparent"
        size="--button-full"
        centreText
        buttonHeight="--button-height-full"
        classes={styles.claimButton}
      />
    </div>
  );
};

export default TeslaModal;
