import cln from 'classnames';
import { useMemo, useState } from 'react';
import { ModalLayout, Text, useTranslate } from 'decryption_component-kit';
import { useTransportControllers } from 'decryption_protocol_hooks';
import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';

import EmailSignInForm from './components/EmailSignInForm';
import ComponentKitTabs from 'components/ui/KitComponents/Tab';
import PhoneSignInForm from './components/PhoneSignInForm';
import IdSignInForm from './components/IdSignInForm';
import HOCRequestLoader from 'components/HOCs/HOCRequestLoader';
import CircularLoader from 'components/ui/Loaders/Circular';
import PasswordInput from 'components/ui/Input/PasswordInput';
import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';

import { AUTH_TABS } from './constants';

import { useZustandSignInStore } from 'stores/forms/signIn/store';
import {
  getActiveSignInFormSelector,
  setActiveSignInFormAction,
} from 'stores/forms/signIn/selectors';
import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { useZustandModalStore } from 'stores/modal/store';

import { ModalTypes } from 'stores/modal/types';
import { EAuthFormType } from 'stores/forms/signIn/types';

import { getAuthorizationData } from 'utils/transport/main';
import { useHookFabric } from 'hooks/useHookFabric';

import styles from './SignIn.module.css';

type Props = {
  isModal?: boolean;
};

const SignIn = (props: Props) => {
  const { isModal = false } = props;

  const activeForm = useZustandSignInStore(getActiveSignInFormSelector);

  const setAuthFormType = useZustandSignInStore(setActiveSignInFormAction);

  const setModal = useZustandModalStore(closeAndOpenModalAction);

  const [password, setPassword] = useState('');

  const [email, setEmail] = useState('');

  const [id, setId] = useState('');

  const [phone, setPhone] = useState('');

  const { sendCommand } = useHookFabric({ fn: useTransportControllers });

  const { getText } = useTranslate('', '');

  const onCloseModal = () => {
    setModal(ModalTypes.Unknown);
  };

  const handleSignIn = () => {
    let logInInfo = activeForm === EAuthFormType.Phone ? phone : email;

    if (activeForm === EAuthFormType.UserName) {
      logInInfo = id;
    }

    const data = getAuthorizationData(
      logInInfo,
      password,
      ERequestIds.AuthorizeByEmail
    );

    sendCommand('x02', data, true);
  };

  const setModalType = (t: ModalTypes) => () => {
    setModal(t);
  };

  const activeInput = useMemo(() => {
    if (activeForm === EAuthFormType.Phone) {
      return phone;
    }

    if (activeForm === EAuthFormType.Email) {
      return email;
    }

    return id;
  }, [activeForm, phone, email, id]);

  return (
    <ModalLayout
      classes={styles.wrapperReferralCopyContent}
      buttonClose={isModal ? onCloseModal : undefined}
      popUpHeaderProps={{
        title: getText('header.login'),
        transparent: true,
      }}
    >
      <div className={styles.content}>
        <ComponentKitTabs
          activeValue={activeForm}
          onChange={setAuthFormType}
          data={AUTH_TABS}
          classes={styles.wrapperTabs}
          type="underline"
        />

        <div
          className={cln(styles.wrapperForms, {
            [styles.formOne]: activeForm === EAuthFormType.Phone,
            [styles.formTwo]: activeForm === EAuthFormType.Email,
            [styles.formThree]: activeForm === EAuthFormType.UserName,
          })}
        >
          <PhoneSignInForm
            classes={cln(styles.wrapperForm, styles.marginInput)}
            onChange={setPhone}
          />

          <EmailSignInForm
            classes={cln(styles.wrapperForm, styles.marginInput)}
            onChange={setEmail}
          />

          <IdSignInForm
            classes={cln(styles.wrapperForm, styles.marginInput)}
            onChange={setId}
          />
        </div>

        <PasswordInput
          placeholder="authorization.inputs.password"
          size="full"
          isHidePlaceholder
          onChange={setPassword}
          classes={cln(styles.passwordField, styles.marginInput)}
        />

        <div
          className={cln(styles.lostPsw, styles.marginInput)}
          onClick={setModalType(ModalTypes.LostPassword)}
        >
          <div>
            <Text idT="authorization.lostPasswordBtn" />
          </div>
        </div>

        <HOCRequestLoader
          command="\\x02"
          requestId={ERequestIds.AuthorizeByEmail}
          customLoader={<CircularLoader />}
        >
          <ComponentKitBaseButton
            text="authorization.submitBtn"
            size="--button-full"
            buttonHeight="--button-height-full"
            color="primary"
            disabled={Boolean(!activeInput || !password)}
            handleClick={handleSignIn}
            classes={styles.btn}
            centreText
          />
        </HOCRequestLoader>

        <div className={styles.dash} />

        <ComponentKitBaseButton
          text={getText('header.join')}
          size="--button-full"
          buttonHeight="--button-height-full"
          handleClick={setModalType(ModalTypes.SignUp)}
          classes={cln(styles.btn, styles.reg)}
          color="dark"
          centreText
        />
      </div>
    </ModalLayout>
  );
};

export default SignIn;
