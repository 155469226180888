import {
  EOperationStatus,
  MixerTransactionAdditionalInfo,
} from 'decryption_protocol/dist/lib/store/operations/types';

import BonusCardComponentKit from 'components/ui/KitComponents/BonusCardComponentKit';

import { getBonusCardsSelector } from 'stores/configuration/selectors';
import { useZustandWebConfiguration } from 'stores/configuration/store';

type Props = {
  info: MixerTransactionAdditionalInfo;
  id: number;
};

const SupportCard = ({ info, id }: Props) => {
  const { status, resultValue, endTimestamp } = info;

  const bonusImagePaths = useZustandWebConfiguration(getBonusCardsSelector);

  if (status !== EOperationStatus.Created) {
    return null;
  }

  return (
    <BonusCardComponentKit
      title="newBonuses.cards.egt"
      description="newBonuses.cards.egtDescription"
      id={id}
      value={`${resultValue}`}
      endTimestamp={endTimestamp}
      bonusImagePath={bonusImagePaths?.support || ''}
    />
  );
};

export default SupportCard;
