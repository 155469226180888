import React, { useRef, useState } from 'react';
import { useCountDown, Text } from 'decryption_component-kit';

import ComponentKitBaseButton from '../KitComponents/Buttons/BaseButton';

import { DEFAULT_TIMER_VALUE } from './constants';

import styles from './Timer.module.css';

type Props = {
  handleRequestCode: () => void;
};

const Timer = ({ handleRequestCode }: Props) => {
  const [isShowButton, setButtonState] = useState(false);

  const timerValue = useRef(new Date().getTime() + DEFAULT_TIMER_VALUE);

  const callback = () => {
    setButtonState(true);
  };

  const countDown = useCountDown({
    endTimestamp: timerValue.current,
    callback,
  });

  const handleResend = () => {
    timerValue.current = new Date().getTime() + DEFAULT_TIMER_VALUE;
    handleRequestCode();
    setButtonState(false);
  };

  return (
    <div className={styles.wrapperTimer}>
      {!isShowButton ? (
        <>
          <div>
            <Text idT="getAgainAfterTime" />
          </div>
          <div className={styles.time}>
            {countDown.formatTime[2]}:{countDown.formatTime[3]}
          </div>
        </>
      ) : (
        // <div onClick={handleResend}>
        //   <Text idT="getCodeBtn" />
        // </div>

        <ComponentKitBaseButton
          color="primary"
          handleClick={handleResend}
          size="--button-full"
          buttonHeight="--button-height-l"
          text="getCodeBtn"
          centreText
        />
      )}
    </div>
  );
};

export default Timer;
