import React from 'react';

import styles from './Checkbox.module.css';

type Props = {
  checked: boolean;
  toggleState: () => void;
};

const Checkbox = (props: Props) => {
  const { checked, toggleState } = props;

  return (
    <input
      className={styles.checkbox}
      checked={checked}
      type="checkbox"
      onChange={toggleState}
    />
  );
};

export default Checkbox;
