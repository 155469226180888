import { TabTypes } from './types';

export const BETTING_EVENT_TYPES = [
  {
    label: 'T.Prematch',
    value: TabTypes.Prematch,
  },
  {
    label: 'T.Live',
    value: TabTypes.Live,
  },
];
