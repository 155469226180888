import { ZustandModalStore } from './types';

export const getModalTypeSelector = (state: ZustandModalStore) =>
  state.modalType;

export const getModalAdditionalInfoSelector = (state: ZustandModalStore) =>
  state.additionalInfo;

export const getNextModalTypeSelector = (state: ZustandModalStore) =>
  state.oldType;

// ================================================================= ACTIONS =================================================================
export const setModalInfoAction = (state: ZustandModalStore) =>
  state.setModalInfo;

export const closeAndOpenModalAction = (state: ZustandModalStore) =>
  state.closeAndOpen;
