import SkeletLoader from 'components/ui/Loaders/SkeletLoader';

import styles from './Loader.module.css';

type Props = {
  cashOutInfo: number | null;
};

const Loader = ({ cashOutInfo }: Props) => (
  <div className={styles.wrapperLoader}>
    <SkeletLoader />

    <div className={styles.cashOutInfo}>
      T.Cash Out {cashOutInfo || '?'} loading..
    </div>
  </div>
);

export default Loader;
