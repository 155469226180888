import { ModalLayout, useTranslate } from 'decryption_component-kit';

import GeneralBonuses from './components/GeneralBonuses';
import VipBonuses from './components/VipBonuses';

import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { ModalTypes } from 'stores/modal/types';

import styles from './Bonuses.module.css';

const Bonuses = () => {
  const setModal = useZustandModalStore(closeAndOpenModalAction);

  const { name: title } = useTranslate('vipClub.tabs.Bonuses', '');

  const onClose = () => {
    setModal(ModalTypes.Unknown);
  };

  return (
    <ModalLayout
      popUpHeaderProps={{
        title,
        transparent: true,
      }}
      buttonClose={onClose}
    >
      <>
        <div className={styles.content}>
          <VipBonuses />

          <GeneralBonuses />
        </div>
      </>
    </ModalLayout>
  );
};

export default Bonuses;
