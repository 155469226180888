import { useEffect, useRef } from 'react';
import cln from 'classnames';
import { useLocation } from 'react-router';
import { useFavoriteGames } from 'decryption_protocol_hooks';
import { FavoriteGame } from 'decryption_protocol/dist/lib/store/user/entities/favorites/types';
import { ModalLayout, useTranslate } from 'decryption_component-kit';

import NotFoundData from 'components/ui/DataNotFound';
import CasinoGame from 'components/CasinoComponents/CasinoGame';

import { IS_VERTICAL } from 'constants/ui';

import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';
import { isAuthorizedUserSelector } from 'stores/user/personal/selectors';

import { ModalTypes } from 'stores/modal/types';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './CasinoFavorites.module.css';

const CasinoFavorites = () => {
  const games = useHookFabric({
    fn: useFavoriteGames,
  }) as FavoriteGame[];

  const setModal = useZustandModalStore(closeAndOpenModalAction);

  const isAuth = useZustandUserStore(isAuthorizedUserSelector);

  const { name: title } = useTranslate('favorites', '');

  const { pathname } = useLocation();

  const prevLocation = useRef<string>(pathname);

  const handleClose = () => {
    setModal(ModalTypes.Unknown);
  };

  useEffect(() => {
    if (pathname !== prevLocation.current) {
      handleClose();
    }
  }, [pathname]);

  return (
    <ModalLayout
      popUpHeaderProps={{
        title,
        transparent: true,
      }}
      buttonClose={handleClose}
    >
      <div className={styles.content}>
        <div
          className={cln(styles.games, {
            [styles.vertical]: IS_VERTICAL,
          })}
        >
          {games.map((g) => (
            <CasinoGame key={g.id} {...g} />
          ))}
        </div>

        {(!isAuth || !games.length) && <NotFoundData />}
      </div>
    </ModalLayout>
  );
};

export default CasinoFavorites;
