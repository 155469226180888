import {
  PhoneInputPropsWithConfirmation,
  PhoneInputPropsWithoutConfirmation,
} from 'components/ui/Input/Phone';
import {
  ERequestIds,
  RecoverySubType,
} from 'decryption_protocol/dist/lib/binary/types';

export const PHONE_PROPS_WITH_CONFIRMATION: PhoneInputPropsWithConfirmation = {
  requestId: ERequestIds.GetPhoneCode,
  sendDataType: RecoverySubType.Phone,
  sendKey: '09x',
  section: 1,
  withConfirm: true,
};

export const PHONE_PROPS_WITHOUT_CONFIRMATION: PhoneInputPropsWithoutConfirmation =
  {
    sendDataType: RecoverySubType.Phone,
    withConfirm: false,
  };
