import BetSlipCount from '../BetSlipCount';
import BetSlipShortInfo from '../BetSlipShortInfo';
import BetSlipTabs from '../BetSlipTabs';

import styles from './BetSlipHeader.module.css';

type Props = {
  handleToggleState: () => void;
  isOpened: boolean;
  count: number;
};

const BetSlipHeader = ({ handleToggleState, isOpened, count }: Props) => (
  <div
    className={styles.wrapperBetSlipButton}
    onClick={isOpened ? undefined : handleToggleState}
  >
    <BetSlipCount count={count} />

    {isOpened ? (
      <BetSlipTabs handleToggleState={handleToggleState} />
    ) : (
      <BetSlipShortInfo />
    )}
  </div>
);

export default BetSlipHeader;
