import { useMemo } from 'react';

import { IProvider } from 'decryption_protocol/dist/lib/store/casino/entities/providers/types';

import ProviderBlock from '../ProviderBlock';

type Props = {
  providerThree: {
    [letter: string]: (IProvider & { gameCounter: number })[];
  };
};

const ProviderThree = ({ providerThree }: Props) => {
  const keys = useMemo(() => Object.keys(providerThree), [providerThree]);

  return (
    <div>
      {keys.map((key) => (
        <ProviderBlock
          key={key}
          letter={key}
          providerByLetter={providerThree[key]}
        />
      ))}
    </div>
  );
};

export default ProviderThree;
