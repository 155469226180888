import React from 'react';
import { useTranslate } from 'decryption_component-kit';

const useCountDownTranslates = () => {
  const { getText } = useTranslate('', '');

  return {
    day: getText('vipClub.boostContent.day'),
    hours: getText('vipClub.boostContent.hours'),
    min: getText('vipClub.boostContent.min'),
    seconds: getText('vipClub.boostContent.seconds'),
    shortDay: getText('counter.dd'),
    shortHH: getText('counter.hh'),
    shortMM: getText('counter.mm'),
    shortSec: getText('counter.ss'),
  };
};

export default useCountDownTranslates;
