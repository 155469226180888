/* eslint-disable no-console */
import { IS_DEVELOPMENT_MODE } from 'constants/ui';
import { BalanceInfo } from 'decryption_protocol/dist/lib/store/user/entities/balance/types';
import { UserInfo } from 'decryption_protocol/dist/lib/store/user/types';
import {
  usePushControllerExperimental,
  useAuth,
  useUserInfo,
  useBalance,
} from 'decryption_protocol_hooks';

import { useCallback, useEffect, useRef, useState } from 'react';
import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';

import {
  useZustandLanguagesKitStore,
  languageSelectors,
} from 'decryption_component-kit';
import { isValidMobileAppSelector } from 'stores/marketing/selectors';
import { useZustandMarketingTabsStore } from 'stores/marketing/store';

import { getPushTokenSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';
import {
  addUsersToCollection,
  isUserAlreadyInDoc,
  setDataToPushUsers,
} from 'utils/firestore-db';
import { useHookFabric } from 'hooks/useHookFabric';

enum EServiceStatus {
  Unknown,
  Requesting,
  UpdateUser,
  Success,
  Reject,
}

export const usePush = () => {
  // setPushTokenAction()
  const pushToken = useZustandUserStore(getPushTokenSelector);

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const isMobileApp = useZustandMarketingTabsStore(isValidMobileAppSelector);

  const { savePushInfo } = useHookFabric({ fn: usePushControllerExperimental });

  const [ownServiceStatus, setIsOwnServiceStatus] = useState<EServiceStatus>(
    EServiceStatus.Unknown
  );

  const activeLanguage = useZustandLanguagesKitStore(
    languageSelectors.getActiveLanguageNameKitSelector
  );

  const balanceInfo = useHookFabric({
    fn: useBalance,
    customProps: {
      isBalanceInfo: true,
    },
  }) as BalanceInfo | null;

  const isAuth = useAuth({ isConnectedApp });

  const userInfo = useHookFabric({ fn: useUserInfo }) as UserInfo | null;

  const updateUserInfo = useCallback(
    async (token: string) => {
      try {
        if (!token || !userInfo) {
          return;
        }

        const { email, clientId, login } = userInfo as UserInfo;

        const { exists, _data } =
          (await isUserAlreadyInDoc(token)) || ({} as any);

        const dateNow = new Date().getTime();

        if (exists) {
          setDataToPushUsers(
            token,
            {
              email,
              clientId,
              login,
              version: '1.0.2',
              platform: 'web',
            },
            true
          );
        } else {
          addUsersToCollection(token, {
            token: token,
            createTime: dateNow,
            lastInteractionTime: dateNow,
            email,
            clientId,
            login,
            platform: 'web',
            version: '1.0.2',
          });
        }
      } catch (error) {
        console.error('error', error);
      }
      console.warn('FIREBASE UPDATED');
    },
    [userInfo, balanceInfo]
  );

  const saveToMainDataBase = useCallback(
    async (token: string) => {
      const { clientId, email = '' } = userInfo || {};

      if (isAuth && (!userInfo || !balanceInfo)) {
        return;
      }

      // postgresQl

      try {
        const response = await savePushInfo({
          clientId,
          email,
          deposits: balanceInfo?.successDepositCnt || 0,
          platform: 'web',
          pushToken: token,
          language: activeLanguage,
        });

        if (!response && !isAuth) {
          setIsOwnServiceStatus(EServiceStatus.UpdateUser);
        }

        console.info(response, 'response');
      } catch (error) {
        console.log('🚀 ~ error:', error);
      }

      // const data = await res.json();
      // firebase
      updateUserInfo(token);
    },
    [isAuth, userInfo, activeLanguage, balanceInfo]
  );

  useEffect(() => {
    if (IS_DEVELOPMENT_MODE) {
      return;
    }

    if (!pushToken || !isConnectedApp || isMobileApp || !userInfo) {
      // TODO important because instance of store is not created
      return;
    }

    if (ownServiceStatus === EServiceStatus.Unknown) {
      setIsOwnServiceStatus(EServiceStatus.Requesting);
      setTimeout(() => {
        saveToMainDataBase(pushToken);
      }, 5000);

      return;
    }

    //
  }, [pushToken, userInfo, ownServiceStatus, isConnectedApp, isMobileApp]);

  useEffect(() => {
    if (IS_DEVELOPMENT_MODE) {
      return;
    }

    if (!pushToken || (isAuth && (!userInfo || !balanceInfo))) {
      return;
    }

    if (ownServiceStatus === EServiceStatus.UpdateUser) {
      saveToMainDataBase(pushToken);
      // saveToMainDataBase(pushToken);

      return;
    }
  }, [ownServiceStatus, isAuth, userInfo, pushToken]);

  return pushToken;
};
