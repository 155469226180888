import { useEffect, useState } from 'react';

import { DEFAULT_INPUT_VALUE } from 'components/ui/Input/Base/constant';
import { DefaultInputType } from 'components/ui/Input/Base/types';
import AmountInput from 'components/ui/Input/AmountInput';

import { setFormsInfoAction } from 'stores/money/forms/selectors';
import { useZustandFormsStore } from 'stores/money/forms/store';
import { setPaymentsCurrentStepAction } from 'stores/money/payments/selectors';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';

import {
  PaymentChannelType,
  RublesMethods,
} from 'decryption_protocol/dist/lib/store/user/types';
import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';

import { isFilledForm } from 'utils/validation';
import { Steps } from 'stores/money/payments/types';

import depositStyles from '../../Deposit.module.css';

type Props = {
  channelId: RublesMethods;
  limit: { min: number; max: number };
};

const RubCreditCard = ({ limit, channelId }: Props) => {
  const [amount, setAmount] = useState<string>('');

  const setFormInfo = useZustandFormsStore(setFormsInfoAction);

  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  const currencyId = useZustandUserStore(getUserCurrencyIdSelector);

  useEffect(() => {
    const isFilled = isFilledForm([amount]);

    if (isFilled && currencyId) {
      setFormInfo({
        isDeposit: true,
        form: {
          amount,
          channelId,
          currencyId,
          requestId: ERequestIds.DepositRubles,
        },
      });
      setStep(Steps.FilledForm);
    } else {
      setStep(Steps.TypingData);
    }
  }, [currencyId, channelId, amount]);

  return (
    <AmountInput
      inputStyleType="border-colorful"
      inputLabelType="labelUp"
      placeholder="walletPopUp.forms.amount"
      size="full"
      onChange={setAmount}
      min={limit.min}
      max={limit.max}
      classes={depositStyles.amountInputMargin}
      wrapperClasses={depositStyles.marginInputBottom}
    />
  );
};

export default RubCreditCard;
