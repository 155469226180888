export interface ZustandDeviceStore extends ZustandDeviceEntities {
  setDeviceType: (t: EDeviceType) => void;
}

export interface ZustandDeviceEntities {
  device: EDeviceType;
}

export enum EDeviceType {
  Mobile,
  Tablet,
  Desktop,
}
