import cln from 'classnames';

import Header from 'components/Header';

import { getCasinoGameUrlSelector } from 'stores/casino/games/selectors';
import { useZustandCasinoGamesStore } from 'stores/casino/games/store';

import animation from 'styles/animation.module.css';
import styles from './MobileLaunchGamePopUp.module.css';

const MobileLaunchGamePopUp = () => {
  const casinoUrl = useZustandCasinoGamesStore(getCasinoGameUrlSelector);

  return (
    <div
      className={cln(
        styles.gameFrameWrapper,
        animation.animationShowCasinoGame
      )}
    >
      <Header isCasinoGameHeader />

      <iframe src={casinoUrl} className={styles.gameFrame} allow="fullscreen" />
    </div>
  );
};

export default MobileLaunchGamePopUp;
