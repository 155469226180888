import { Text } from 'decryption_component-kit';
import { useNavigate } from 'react-router';

import { getPromotionPathSelector } from 'stores/configuration/selectors';
import { useZustandWebConfiguration } from 'stores/configuration/store';

import styles from './PromotionBanner.module.css';
import { ModalTypes } from 'stores/modal/types';
import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';

const PromotionBanner = () => {
  const promotionPath = useZustandWebConfiguration(getPromotionPathSelector);

  const navigate = useNavigate();

  const closeModal = useZustandModalStore(closeAndOpenModalAction);

  const jumpTo = () => {
    if (!promotionPath) {
      return;
    }

    closeModal(ModalTypes.Unknown);

    navigate(promotionPath);
  };

  return (
    <div className={styles.wrapperPromotionBanner} onClick={jumpTo}>
      <img
        src="/icons-elon/landings/promotions/gifts.png"
        className={styles.gifts}
      />
      <img
        src="/icons-elon/landings/promotions/coins.png"
        className={styles.coins}
      />
      <img
        src="/icons-elon/landings/promotions/coin-1.png"
        className={styles.coinOne}
      />
      <img
        src="/icons-elon/landings/promotions/coin-2.png"
        className={styles.coinTwo}
      />
      <img
        src="/icons-elon/landings/promotions/coin-3.png"
        className={styles.coinThree}
      />
      <img
        src="/icons-elon/landings/promotions/coin-4.png"
        className={styles.coinFourth}
      />
      <img
        src="/icons-elon/landings/promotions/coin-5.png"
        className={styles.coinFifth}
      />

      <div className={styles.title}>
        <Text idT="bigGive.button" />
      </div>
    </div>
  );
};

export default PromotionBanner;
