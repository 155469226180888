import cln from 'classnames';
import QRCode from 'react-qr-code';
import { EIconType } from 'decryption_protocol/dist/lib/service/moneyResponse/types';
import { PaymentChannelType } from 'decryption_protocol/dist/lib/store/user/types';

import Input from 'components/ui/Input/Base';
import {
  Text,
  CopySvg,
  ErrorSvg,
  WaitingSvg,
  SuccessSvg,
} from 'decryption_component-kit';

import { copyToClipboard } from 'utils/shared';
import {
  getActivePaymentInfoSelector,
  getPaymentsMessageInfoSelector,
} from 'stores/money/payments/selectors';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { useZustandUserStore } from 'stores/user/personal/store';
import { getUserCurrencyNameSelector } from 'stores/user/personal/selectors';
import { useZustandFormsStore } from 'stores/money/forms/store';
import { getFormSInfoSelector } from 'stores/money/forms/selectors';

import styles from './Message.module.css';
import animation from 'styles/animation.module.css';

const PaymentMessage = () => {
  const {
    text,
    iconType,
    isQR = false,
    url = '',
  } = useZustandPaymentsStore(getPaymentsMessageInfoSelector);

  const currencyName = useZustandUserStore(getUserCurrencyNameSelector);

  const formInfo = useZustandFormsStore(getFormSInfoSelector);

  const activePayment = useZustandPaymentsStore(getActivePaymentInfoSelector);

  return (
    <div
      className={cln(
        styles.wrapperPaymentMessageInfo,
        animation.modalOpenMobileAnimation,
        {
          [styles.jscSB]: isQR,
        }
      )}
    >
      {isQR ? (
        <>
          <div>
            <div className={styles.titleQrCode}>
              <Text
                idT="walletPopUp.qrMessageTitle"
                specValue={`${currencyName} ${formInfo?.form.amount || ''}`}
              />
            </div>
            <div className={styles.descriptions}>
              <Text idT="walletPopUp.qrMessageDescription" />
            </div>
          </div>

          <QRCode value={url} size={160} />

          {activePayment?.id !== PaymentChannelType.InrDeposit && (
            <Input
              defaultValue={url}
              disabled
              onChange={console.info}
              size="large"
              placeholder="qrPath"
            >
              <CopySvg handleClick={copyToClipboard(url)} />
            </Input>
          )}
        </>
      ) : (
        <>
          <div className={styles.titleStatus}>
            {iconType === EIconType.Wait && (
              <Text idT="walletPopUp.depositPostpounedStatus" />
            )}
            {iconType === EIconType.Error && (
              <Text idT="walletPopUp.depositErrorStatus" />
            )}
            {iconType === EIconType.Success && (
              <Text idT="walletPopUp.DepositSuccessStatus" />
            )}
          </div>

          {iconType === EIconType.Wait && <WaitingSvg />}
          {iconType === EIconType.Error && <ErrorSvg />}
          {iconType === EIconType.Success && <SuccessSvg />}

          <div className={styles.textWrapper}>
            <Text idT={text} />
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentMessage;
