import { create } from 'zustand';
import { ShowMoneyPopUpState, ZustandBalanceStore } from './types';

export const useZustandBalanceStore = create<ZustandBalanceStore>((set) => ({
  balanceInfo: null,
  activeBalanceType: 'mainBalance',
  showMoneyPopUpState: ShowMoneyPopUpState.Unknown,

  setShowPopUpState: (showMoneyPopUpState) => {
    set({ showMoneyPopUpState });
  },

  setBalanceInfo: (balanceInfo) => {
    set({ balanceInfo });
  },
  setActiveBalanceType: (activeBalanceType) => {
    set({ activeBalanceType });
  },
}));
