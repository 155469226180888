import { EDeviceType } from './types';

export const handleDeviceDetection = () => {
  const screenWidth = window.innerWidth;

  if (screenWidth <= 768) {
    return EDeviceType.Mobile;
  }

  if (screenWidth >= 768 && screenWidth < 1024) {
    return EDeviceType.Tablet;
  }

  return EDeviceType.Desktop;
};
