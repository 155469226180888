import { useEffect, useState } from 'react';

import { setFormsInfoAction } from 'stores/money/forms/selectors';
import { useZustandFormsStore } from 'stores/money/forms/store';
import { setPaymentsCurrentStepAction } from 'stores/money/payments/selectors';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';

import Input from 'components/ui/Input/Base';
import { isFilledForm, validationNameSurname } from 'utils/validation';
import { DefaultInputType } from 'components/ui/Input/Base/types';
import { DEFAULT_INPUT_VALUE } from 'components/ui/Input/Base/constant';
import AmountInput from 'components/ui/Input/AmountInput';
import TurkishBankInput from 'components/ui/Input/TurkishBankInput';
import PhoneInput from 'components/ui/Input/Phone';
import InputUserInfo from 'components/ui/Input/InputUserInfo';

import { Steps } from 'stores/money/payments/types';
import { PaymentChannelType } from 'decryption_protocol/dist/lib/store/user/types';
import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';

import withdrawStyles from '../../Withdraw.module.css';

type Props = {
  min: number;
  max: number;
};

type InputType = 'accountNumber';

const VipHavale = ({ min, max }: Props) => {
  const setFormInfo = useZustandFormsStore(setFormsInfoAction);

  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  const currencyId = useZustandUserStore(getUserCurrencyIdSelector);

  const [accountNumber, setAccountNumber] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [name, setName] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [familyName, setFamilyName] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [bankInfo, setBankInfo] = useState<{
    iban: string;
    bankId: number;
    swiftCode: string;
  }>({ iban: '', bankId: 0, swiftCode: '' });

  const [amount, setAmount] = useState<string>('');
  const [phone, setPhone] = useState<string>('');

  const handleValidateForm = (inputName: InputType) => (value: string) => {
    if (inputName === 'accountNumber') {
      const errorText =
        value.length >= 7 && value.length <= 17 ? '' : 'errors.invalidValue';

      setAccountNumber({ value, errorText });

      return;
    }
    const setFn = inputName === 'familyName' ? setFamilyName : setName;

    setFn(validationNameSurname(value));
  };

  useEffect(() => {
    const isNotFilled = isFilledForm([
      accountNumber,
      name,
      familyName,
      bankInfo,
      amount,
      phone,
    ]);

    if (isNotFilled && currencyId) {
      setFormInfo({
        isDeposit: false,
        form: {
          currencyId,
          amount,
          accountNumber: accountNumber.value,
          phone,

          firstName: name.value,
          familyName: familyName.value,

          ...bankInfo,
          channelId: PaymentChannelType.VipHavale,
          requestId: ERequestIds.WithdrawHavaleHera,
        },
      });

      setStep(Steps.FilledForm);
    } else {
      setStep(Steps.TypingData);
    }
  }, [currencyId, accountNumber, phone, name, familyName, bankInfo, amount]);

  return (
    <div className={withdrawStyles.marginInputBottom}>
      <AmountInput
        placeholder="walletPopUp.forms.amount"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        isWithdraw
        size="full"
        onChange={setAmount}
        isPresets={false}
        min={min}
        max={max}
        classes={withdrawStyles.amountInputMargin}
        wrapperClasses={withdrawStyles.marginInputBottom}
      />

      <InputUserInfo
        isIgnoreUserInfo
        userInfoKey="name"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.name"
        size="full"
        classes={withdrawStyles.marginInputBottom}
        onChange={setName}
        field={name}
      />

      <InputUserInfo
        isIgnoreUserInfo
        userInfoKey="surname"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.sureName"
        size="full"
        field={familyName}
        onChange={setFamilyName}
        classes={withdrawStyles.marginInputBottom}
      />
      <Input
        inputLabelType="labelUp"
        inputStyleType="border-colorful"
        // 7 - 17
        placeholder="walletPopUp.forms.bankAccountNumber"
        size="full"
        errorText={accountNumber.errorText}
        onChange={handleValidateForm('accountNumber')}
        classes={withdrawStyles.marginInputBottom}
      />

      <PhoneInput
        countryCodeDefault="TR"
        withConfirm={false}
        size="full"
        inputLabelType="labelUp"
        inputStyleType="border-colorful"
        placeholder="walletPopUp.forms.phone"
        baseValue="+90"
        onChange={setPhone}
        classes={withdrawStyles.marginInputBottom}
      />

      {/* swift */}
      <TurkishBankInput
        withSwift
        onChange={setBankInfo}
        classesWrapper={withdrawStyles.marginInputBottom}
        classesInput={withdrawStyles.marginInputBottom}
        classesSelector={withdrawStyles.marginInputBottom}
      />
    </div>
  );
};

export default VipHavale;
