import { ZustandCasinoGameStore } from './types';

export const getGamesPageSelector = (state: ZustandCasinoGameStore) =>
  state.page;

export const getCasinoGameUrlSelector = (state: ZustandCasinoGameStore) =>
  state.casinoGameUrl;

export const getActiveProviderIdSelector = (state: ZustandCasinoGameStore) =>
  state.activeProviderId;

// ================================ ACTIONS ================================
export const setGamesPageAction = (state: ZustandCasinoGameStore) =>
  state.setPage;

export const setCasinoGameUrlAction = (state: ZustandCasinoGameStore) =>
  state.setCasinoGameUrl;

export const setActiveProviderIdAction = (state: ZustandCasinoGameStore) =>
  state.setActiveProviderId;
