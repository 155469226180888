import { ZustandFormsStore } from './types';

export const getActiveDepositBonusIdSelector = (state: ZustandFormsStore) =>
  state.bonusIdForActivate;

export const getFormsLoaderInfoSelector = (state: ZustandFormsStore) =>
  state.loaderInfo;

export const getFormSInfoSelector = (state: ZustandFormsStore) =>
  state.formInfo;

export const getBdtStepTwoInfoSelector = (state: ZustandFormsStore) =>
  state.bdtStepTwo;

// ============================== ACTION

export const setDepositBonusIdForActivateAction = (state: ZustandFormsStore) =>
  state.setBonusIdForActivate;

export const setFormsInfoAction = (state: ZustandFormsStore) =>
  state.setFormInfo;

export const setFormsButtonLoaderStateAction = (state: ZustandFormsStore) =>
  state.setLoaderInfo;

export const setBdtTwpStepInfoAction = (state: ZustandFormsStore) =>
  state.setBdtTwpStepInfo;
