import { TOKEN } from 'constants/localstoreKeys';
import { ENCRYPT_DATA } from 'constants/marketingTags';
import { decrypt } from 'stores/utils/crypto';
import { getCookie } from 'utils/cookie';
import { getFromLocalStorage } from 'utils/localstorage';

export const getInitialToken = () =>
  getFromLocalStorage(TOKEN) ||
  getCookie(TOKEN) ||
  (ENCRYPT_DATA ? decrypt(ENCRYPT_DATA, `${window.location.host}+kkm`) : '');
