import React from 'react';
import cln from 'classnames';

import styles from './ProgressBar.module.css';

export type ProgressProps = {
  percent: number;
  leftTitle?: string | React.ReactElement;
  rightTitle?: string | React.ReactElement;
  downLeft?: React.ReactElement;
  downRight?: React.ReactElement;
  classes?: string;
  classesProgress?: string;
};

const ProgressBar = (props: ProgressProps) => {
  const {
    percent,
    leftTitle,
    rightTitle,
    downLeft,
    downRight,
    classes,
    classesProgress,
  } = props;

  const isDownItems = downLeft || downRight;

  const isTitle = leftTitle || rightTitle;

  return (
    <div className={cln(styles.wrapperProgressBorder, classes)}>
      {isTitle && (
        <div className={styles.titleWrapper}>
          <div className={styles.leftTitle}> {leftTitle}</div>
          <div className={styles.rightTitle}> {rightTitle}</div>
        </div>
      )}

      <div className={cln(styles.progressWrapper, classesProgress)}>
        <div className={styles.progressFill} style={{ width: `${percent}%` }} />
      </div>

      {isDownItems && (
        <div className={styles.wrapperDownItems}>
          <div className={styles.leftDown}> {downLeft}</div>
          <div className={styles.rightDown}> {downRight}</div>
        </div>
      )}
    </div>
  );
};

export default ProgressBar;
