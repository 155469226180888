import { create } from 'zustand';
import { ZustandFrameLoyaltyStore } from './types';

export const useZustandFrameLoyaltyStore = create<ZustandFrameLoyaltyStore>(
  (set) => ({
    frameLink: null,

    setLoyaltyLink: (frameLink: string) => {
      set({ frameLink });
    },
  })
);
