import { EKYCStatus } from 'stores/user/personal/types';
import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';
import { EImgType } from 'decryption_protocol/dist/lib/service/moneyResponse/types';
import { LiveEventsShortEventView } from 'decryption_protocol/dist/lib/store/betting/entities/events/live/types';
// RESPONSE TYPES

export type HandleTokenUpdate = {
  at1: string;
  at2: string;
};

export type HandleCasinoLoyaltyFrameResponse = {
  requestId: ERequestIds;
  url: string;
};

export type HandleRunCasinoGameProps = {
  errorCode: number;
  requestId: ERequestIds;
  url: string;
};

export type HandleBillingAddressResponse = {
  imgType: EImgType;
  billingAccount: string;
  isError: boolean;
};

type UserPermissionsResponse = {
  isBonusBan: boolean;
  isBetsPause: boolean;
  maxLiveDelay: boolean;
  isLoginBan: boolean;
  isWithdrawalBan: boolean;
  isCashoutBan: boolean;
  isWithDrawalPostponed: boolean;
  verificationStatus: EKYCStatus;
};

type TotalDepositResponse = {
  dayDeposit: number;
  monthDeposit: number;
  needRolled: number;
  rolledWithTax: number;
  weekDeposit: number;
};

// TODO not implemented all properties
export type HandleAdditionalUserInfoProps = {
  isAvailableBonusPack: boolean;
  userPermissions: UserPermissionsResponse;
  totalDeposit: TotalDepositResponse;
  requestId: ERequestIds;
};

export type HandleRegistrationResponse = {
  requestId: ERequestIds;
  status: number;
  login: string;
  password: string;
};

export enum UpdatePasswordType {
  Success = 54,
}

export enum RecoveryPasswordStatus {
  UpdatePasswordAvailable = 15,
}

export enum SetNewPasswordStatus {
  Success = 12,
}
export enum UpdateUserInfo {
  PhoneAssignedSuccess = 50,
}
export type HandleBettingAvailableStatus = {
  status: boolean;
};
export type HandleCategoryTournamentsUpdates = {
  [categoryId: number]: { id: number }[];
};
export type HandleEventsByTournamentId = {
  [tournamentId: number]: LiveEventsShortEventView[];
};
export type HandleSetOddsInfo = {
  [getKeyFn: string]: {
    odd: number;
    key: string;
  };
};
