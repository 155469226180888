import { MixerTransactionAdditionalInfo } from 'decryption_protocol/dist/lib/store/operations/types';

import BonusCardComponentKit from 'components/ui/KitComponents/BonusCardComponentKit';

import { getBonusCardsSelector } from 'stores/configuration/selectors';
import { useZustandWebConfiguration } from 'stores/configuration/store';

type Props = {
  info: MixerTransactionAdditionalInfo;

  id: number;
};

const MonthlyCard = ({ info, id }: Props) => {
  const { resultValue, endTimestamp } = info;

  const bonusImagePaths = useZustandWebConfiguration(getBonusCardsSelector);

  return (
    <BonusCardComponentKit
      title="newBonuses.cards.monthly"
      description="newBonuses.cards.monthlyDescription"
      id={id}
      value={`${resultValue}`}
      endTimestamp={endTimestamp}
      bonusImagePath={bonusImagePaths?.monthly || ''}
    />
  );
};

export default MonthlyCard;
