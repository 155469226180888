import AmountInput from 'components/ui/Input/AmountInput';
import { DEFAULT_INPUT_VALUE } from 'components/ui/Input/Base/constant';
import { DefaultInputType } from 'components/ui/Input/Base/types';
import {
  PaymentChannelType,
  UserInfo,
} from 'decryption_protocol/dist/lib/store/user/types';
import { useUserInfo } from 'decryption_protocol_hooks';
import React, { useEffect, useState } from 'react';
import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';
import { setFormsInfoAction } from 'stores/money/forms/selectors';
import { useZustandFormsStore } from 'stores/money/forms/store';
import { setPaymentsCurrentStepAction } from 'stores/money/payments/selectors';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { Steps } from 'stores/money/payments/types';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';
import { isFilledForm, validateEmailValue } from 'utils/validation';

import depositStyles from '../../Deposit.module.css';
import PhoneInput from 'components/ui/Input/Phone';
import InputUserInfo from 'components/ui/Input/InputUserInfo';
import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';

type Props = {
  limit: {
    min: number;
    max: number;
  };
};

const CashXAzn = ({ limit }: Props) => {
  const [amount, setAmount] = useState<string>('');

  const [phone, setPhone] = useState<string>('');
  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const userInfo = useUserInfo({ isConnectedApp }) as UserInfo | null;

  const [email, setEmail] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [name, setName] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [sureName, setSurName] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const currencyId = useZustandUserStore(getUserCurrencyIdSelector);

  const setFormInfo = useZustandFormsStore(setFormsInfoAction);

  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  useEffect(() => {
    const data = [amount, phone, email, name, sureName];

    const isFilled = isFilledForm(data);

    if (isFilled && currencyId) {
      setFormInfo({
        isDeposit: true,
        form: {
          amount,
          channelId: PaymentChannelType.CashXAZN,
          email: email.value || '',
          name: name.value,
          sureName: sureName.value,
          phone: phone.slice(3),
          currencyId,
          requestId: ERequestIds.WalletAzn,
        },
      });
      setStep(Steps.FilledForm);
    } else {
      setStep(Steps.TypingData);
    }
  }, [currencyId, amount, phone, email, name, sureName]);

  return (
    <div>
      <AmountInput
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.amount"
        size="full"
        onChange={setAmount}
        min={limit.min}
        max={limit.max}
        classes={depositStyles.amountInputMargin}
        wrapperClasses={depositStyles.marginInputBottom}
      />

      {/* Phone */}
      <PhoneInput
        countryCodeDefault="AZ"
        withConfirm={false}
        size="full"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        sendKey="09x"
        onChange={setPhone}
        isHidePlaceholder={false}
        baseValue="+91"
        placeholder="walletPopUp.forms.phone"
        classesWrapper={depositStyles.marginInputBottom}
      />

      <InputUserInfo
        userInfoKey="name"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.name"
        size="full"
        classes={depositStyles.marginInputBottom}
        onChange={setName}
        field={name}
      />
      <InputUserInfo
        userInfoKey="surname"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.sureName"
        size="full"
        classes={depositStyles.marginInputBottom}
        onChange={setSurName}
        field={sureName}
      />

      <InputUserInfo
        size="full"
        userInfoKey="email"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        validationFn={validateEmailValue}
        onChange={setEmail}
        field={email}
        placeholder="walletPopUp.forms.email"
        classes={depositStyles.marginInputBottom}
      />
    </div>
  );
};

export default CashXAzn;
