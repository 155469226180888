import { BalanceInfo } from 'decryption_protocol/dist/lib/store/user/entities/balance/types';
export interface ZustandBalanceStore extends ZustandBalanceEntities {
  setActiveBalanceType: (type: keyof BalanceInfo) => void;
  setBalanceInfo: (balance: BalanceInfo) => void;
  setShowPopUpState: (d: ShowMoneyPopUpState) => void;
}

interface ZustandBalanceEntities {
  balanceInfo: BalanceInfo | null;
  activeBalanceType: keyof BalanceInfo | 'customBonusCoin';
  showMoneyPopUpState: ShowMoneyPopUpState;
}

export enum ShowMoneyPopUpState {
  Unknown,
  WaitingBalance,
  Showed,
}
