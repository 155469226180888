import React from 'react';

import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';

import { NEXT_PUBLIC_BASE_IMAGE_URL } from 'constants/image';

import { setInstaNewsPropertyAction } from 'stores/instaNews/selectors';
import { useZustandInstaNewsStore } from 'stores/instaNews/store';
import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';

import { ModalTypes } from 'stores/modal/types';

import styles from './Post.module.css';

const Post = ({ image, id }: { image: string; id: number }) => {
  const setProperty = useZustandInstaNewsStore(setInstaNewsPropertyAction);

  const onClose = useZustandModalStore(closeAndOpenModalAction);

  const onDisabled = () => {
    setProperty('lastTimeStoryId', id);

    onClose(ModalTypes.Unknown);
  };

  return (
    <div className={styles.wrapper}>
      {image ? (
        <img
          src={`${NEXT_PUBLIC_BASE_IMAGE_URL}${image}`}
          className={styles.img}
        />
      ) : (
        <div className={styles.end}>
          <div className={styles.title}>Title</div>

          <div className={styles.description}>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque
            velit iure autem hic culpa, deserunt soluta modi voluptatum id
            excepturi voluptatibus, nulla molestiae labore assumenda repellat
            aut fugiat aperiam illo.
          </div>

          <ComponentKitBaseButton
            text="Close"
            handleClick={onDisabled}
            color="primary"
            size="--button-xxl"
            buttonHeight="--button-height-l"
            classes={styles.closeButton}
            centreText
          />
        </div>
      )}
    </div>
  );
};

export default Post;
