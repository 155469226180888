import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { CloseSvg } from 'decryption_component-kit';
import FavoriteButton from 'components/ui/Buttons/FavoriteButton';

import { useZustandCasinoGamesStore } from 'stores/casino/games/store';
import {
  getCasinoGameUrlSelector,
  setCasinoGameUrlAction,
} from 'stores/casino/games/selectors';

import styles from './GameActions.module.css';

type Props = {
  gameId: number;
};

const GameActions = ({ gameId }: Props) => {
  const setCasinoGameUrl = useZustandCasinoGamesStore(setCasinoGameUrlAction);

  const activeGameUrl = useZustandCasinoGamesStore(getCasinoGameUrlSelector);

  const navigate = useNavigate();

  const [isClose, setClose] = useState(true);

  const toggleCloseState = () => {
    const newValue = !isClose;

    if (isClose) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    setClose(newValue);
  };

  const onClose = () => {
    if (activeGameUrl) {
      setCasinoGameUrl('');

      return;
    }

    navigate(-1);
  };

  useEffect(
    () => () => {
      document.body.style.overflow = 'unset';
    },
    []
  );

  return (
    <div className={styles.wrapperButtons}>
      <FavoriteButton gameId={gameId} classes={styles.fill} />

      {/* <div className={styles.buttonBackground} onClick={toggleCloseState}>
        {isClose ? <LockOpenSvg /> : <LockCloseSvg />}
      </div> */}

      <div className={styles.buttonBackground} onClick={onClose}>
        <CloseSvg classes={styles.fill} />
      </div>
    </div>
  );
};

export default GameActions;
