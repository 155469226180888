import React from 'react';
import { useBetSlipSpecialOdd } from 'decryption_protocol_hooks';

import { useHookFabric } from 'hooks/useHookFabric';

const SystemOdd = () => {
  const odd = useHookFabric({
    fn: useBetSlipSpecialOdd,
    customProps: { systemOdd: true },
  }) as {
    system: string;
  };

  return <div>{odd.system}</div>;
};

export default SystemOdd;
