import cln from 'classnames';

import { ActiveWalletTab } from 'stores/money/payments/types';
import { Text, DepositSvg, WithdrawSvg } from 'decryption_component-kit';

import styles from './Tabs.module.css';
import animation from 'styles/animation.module.css';

type Props = {
  activeTab: ActiveWalletTab;
  setActiveTab: (s: ActiveWalletTab) => void;
};

const Tabs = ({ setActiveTab, activeTab }: Props) => {
  const handleSetActiveTab = (t: ActiveWalletTab) => () => {
    setActiveTab(t);
  };

  return (
    <div className={styles.wrapperTabs}>
      <div
        className={cln(styles.activeTab, {
          [animation.animationLeft]: activeTab === 'deposit',
          [animation.animationRight]: activeTab === 'withdraw',
        })}
      />

      <div
        className={cln(styles.buttonModifier, {
          [styles.activeText]: activeTab === 'deposit',
        })}
        onClick={handleSetActiveTab('deposit')}
      >
        <DepositSvg classes={styles.index} />

        <span className={styles.index}>
          <Text idT="walletPopUp.tabs.deposit" />
        </span>
      </div>

      <div
        className={cln(styles.buttonModifier, {
          [styles.activeText]: activeTab === 'withdraw',
        })}
        onClick={handleSetActiveTab('withdraw')}
      >
        <WithdrawSvg classes={styles.index} />

        <span className={styles.index}>
          <Text idT="walletPopUp.tabs.withdraw" />
        </span>
      </div>
    </div>
  );
};

export default Tabs;
