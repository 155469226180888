import cryptoJs from 'crypto-js';

export function decrypt(cipherText: string, secret: string) {
  try {
    const result = cryptoJs.AES.decrypt(
      window.atob(cipherText),
      secret
    ).toString(cryptoJs.enc.Utf8);

    return JSON.parse(result).str;
  } catch (error) {
    return '';
  }
}
