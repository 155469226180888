import { Tournament } from 'decryption_protocol/dist/lib/store/betting/entities/tournaments/types';
import { useBettingTournamentById } from 'decryption_protocol_hooks';

import { useHookFabric } from 'hooks/useHookFabric';

type Props = {
  tournamentId: number;
  classes?: string;
};

// all styles from parents
const TournamentName = ({ classes, tournamentId }: Props) => {
  const tournament = useHookFabric({
    fn: useBettingTournamentById,
    customProps: { tournamentId },
  }) as Tournament | null;

  return <div className={classes}>{tournament?.name}</div>;
};

export default TournamentName;
