import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';

import {
  useZustandLanguagesKitStore,
  languageSelectors,
  CloseSvg,
} from 'decryption_component-kit';
import { getLanguagesInfoSelector } from 'stores/configuration/selectors';
import { useZustandWebConfiguration } from 'stores/configuration/store';

import { useZustandModalStore } from 'stores/modal/store';
import { useZustandMarketingTabsStore } from 'stores/marketing/store';
import { isValidMobileAppSelector } from 'stores/marketing/selectors';
import { closeAndOpenModalAction } from 'stores/modal/selectors';

import { IS_VALID_MOBILE_APP } from 'constants/marketingTags';
import { getFlagIcon } from 'utils/icons';

import { ModalTypes } from 'stores/modal/types';
import { ERouteNames } from 'Routes/types';

import styles from './Languages.module.css';

const Languages = () => {
  const languages = useZustandWebConfiguration(getLanguagesInfoSelector) || [];

  const closeModal = useZustandModalStore(closeAndOpenModalAction);

  const isMobileApp = useZustandMarketingTabsStore(isValidMobileAppSelector);

  const activeLanguage = useZustandLanguagesKitStore(
    languageSelectors.getActiveLanguageNameKitSelector
  );

  const onClose = () => {
    closeModal(ModalTypes.Unknown);
  };

  const handleChangeLanguage = (lang: string) => () => {
    const additionalInfo = isMobileApp ? `&${IS_VALID_MOBILE_APP}=1` : '';

    window.location.href = `${window.location.origin}${ERouteNames.CasinoHome}?lang=${lang}${additionalInfo}`;
  };

  return (
    <div className={styles.wrapperDrawer}>
      <div className={styles.closeBtn} onClick={onClose}>
        <CloseSvg classes={styles.closeIcon} />
      </div>

      <div className={styles.languageWrapper}>
        {languages.map((l) => (
          <ComponentKitBaseButton
            key={l.shortName}
            disabled={activeLanguage === l.shortName}
            color={activeLanguage === l.shortName ? 'primary' : 'dark'}
            size="--button-full"
            buttonHeight="--button-height-s"
            text={l.name}
            startIcon={getFlagIcon(l.shortName, styles.iconFlag)}
            classes={styles.button}
            handleClick={handleChangeLanguage(l.shortName)}
          />
        ))}
      </div>
    </div>
  );
};

export default Languages;
