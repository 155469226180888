import { create } from 'zustand';
import { ZustandBettingFrameStore } from './types';

export const useZustandBettingFrameStore = create<ZustandBettingFrameStore>(
  (set) => ({
    openPopUpType: null,
    navigateToPage: null,

    setBettingFrameProperty: (key, value) => {
      set({ [key]: value });
    },
  })
);
