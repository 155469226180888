import cln from 'classnames';

import {
  useGetVipProgress,
  useGetMixTransactions,
} from 'decryption_protocol_hooks';
import {
  CommonOperationProperties,
  MixerTransactionResponse,
  TransactionFilterType,
} from 'decryption_protocol/dist/lib/store/operations/types';

import ProgressBar from 'components/ui/Progress/ProgressBar';
import { Text, useTranslate } from 'decryption_component-kit';
import RakeBackCard from './components/RakeBackCard';
import SupportCard from './components/SupportCard';
import BattleCard from './components/BattleCard';
import WeeklyCard from './components/WeeklyCard';
import MonthlyCard from './components/MonthlyCard';

import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { getLoyaltyWagerSelector } from 'stores/user/balance/selectors';
import { useZustandBalanceStore } from 'stores/user/balance/store';
import { useZustandUserStore } from 'stores/user/personal/store';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './VipBonuses.module.css';

const range = [0, 0];

const VipBonuses = () => {
  const currencyId = useZustandUserStore(getUserCurrencyIdSelector) || 0; // TODO USD

  const loyaltyWager = useZustandBalanceStore(getLoyaltyWagerSelector) || 0;

  const { getText } = useTranslate('', '');

  const { percent, level } = useHookFabric({
    fn: useGetVipProgress,
    customProps: {
      currencyId,
      loyaltyWager,
    },
  }) as { level: { current: number; next: number }; percent: number };

  const transactions = useHookFabric({
    fn: useGetMixTransactions,
    customProps: {
      range,
      types: [
        TransactionFilterType.Egt,
        // TODO bonus tournaments
        // TODO hide bonus sum
        TransactionFilterType.ActiveTournamentPrize,
        // TransactionFilterType.ActiveMonthly,
      ],
    },
  }) as MixerTransactionResponse;

  return (
    <div className={styles.wrapper}>
      <div className={styles.desktopRow}>
        <ProgressBar
          leftTitle={
            <span className={styles.progressColor}>
              <Text idT="newBonuses.progress.left" />
            </span>
          }
          rightTitle={
            <span className={cln(styles.progressColor, styles.opacity)}>
              {percent}%
            </span>
          }
          downRight={
            <span className={styles.downText}>
              <Text
                idT="newBonuses.progress.right"
                specValue={`${getText(`vipClub.level.${level.current + 1}`)}`}
              />
            </span>
          }
          percent={percent}
          classesProgress={styles.progress}
          classes={styles.progressWrapper}
        />

        <div>
          <Text idT="newBonuses.progress.bottomText" />
        </div>
      </div>

      <div className={styles.bonuses}>
        <RakeBackCard />

        <WeeklyCard />

        {transactions.map((t) => {
          if (t.additionalInfo.type === TransactionFilterType.Egt) {
            return <SupportCard key={t.id} info={t.additionalInfo} id={t.id} />;
          }

          if (t.additionalInfo.type === TransactionFilterType.TournamentPrize) {
            return (
              <BattleCard
                key={t.id}
                info={t.additionalInfo}
                id={t.id}
                operation={t.operation as CommonOperationProperties}
              />
            );
          }

          if (t.additionalInfo.type === TransactionFilterType.Monthly) {
            return <MonthlyCard key={t.id} info={t.additionalInfo} id={t.id} />;
          }

          return null;
        })}
      </div>
    </div>
  );
};

export default VipBonuses;
