import { useState } from 'react';
import cln from 'classnames';

import { ArrowSvg } from 'decryption_component-kit';

import { getDateByFormat } from 'utils/date';

import styles from './CashoutRecords.module.css';

type Props = {
  cashoutRecords: any;
};

// editableTicketId
// stake
// sum
// timestamp
// []

const CashoutRecords = ({ cashoutRecords }: Props) => {
  const [isVisible, setVisibleState] = useState<boolean>(false);

  const handleToggleState = () => {
    setVisibleState(!isVisible);
  };

  return (
    <div>
      <div
        className={cln(styles.wrapperTitle, {
          [styles.visibleHistoryTitle]: isVisible,
        })}
        onClick={handleToggleState}
      >
        <div>T.cashout history</div>

        <ArrowSvg classes={cln({ [styles.rotated]: isVisible })} />
      </div>

      {isVisible && (
        <div className={styles.wrapperCashoutHistoryList}>
          {cashoutRecords.map((cashout: any) => (
            <div
              key={cashout.editableTicketId}
              className={styles.cashoutHistoryItemWrapper}
            >
              <div>
                <div className={styles.title}>T.UsedStake</div>
                <div className={styles.value}>{cashout.stake}</div>
              </div>
              <div>
                <div className={styles.title}>T.Cash out</div>
                <div className={styles.value}>{cashout.sum}</div>
              </div>

              <div className={styles.timeBlock}>
                {getDateByFormat('DD.MM.YYYY | HH:mm', cashout.timestamp)}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CashoutRecords;
