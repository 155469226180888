import cln from 'classnames';

import { Text, CloseSvg } from 'decryption_component-kit';
import { ReactComponent as ArrowBackSvg } from 'assets/base/arrow-back.svg';

import styles from './PopUpHeader.module.css';

type Props = {
  title: string;
  closeButton?: () => void;
  classes?: string;
  backButton?: () => void;
};

const PopUpHeader = (props: Props) => {
  const { title, closeButton, classes, backButton } = props;

  return (
    <div className={cln(styles.wrapperPopUpHeader, classes)}>
      <div>
        {backButton && (
          <div className={styles.closeButton} onClick={backButton}>
            <ArrowBackSvg className={styles.arrowBackSvg} />
          </div>
        )}
      </div>

      <div className={styles.title}>
        <Text idT={title} />
      </div>

      <div>
        {closeButton && (
          <div className={styles.closeButton} onClick={closeButton}>
            <CloseSvg classes={styles.closeSvg} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PopUpHeader;
