import React from 'react';
import { CountDown } from 'decryption_component-kit';
import { CountDownProps } from 'decryption_component-kit/dist/lib/components/ui/CountDown/types';

import useCountDownTranslates from 'hooks/useCountDownTranslates';

const CountDownKit = (props: Omit<CountDownProps, 'names'>) => {
  const countDownTranslates = useCountDownTranslates();

  return <CountDown {...props} names={countDownTranslates} />;
};

export default CountDownKit;
