import { useEffect } from 'react';
import {
  useGetUserTournamentInfo,
  useTransportControllers,
} from 'decryption_protocol_hooks';
import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';
import { CurrentTournamentPlayer } from 'decryption_protocol/dist/lib/store/casino/entities/casinoTournaments/types';

import CasinoTournamentPlayer from 'components/ui/TournamentPlayer';

import { useZustandUserStore } from 'stores/user/personal/store';
import { getClientIdSelector } from 'stores/user/personal/selectors';

import { useHookFabric } from 'hooks/useHookFabric';

type Props = {
  tournamentId: number;
};

const CasinoSignInTournamentPlayer = ({ tournamentId }: Props) => {
  const userInfo = useHookFabric({
    fn: useGetUserTournamentInfo,
    customProps: {
      tournamentId,
    },
  }) as CurrentTournamentPlayer | null;

  const currentUserId = useZustandUserStore(getClientIdSelector);

  const { sendCommand } = useTransportControllers();

  useEffect(() => {
    if (!currentUserId) {
      return;
    }

    sendCommand(
      '45x',
      {
        casinoTournamentId: tournamentId,
        requestId: ERequestIds.GetCurrentUserTournamentPlace,
      },
      true
    );
  }, [tournamentId, currentUserId]);

  if (!userInfo?.position || !currentUserId) {
    return null;
  }

  return <CasinoTournamentPlayer {...userInfo} id={currentUserId} isHideInfo />;
};

export default CasinoSignInTournamentPlayer;
