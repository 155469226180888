import { create } from 'zustand';
import { ZustandMainLoaderStore } from './types';

export const useZustandMainLoaderStore = create<ZustandMainLoaderStore>(
  (set) => ({
    percent: 0,

    setPercent: (percent) => {
      set({ percent });
    },
  })
);
