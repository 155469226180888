import { ZustandPaymentsStore } from './types';

export const getActivePaymentsTabSelector = (state: ZustandPaymentsStore) =>
  state.activeTab;

export const getCurrentPaymentStepSelector = (state: ZustandPaymentsStore) =>
  state.step;

export const getPaymentsMessageInfoSelector = (state: ZustandPaymentsStore) =>
  state.messageInfo;

export const getActivePaymentChannelSelector = (state: ZustandPaymentsStore) =>
  state.activeChannelId;

export const getActivePaymentInfoSelector = (state: ZustandPaymentsStore) =>
  state.activePaymentInfo;

export const getDepositQrFormInfoSelector = (state: ZustandPaymentsStore) =>
  state.depositQrFormInfo;

export const getRedirectUrlSelector = (state: ZustandPaymentsStore) =>
  state.redirectUrl;
// ============================== ACTION

export const setActivePaymentsTabAction = (state: ZustandPaymentsStore) =>
  state.setActiveTab;

export const setPaymentsCurrentStepAction = (state: ZustandPaymentsStore) =>
  state.setStep;

export const setActivePaymentChannelAction = (state: ZustandPaymentsStore) =>
  state.setActivePaymentChannel;

export const setDepositQrFormInfoAction = (state: ZustandPaymentsStore) =>
  state.setDepositQrFormInfo;

export const setActivePaymentInfoAction = (state: ZustandPaymentsStore) =>
  state.setActivePaymentInfo;
