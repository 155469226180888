import styles from './BetSlipCount.module.css';

const BetSlipCount = ({ count }: { count: number }) => (
  <div className={styles.row}>
    <div className={styles.counter}>x{count}</div>
    <div className={styles.name}>T.BetSlip</div>
  </div>
);

export default BetSlipCount;
