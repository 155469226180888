import { useNavigate } from 'react-router';
import { TournamentCard } from 'decryption_component-kit';
import { AdaptedCasinoTournament } from 'decryption_protocol/dist/lib/store/casino/entities/casinoTournaments/types';
import { ECasinoCategoryIds } from 'decryption_protocol/dist/lib/store/casino/entities/categories/types';

import { APP_TYPE } from 'constants/ui';

import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { ModalTypes } from 'stores/modal/types';
import { useZustandUserStore } from 'stores/user/personal/store';
import { getUserCurrencyNameSelector } from 'stores/user/personal/selectors';

import { ERouteNames } from 'Routes/types';
import useCountDownTranslates from 'hooks/useCountDownTranslates';

type Props = AdaptedCasinoTournament & {
  isFinished?: boolean;
  classes?: string;
};

const TournamentCardComponentKit = (props: Props) => {
  const { amount, endDate, isFinished = false, classes } = props;

  const currencyName = useZustandUserStore(getUserCurrencyNameSelector);

  const countDownPropsNames = useCountDownTranslates();

  const setModal = useZustandModalStore(closeAndOpenModalAction);

  const navigate = useNavigate();

  const handleOpenTournamentRules = () => {
    setModal(ModalTypes.TournamentRules);
  };

  const jumpToSlots = () => {
    navigate(
      ERouteNames.CasinoCategories.replace(
        ':categoryId',
        `${ECasinoCategoryIds.Slots}`
      )
        .replace(':subCategoryId', 'all')
        .replaceAll(':page', '1')
        .replaceAll(':scroll', '0')
    );
    setModal(ModalTypes.Unknown);
  };

  return (
    <TournamentCard
      isFinished={isFinished}
      finishedBackgroundImagePath="url(/icons-elon/tournaments/bg.png)"
      activeTournamentImagePath={`/icons-elon/tournaments/${APP_TYPE}/battle-logo.png`}
      activeTournamentName="tournaments.active"
      notActiveTournamentName="tournaments.notActive"
      endDate={endDate}
      counterProps={{ names: countDownPropsNames }}
      prizeText="tournaments.prize"
      currencyName={currencyName}
      amount={amount}
      handleOpenTournamentRules={handleOpenTournamentRules}
      jumpToSlots={jumpToSlots}
      classes={classes}
    />
  );
};

export default TournamentCardComponentKit;
