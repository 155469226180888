import { ZustandRightSideStore } from './types';

export const isFullRightSideSelector = (state: ZustandRightSideStore) =>
  state.isFullView;
export const getActiveRightSideTabSelector = (state: ZustandRightSideStore) =>
  state.activeTab;

// Actions

export const setRightSideDrawerViewAction = (state: ZustandRightSideStore) =>
  state.setRightSideDrawerView;

export const setRightSideActiveTabAction = (state: ZustandRightSideStore) =>
  state.setRightSideTab;
