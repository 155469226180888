import { useEffect, useState } from 'react';

import { setFormsInfoAction } from 'stores/money/forms/selectors';
import { useZustandFormsStore } from 'stores/money/forms/store';
import { setPaymentsCurrentStepAction } from 'stores/money/payments/selectors';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';

import Input from 'components/ui/Input/Base';
import { DefaultInputType } from 'components/ui/Input/Base/types';
import { DEFAULT_INPUT_VALUE } from 'components/ui/Input/Base/constant';
import AmountInput from 'components/ui/Input/AmountInput';

import { isFilledForm, validationPatternByName } from 'utils/validation';

import { Steps } from 'stores/money/payments/types';
import { PaymentChannelType } from 'decryption_protocol/dist/lib/store/user/types';
import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';

import TurkishBankInput from 'components/ui/Input/TurkishBankInput';
import withdrawStyles from '../../../Withdraw.module.css';
import InputUserInfo from 'components/ui/Input/InputUserInfo';

type Props = {
  min: number;
  max: number;
};

const TurkishMPayEft = ({ max, min }: Props) => {
  const setFormInfo = useZustandFormsStore(setFormsInfoAction);

  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  const currencyId = useZustandUserStore(getUserCurrencyIdSelector);

  const [name, setName] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [walletNumber, setWalletNumber] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [bankId, setBankId] = useState<number | null>(null);

  const [iban, setIban] = useState<string>('');

  const [amount, setAmount] = useState<string>('');

  const [familyName, setFamilyName] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const handleValidateForm = (inputName: 'walletNumber') => (value: string) => {
    if (inputName === 'walletNumber') {
      const errorText = value.length < 7 ? 'errors.invalidValue' : '';

      setWalletNumber({ value, errorText });

      return;
    }
  };

  const handleChangeIban = ({
    iban,
    bankId,
  }: {
    iban: string;
    bankId: number | string;
  }) => {
    setIban(iban);
    setBankId(+bankId);
  };

  useEffect(() => {
    const isFilled = isFilledForm([amount, name, familyName, walletNumber]);

    if (isFilled && bankId && iban && currencyId) {
      setFormInfo({
        isDeposit: false,
        form: {
          walletNumber: walletNumber.value,
          amount,
          currencyId,
          iban,
          bankId: `${bankId}`,
          channelId: PaymentChannelType.EFTMPay,
          firstName: name.value,
          familyName: familyName.value,
          // TODO MPay
          requestId: ERequestIds.MPayWithdraw,
        },
      });
      setStep(Steps.FilledForm);
    } else {
      setStep(Steps.TypingData);
    }
  }, [currencyId, name, familyName, amount, walletNumber, bankId, iban]);

  return (
    <div className={withdrawStyles.marginInputBottom}>
      <AmountInput
        placeholder="walletPopUp.forms.amount"
        inputLabelType="labelUp"
        isWithdraw
        inputStyleType="border-colorful"
        size="full"
        onChange={setAmount}
        isPresets={false}
        min={min}
        max={max}
        classes={withdrawStyles.amountInputMargin}
        wrapperClasses={withdrawStyles.marginInputBottom}
      />

      <Input
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.bankAccountNumber"
        inputStyleType="border-colorful"
        size="full"
        errorText={walletNumber.errorText}
        patternFn={validationPatternByName}
        onChange={handleValidateForm('walletNumber')}
        classes={withdrawStyles.marginInputBottom}
      />

      <TurkishBankInput
        isMpay
        onChange={handleChangeIban}
        classesWrapper={withdrawStyles.marginInputBottom}
        classesInput={withdrawStyles.marginInputBottom}
        classesSelector={withdrawStyles.marginInputBottom}
      />
      <InputUserInfo
        isIgnoreUserInfo
        userInfoKey="name"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.name"
        size="full"
        classes={withdrawStyles.marginInputBottom}
        onChange={setName}
        field={name}
      />

      <InputUserInfo
        isIgnoreUserInfo
        userInfoKey="surname"
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.sureName"
        size="full"
        field={familyName}
        onChange={setFamilyName}
        classes={withdrawStyles.marginInputBottom}
      />
    </div>
  );
};

export default TurkishMPayEft;
