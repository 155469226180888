import cln from 'classnames';
import {
  useBetSlipPlaceBet,
  useBetSlipControllers,
  useAuth,
  useBetSlipAmount,
} from 'decryption_protocol_hooks';

import { EPlaceBetStatus } from 'decryption_protocol/dist/lib/store/betting/entities/betslip/types';

import SkeletLoader from 'components/ui/Loaders/SkeletLoader';

import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';
import { useZustandModalStore } from 'stores/modal/store';
import { setModalInfoAction } from 'stores/modal/selectors';
import { getUserCurrencyNameSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';

import { ModalTypes } from 'stores/modal/types';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './PlaceBetSlipButtons.module.css';

type Props = {
  classes?: string;
};

const DISABLED_STATUS = [
  EPlaceBetStatus.Unknown,
  EPlaceBetStatus.MaxLimitNotValid,
  EPlaceBetStatus.Sending,
  EPlaceBetStatus.StakeError,
];

const PlaceBetSlipButtons = ({ classes }: Props) => {
  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const userInfo = useAuth({ isConnectedApp });

  const setModal = useZustandModalStore(setModalInfoAction);

  const status: any = useHookFabric({ fn: useBetSlipPlaceBet });

  const currencyName = useZustandUserStore(getUserCurrencyNameSelector);

  const betSlipAmounts = useHookFabric({ fn: useBetSlipAmount });

  const { placeBet, acceptErrors }: any = useHookFabric({
    fn: useBetSlipControllers,
  });

  const handleClick = () => {
    if (!userInfo) {
      setModal(ModalTypes.SignIn);

      return;
    }

    switch (status) {
      case EPlaceBetStatus.ReadyToBet:
        return placeBet();
      case EPlaceBetStatus.Rejected:
        return acceptErrors();

      default:
        return undefined;
    }
  };

  return (
    <div
      className={cln(styles.wrapperPlaceBet, classes, {
        [styles.disabled]: DISABLED_STATUS.includes(status),
        [styles.readyToBet]: status === EPlaceBetStatus.ReadyToBet,
        [styles.error]: status === EPlaceBetStatus.Rejected,
      })}
      onClick={handleClick}
    >
      {status === EPlaceBetStatus.Sending && (
        <SkeletLoader classes={styles.loader} />
      )}
      {status === EPlaceBetStatus.Rejected ? (
        <div>T.Accept errors and try again</div>
      ) : (
        <>
          <div className={styles.mainText}>
            Place bet {EPlaceBetStatus[status]}
          </div>
          <div className={styles.subInfo}>
            Total to return {betSlipAmounts} {currencyName}
          </div>
        </>
      )}
    </div>
  );
};

export default PlaceBetSlipButtons;
