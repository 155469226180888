import { useMemo } from 'react';
import { Tabs, useTranslate } from 'decryption_component-kit';
import { TabsProps } from 'decryption_component-kit/dist/lib/components/ui/Tabs/types';

const ComponentKitTabs = (props: TabsProps) => {
  const { data } = props;

  const { getText } = useTranslate('', '');

  const translatedData = useMemo(
    () => data.map((t) => ({ ...t, label: `${getText(t.label)}` })),
    [data, getText]
  );

  return <Tabs {...props} data={translatedData} />;
};

export default ComponentKitTabs;
