import ReactDOM from 'react-dom';
import {
  getNotificationsSelector,
  removeNotificationAction,
} from 'stores/notification/selectors';
import { useZustandNotificationStore } from 'stores/notification/store';
import NotificationView from './components/NotificationView';

const notificationPortal = document.getElementById('notification-box');

const Notification = () => {
  const notifications = useZustandNotificationStore(getNotificationsSelector);

  if (!notificationPortal) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      {notifications.map((n, inx) => (
        <NotificationView {...n} key={`${n.text}_${n.type}_${n.uid}`} />
      ))}
    </>,
    notificationPortal
  );
};

export default Notification;
