import { useCallback } from 'react';
import { useGetProviders } from 'decryption_protocol_hooks';
import { IProvider } from 'decryption_protocol/dist/lib/store/casino/entities/providers/types';
import { Selector } from 'decryption_component-kit';

import { useZustandCasinoGamesStore } from 'stores/casino/games/store';
import {
  getActiveProviderIdSelector,
  setActiveProviderIdAction,
} from 'stores/casino/games/selectors';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './CasinoProviderSelector.module.css';

type Props = {
  classes?: string;
  subCategoryId?: number;
  categoryId?: number;
};

const CasinoProviderSelector = ({
  classes,
  subCategoryId,
  categoryId,
}: Props) => {
  const activeProviderId = useZustandCasinoGamesStore(
    getActiveProviderIdSelector
  );

  const setActiveProviderId = useZustandCasinoGamesStore(
    setActiveProviderIdAction
  );

  const providers = useHookFabric({
    fn: useGetProviders,
    customProps: {
      withGameCounters: false,
      categoryId,
      subCategoryId,
      sortFn: useCallback(
        () => (p1: IProvider, p2: IProvider) => p1.name.localeCompare(p2.name),
        []
      ),
    },
  }) as (IProvider & { gameCounter: number })[];

  const handleSetActiveProviderId = (id: number | null) => {
    setActiveProviderId(id);
  };

  return (
    <Selector
      placeholder="filterByProviders"
      activeId={activeProviderId}
      data={providers}
      size="full"
      handleClick={handleSetActiveProviderId}
      classes={classes}
      classesItem={styles.itemStyles}
      selectedMainItemClasses={styles.mainItem}
      isAll
    />
  );
};

export default CasinoProviderSelector;
