import React from 'react';
import SkeletLoader from '../SkeletLoader';

import styles from './BettingSkelet.module.css';

const BettingSkelet = () => (
  <div className={styles.wrapper}>
    <SkeletLoader classes={styles.bannerSkelet} />

    <div className={styles.row}>
      <SkeletLoader classes={styles.tournamentLoader} />

      <SkeletLoader classes={styles.tournamentLoader} />

      <SkeletLoader classes={styles.tournamentLoader} />

      <SkeletLoader classes={styles.tournamentLoader} />
    </div>

    <SkeletLoader classes={styles.eventLoader} />

    <SkeletLoader classes={styles.eventLoader} />

    <SkeletLoader classes={styles.eventLoader} />
  </div>
);

export default BettingSkelet;
