import { useState } from 'react';

import CasinoDrawer from 'components/Drawers/CasinoDrawer';
import BettingDrawer from 'components/Drawers/BettingDrawer';

import { sleep } from 'utils/shared';

import { useZustandModalStore } from 'stores/modal/store';
import { setModalInfoAction } from 'stores/modal/selectors';
import { ModalTypes } from 'stores/modal/types';

import styles from './Drawer.module.css';

type Props = {
  isCasino?: boolean;
};

const DrawerModal = ({ isCasino = false }: Props) => {
  const [isCloseDrawer, setCloseDrawerState] = useState(false);

  const close = useZustandModalStore(setModalInfoAction);

  const closeDrawer = async () => {
    setCloseDrawerState(true);

    await sleep(300);

    close(ModalTypes.Unknown);
  };

  return (
    <div className={styles.wrapperDrawer}>
      <div className={styles.content}>
        {isCasino ? (
          <CasinoDrawer isCloseDrawer={isCloseDrawer} />
        ) : (
          <BettingDrawer />
        )}
      </div>

      <div onClick={closeDrawer} className={styles.closeArea} />
    </div>
  );
};

export default DrawerModal;
