import { EPromoCodeType } from 'decryption_protocol/dist/lib/store/user/entities/promoCode/type';
import PromoCodeTitle, {
  PromoCodeTitleProps,
} from './components/PromoCodeTitle';
import { PromoCodeRowProps } from './components/PromoCodeRow';

export const PROMO_CODE_RESPONSE: {
  [key in EPromoCodeType]: React.FC<
    Omit<PromoCodeTitleProps, 'title' | 'description'>
  >;
} = {
  [EPromoCodeType.Undefined]: ({
    list,
    specV,
  }: Omit<PromoCodeTitleProps, 'title' | 'description'>) => <></>,
  [EPromoCodeType.FreeSpins]: ({
    list,
    specV,
  }: Omit<PromoCodeTitleProps, 'title' | 'description'>) => (
    <PromoCodeTitle
      title="promoCodePage.freeSpins.title"
      description="promoCodePage.freeSpins.description"
      list={list}
      specV={specV}
    />
  ),
  [EPromoCodeType.BonusCash]: ({
    list,
    specV,
  }: Omit<PromoCodeTitleProps, 'title' | 'description'>) => (
    <PromoCodeTitle
      title="promoCodePage.bonusCash.title"
      description="promoCodePage.bonusCash.description"
      list={list}
      specV={specV}
    />
  ),
  [EPromoCodeType.FtdBonusDepositCoeff]: ({
    list,
    specV,
  }: Omit<PromoCodeTitleProps, 'title' | 'description'>) => (
    <PromoCodeTitle
      title="promoCodePage.bonusOnDep.title"
      description="promoCodePage.bonusOnDep.description"
      list={list}
      specV={specV}
    />
  ),
  [EPromoCodeType.BonusPackActivation]: ({
    list,
    specV,
  }: Omit<PromoCodeTitleProps, 'title' | 'description'>) => (
    <PromoCodeTitle
      title="promoCodePage.bonusPack.title"
      description="promoCodePage.bonusPack.description"
      list={list}
      specV={specV}
    />
  ),
  [EPromoCodeType.ERG]: ({
    list,
    specV,
  }: Omit<PromoCodeTitleProps, 'title' | 'description'>) => (
    <PromoCodeTitle
      title="promoCodePage.erg.title"
      description="promoCodePage.erg.description"
      list={list}
      specV={specV}
    />
  ),
  [EPromoCodeType.RakebackBoost]: ({
    list,
    specV,
  }: Omit<PromoCodeTitleProps, 'title' | 'description'>) => (
    <PromoCodeTitle
      title="promoCodePage.rakeback.title"
      description="promoCodePage.rakeback.description"
      list={[
        ...list,
        {
          title: 'promoCodePage.rakeback.condition',
          value: 'promoCodePage.rakeback.conditionDescription',
        },
      ]}
      specV={`Х${+specV / 100}`}
    />
  ),
  [EPromoCodeType.BonusDeposit]: ({
    list,
    specV,
  }: Omit<PromoCodeTitleProps, 'title' | 'description'>) => (
    <PromoCodeTitle
      title="promoCodePage.bonusOnDep.title"
      description="promoCodePage.bonusOnDep.description"
      list={list}
      specV={specV}
    />
  ),
  [EPromoCodeType.Cash]: ({
    list,
    specV,
  }: Omit<PromoCodeTitleProps, 'title' | 'description'>) => (
    <PromoCodeTitle
      title="promoCodePage.cash.title"
      description="promoCodePage.cash.description"
      list={list}
      specV={specV}
    />
  ),
};
