import { useEffect, useState } from 'react';
import { useBetSlipEventCounter } from 'decryption_protocol_hooks';
import cln from 'classnames';

import BetSlipContent from 'components/Betting/BetSlipContent';
import BetSlipHeader from './components/BetSlipHeader';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './BetSlipButton.module.css';
import animation from 'styles/animation.module.css';

const BetSlipButton = () => {
  const [isShowFull, setFullState] = useState(false);

  const count = useHookFabric({ fn: useBetSlipEventCounter });

  const handleToggleState = () => {
    const newValue = !isShowFull;

    if (newValue) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    setFullState(newValue);
  };

  useEffect(() => {
    if (!count && isShowFull) {
      handleToggleState();
      // setFullState(false);
    }
  }, [count, isShowFull]);

  return (
    <div
      className={cln(styles.betSlipWrapper, {
        [styles.wrapperBetSlipContentShown]: isShowFull,
        [animation.animationBetSlipShow]: count,
        [animation.animationBetSlipHide]: !count,
      })}
    >
      <BetSlipHeader
        count={count}
        isOpened={isShowFull}
        handleToggleState={handleToggleState}
      />

      {isShowFull && <BetSlipContent />}
    </div>
  );
};

export default BetSlipButton;
