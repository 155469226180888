/* eslint-disable no-prototype-builtins */
import { create } from 'zustand';
import { ZustandMarketingTagsStore } from './types';

import {
  IS_VALID_MOBILE_APP,
  REGISTRATION_CLICK_ID_CAMPAING,
  REGISTRATION_UTM_MEDIUM,
  REGISTRATION_UTM_SOURCE,
  UTM_CONTENT,
} from 'constants/marketingTags';
import { setExistingValueToCookie } from './utils';

export const useZustandMarketingTabsStore = create<ZustandMarketingTagsStore>(
  () => ({
    utmMedium: setExistingValueToCookie(
      REGISTRATION_UTM_MEDIUM,
      window.location.href
    ),
    utmSource: setExistingValueToCookie(
      REGISTRATION_UTM_SOURCE,
      window.location.href
    ),
    utmCampaning: '',
    // setExistingValueToCookie(
    //   REGISTRATION_UTM_CAMPAING,
    //   window.location.href
    // ),
    clickIdCampaing: setExistingValueToCookie(
      REGISTRATION_CLICK_ID_CAMPAING,
      window.location.href
    ),
    utmContent: setExistingValueToCookie(UTM_CONTENT, window.location.href),
    isRNMobileApp: Boolean(window.ReactNativeWebView),

    isValidMobileApp: IS_VALID_MOBILE_APP,
  })
);
