import * as CryptoJS from 'crypto-js';
import { useEffect, useState } from 'react';

import { SupportSvg, Text } from 'decryption_component-kit';
import CircularLoader from 'components/ui/Loaders/Circular';

import { getSupportInfoSelector } from 'stores/configuration/selectors';
import { useZustandWebConfiguration } from 'stores/configuration/store';
import { useZustandSupportStore } from 'stores/support';
import {
  isInitChatSelector,
  setSupportChatStateAction,
} from 'stores/support/selectors';
import { useZustandNotificationStore } from 'stores/notification/store';
import { addNotificationAction } from 'stores/notification/selectors';
import { ENotificationType } from 'stores/notification/types';
import { useZustandUserStore } from 'stores/user/personal/store';
import {
  getUserInfoSelector,
  getUserKYCStatusSelector,
  isAuthorizedUserSelector,
} from 'stores/user/personal/selectors';
import { useZustandMarketingTabsStore } from 'stores/marketing/store';
import { isMobileAppSelector } from 'stores/marketing/selectors';

import { APP_TYPE } from 'constants/ui';
import { createJWT } from 'utils/shared';

import styles from './JivoChat.module.css';
import { EKYCStatus } from 'stores/user/personal/types';

const body = document.body;

const JivoChat = ({ isDisableText = false }: { isDisableText?: boolean }) => {
  const isInitChat = useZustandSupportStore(isInitChatSelector);

  const setInitJivoChatStatus = useZustandSupportStore(
    setSupportChatStateAction
  );

  const { jivoKey, secret } =
    useZustandWebConfiguration(getSupportInfoSelector) || {};

  const isAuth = useZustandUserStore(isAuthorizedUserSelector);

  const userInfo = useZustandUserStore(getUserInfoSelector);

  const kyc = useZustandUserStore(getUserKYCStatusSelector);

  const addNotification = useZustandNotificationStore(addNotificationAction);

  const [isLoadedChat, setLoadedChatState] = useState(false);

  const [isAvailableAgent, setAvailableAgent] = useState(false);

  const [openChat, setOpenChatState] = useState(false);

  const [isReceivedMsg, setReceivedMsgState] = useState(false);

  const isMobileApp = useZustandMarketingTabsStore(isMobileAppSelector);

  const toggleChatState = () => {
    setOpenChatState(!openChat);
    toggleJDivState();
  };

  const toggleJDivState = () => {
    const jivoDiv = document.getElementsByTagName(
      'jdiv'
    )?.[0] as HTMLDivElement;

    if (!jivoDiv) {
      return;
    }

    if (openChat) {
      jivoDiv.setAttribute('style', '');

      document.body.style.overflow = 'hidden';

      // @ts-ignore
      window.jivo_api.open();

      setReceivedMsgState(false);

      return;
    }
    document.body.style.overflow = 'unset';
    jivoDiv.setAttribute('style', 'display:none!important');
  };

  useEffect(() => {
    if (!isInitChat || !isLoadedChat) {
      if (window.jivo_api) {
        setLoadedChatState(true);
      }

      return;
    }
    toggleJDivState();
  }, [openChat, isInitChat, isLoadedChat]);

  useEffect(() => {
    if (!jivoKey || !isInitChat || isLoadedChat) {
      return;
    }

    window.jivo_onLoadCallback = () => {
      toggleJDivState();
      setLoadedChatState(true);
    };

    window.jivo_onAccept = () => {
      setAvailableAgent(true);
    };

    window.jivo_onClose = () => {
      setOpenChatState(false);
    };

    window.jivo_onMessageReceived = () => {
      if (!openChat) {
        setReceivedMsgState(true);
        addNotification({
          uid: 'support_msg',
          text: 'supportSendMessage',
          type: ENotificationType.Warning,
        });
      }
    };

    if (document.getElementById('JivoChatElement')) {
      return;
    }
    const scriptElement = document.createElement('script');

    scriptElement.src = `//code.jivo.ru/widget/${jivoKey}`;
    scriptElement.id = 'JivoChatElement';
    scriptElement.async = true;

    if (!body) {
      return;
    }

    body.appendChild(scriptElement);
  }, [jivoKey, isInitChat, openChat, isLoadedChat]);

  useEffect(() => {
    if (!isLoadedChat || !isInitChat) {
      return;
    }

    if (!window.jivo_api) {
      console.error('JIVO IS NOT SUPPORTED');

      return;
    }

    // if (openChat) {
    //   window.jivo_api?.setCustomData([
    // {
    //   content: userInfo?.clientId
    //     ? `user id: ${userInfo?.clientId}`
    //     : ' Not Auth',
    // },
    // {
    //   content: `email: ${userInfo?.email || '-'}`,
    // },
    // {
    //   content: `phoneNumber: ${userInfo?.phoneNumber || '-'}`,
    // },
    // {
    //   content: `Mobile App Installed: ${isMobileApp ? 'yes' : 'No'}`,
    // },
    // {
    //   content: `Play Link ${window.location.origin}`,
    // },
    // {
    //   content: `Currency : ${userInfo?.currencyCode} (${userInfo?.currencyId}))`,
    // },
    // {
    //   content: `KYC status: ${EKYCStatus[kyc]} (${kyc})`,
    // },
    //   ]);
    // }

    window.jivo_api?.setCustomData([
      {
        content: userInfo?.clientId
          ? `user id: ${userInfo?.clientId}`
          : ' Not Auth',
      },
      {
        content: `email: ${userInfo?.email || '-'}`,
      },
      {
        content: `phoneNumber: ${userInfo?.phoneNumber || '-'}`,
      },
      {
        content: `Mobile App Installed: ${isMobileApp ? 'yes' : 'No'}`,
      },
      {
        content: `Play Link ${window.location.origin}`,
      },
      {
        content: `Currency : ${userInfo?.currencyCode} (${userInfo?.currencyId}))`,
      },
      {
        content: `KYC status: ${EKYCStatus[kyc]} (${kyc})`,
      },
    ]);

    if (!userInfo || !isAuth) {
      window.jivo_api.setContactInfo({
        name: 'Not authenticated',
        email: 'Not authenticated',
        phone: 'Not authenticated',
      });

      return;
    }
    window.jivo_api.setContactInfo({
      name: `${userInfo.clientId}`,
      email: userInfo.email,
      phone: userInfo.phoneNumber,
    });
  }, [isLoadedChat, userInfo, isInitChat, isAuth, openChat, kyc]);

  useEffect(() => {
    if (jivoKey) {
      return;
    }

    setInitJivoChatStatus(true);
    sessionStorage.clear();
  }, [jivoKey]);

  useEffect(() => {
    if (userInfo && isLoadedChat && secret) {
      const jwt = createJWT(
        {
          id: `${userInfo.clientId}`,
        },
        secret
      );
      const res = window?.jivo_api?.setUserToken(jwt);

      console.warn('🚀 jwt response', res);
    }
  }, [userInfo, isLoadedChat, secret]);

  return (
    <div className={styles.customJivoButtonWrapper}>
      {isReceivedMsg && <div className={styles.newMsg} />}

      {!isLoadedChat && <CircularLoader classes={styles.loaderSize} />}

      {isLoadedChat && (
        <SupportSvg
          handleClick={toggleChatState}
          classes={styles.supportSvg}
          appType={APP_TYPE}
        />
      )}

      {!isDisableText && <Text idT="navigationBar.support" />}
    </div>
  );
};

export default JivoChat;
