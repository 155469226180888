import { create } from 'zustand';
import { ZustandConnectionStore } from './types';
import { ESocketCallbackStep } from 'decryption_protocol/dist/lib/socket/types';

export const useZustandConnectionStore = create<ZustandConnectionStore>(
  (set) => ({
    status: ESocketCallbackStep.Unknown,

    setStatus: (status) => {
      set({ status });
    },
  })
);
