import { useMemo, useState } from 'react';
import {
  ModalLayout,
  CircularLoader,
  useTranslate,
} from 'decryption_component-kit';
import {
  useReferralControllers,
  useReferralBalance,
} from 'decryption_protocol_hooks';
import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';

import ComponentKitBaseInput from 'components/ui/KitComponents/Inputs/ComponentKitBaseInput';
import ReferralTitles from '../ReferralSubTitle';
import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';
import HOCRequestLoader from 'components/HOCs/HOCRequestLoader';

import { DEFAULT_INPUT_VALUE } from 'components/ui/Input/Base/constant';

import { useZustandModalStore } from 'stores/modal/store';
import { setModalInfoAction } from 'stores/modal/selectors';

import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';
import { getUserCurrencyNameSelector } from 'stores/user/personal/selectors';
import { useZustandRequestStore } from 'stores/request/store';
import { addRequestKeyForLoaderAction } from 'stores/request/selector';

import { DefaultInputType } from 'components/ui/Input/Base/types';
import { ModalTypes } from 'stores/modal/types';

import {
  composeValidations,
  extractNumbersFromString,
  isMinMax,
  isRequired,
} from 'utils/validation';
import { useHookFabric } from 'hooks/useHookFabric';

import styles from './ReferralWithdraw.module.css';

const ReferralWithdraw = () => {
  const [withdrawSum, setWithdrawSum] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const setModal = useZustandModalStore(setModalInfoAction);

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const { getText } = useTranslate('', '');

  const [minText, maxText] = useMemo(
    () => [getText('errors.min'), getText('errors.max')],
    [getText]
  );

  const { withdraw } = useHookFabric({
    fn: useReferralControllers,
  });

  const { referralBalance, minWithdrawal } = useHookFabric({
    fn: useReferralBalance,
  });

  const currencyName = useZustandUserStore(getUserCurrencyNameSelector);

  const addToRequestQueue = useZustandRequestStore(
    addRequestKeyForLoaderAction
  );

  const onCloseModal = () => {
    setModal(ModalTypes.Unknown);
  };

  const handleChange = (value: string) => {
    let errorText =
      composeValidations([
        isRequired({ value: +value, errorText: 'errors.required' }),
        isMinMax({
          value: +value,
          errorTexts: [minText, maxText],
          min: minWithdrawal,
          max:
            referralBalance < minWithdrawal ? minWithdrawal : referralBalance,
        }),
      ]) || '';

    if (!value.length) {
      errorText = '';
    }

    setWithdrawSum({ value, errorText });
  };

  const handleWithdraw = () => {
    addToRequestQueue(ERequestIds.ReferralWithdraw);
    withdraw(+withdrawSum.value);
  };

  const isError = Boolean(withdrawSum.errorText);

  return (
    <ModalLayout
      classes={styles.wrapperReferralCopyContent}
      popUpHeaderProps={{
        title: getText('referral.withdrawModal.title'),
      }}
      buttonClose={onCloseModal}
    >
      <>
        <ReferralTitles subTitle="referral.withdrawModal.subTitle" />

        <div className={styles.availableSum}>
          {currencyName} {referralBalance}
        </div>

        <ComponentKitBaseInput
          placeholder={getText('referral.withdrawModal.title')}
          onChange={handleChange}
          patternFn={extractNumbersFromString}
          errorText={withdrawSum.errorText}
          inputStyleType="border-colorful"
          size="full"
          bottomText={getText('referral.withdrawModal.enterAmount')}
          classes={styles.marginB}
          withErrorSvg
          inputLabelType="labelUp"
        >
          <span className={styles.grayColor}>{currencyName}</span>
        </ComponentKitBaseInput>

        <HOCRequestLoader
          command="63x"
          requestId={ERequestIds.ReferralWithdraw}
          customLoader={<CircularLoader classes={styles.center} />}
        >
          <ComponentKitBaseButton
            text="referral.withdrawModal.transferToAccBtn"
            centreText
            handleClick={handleWithdraw}
            size="--button-full"
            color="primary"
            buttonHeight="--button-height-full"
            disabled={isError || !withdrawSum.value}
            marginB="base"
          />
        </HOCRequestLoader>

        <ComponentKitBaseButton
          text="referral.withdrawModal.cancel"
          centreText
          handleClick={onCloseModal}
          size="--button-full"
          color="secondary"
          buttonHeight="--button-height-full"
        />
      </>
    </ModalLayout>
  );
};

export default ReferralWithdraw;
