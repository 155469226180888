import { ZustandNotificationStore } from './types';

const getNotificationsInfoSelector = (state: ZustandNotificationStore) =>
  state.notifications;

export const getNotificationsSelector = (state: ZustandNotificationStore) => {
  const notifications = Object.values(getNotificationsInfoSelector(state));

  return notifications;
};

// ================================================================= ACTIONS =================================================================
export const addNotificationAction = (state: ZustandNotificationStore) =>
  state.addNotification;

export const removeNotificationAction = (state: ZustandNotificationStore) =>
  state.removeNotification;
