import { EDeviceType, ZustandDeviceStore } from './types';

export const getDeviceTypeSelector = (state: ZustandDeviceStore) =>
  state.device;

export const isDesktopDeviceSelector = (state: ZustandDeviceStore) =>
  getDeviceTypeSelector(state) === EDeviceType.Desktop;

export const isMobileDeviceSelector = (state: ZustandDeviceStore) =>
  getDeviceTypeSelector(state) === EDeviceType.Mobile;

// Action

export const setDeviceTypeAction = (state: ZustandDeviceStore) =>
  state.setDeviceType;
