import { create } from 'zustand';
import { ZustandNotificationStore } from './types';

export const useZustandNotificationStore = create<ZustandNotificationStore>(
  (set, get) => ({
    notifications: {},

    addNotification: (data) => {
      const { uid } = data;
      const { notifications } = get();

      const newData = {
        ...notifications,
        [uid]: data,
      };

      set({ notifications: newData });
    },
    removeNotification: (key: string) => {
      const { notifications } = get();

      const newNotifications = { ...notifications };

      delete newNotifications[key];
      set({ notifications: newNotifications });
    },
  })
);
