import { create } from 'zustand';
import { ZustandSupportStore } from './types';

export const useZustandSupportStore = create<ZustandSupportStore>((set) => ({
  isInitChat: false,

  setInitChatState: (isInitChat) => {
    set({ isInitChat });
  },
}));
