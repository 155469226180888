import { create } from 'zustand';
import { EFooterType, EHeaderType, ZustandUiStore } from './types';

export const useZustandUiStore = create<ZustandUiStore>((set) => ({
  headerType: EHeaderType.Base,
  footerType: EFooterType.Base,

  setHeaderType: (headerType) => {
    set({ headerType });
  },

  setFooterType: (footerType) => {
    set({ footerType });
  },
}));
