import { ReturnSubscribeBetSlipShortInfoProps } from 'decryption_protocol/dist/lib/store/betting/entities/betslip/types';
import { useBetSlipShortInfo } from 'decryption_protocol_hooks';

import Odd from 'components/Betting/Odd';

import { useHookFabric } from 'hooks/useHookFabric';

import styles from './BetSlipShortInfo.module.css';

const BetSlipShortInfo = () => {
  const betSlipInfo = useHookFabric({
    fn: useBetSlipShortInfo,
  }) as ReturnSubscribeBetSlipShortInfoProps;

  const { singleOddInfo } = betSlipInfo.oddInfo;

  return (
    <div className={styles.row}>
      <div className={styles.grayText}>
        {betSlipInfo.betCounter} x t.{betSlipInfo.betSlipName}
      </div>

      {!singleOddInfo ? (
        <div className={styles.betSlipOdd}>
          {betSlipInfo.oddInfo.expressOdd}
        </div>
      ) : (
        <Odd
          eventUid={singleOddInfo.eventUid}
          outcomeId={singleOddInfo.outcomeId}
          lineUid={singleOddInfo.lineUid}
          isPrematch={singleOddInfo.isPrematch}
          classes={styles.wrapperOdd}
        />
      )}
    </div>
  );
};

export default BetSlipShortInfo;
