import { create } from 'zustand';
import { Steps, ZustandPaymentsStore } from './types';

const DEFAULT_MESSAGE_INFO = {
  text: '',
  iconType: null,
};

export const useZustandPaymentsStore = create<ZustandPaymentsStore>((set) => ({
  step: Steps.ChooseMethod,
  activeTab: 'deposit',
  messageInfo: DEFAULT_MESSAGE_INFO,
  activeChannelId: null,
  activePaymentInfo: null,
  depositQrFormInfo: null,
  redirectUrl: null,

  setDepositQrFormInfo: (depositQrFormInfo) => {
    set({ depositQrFormInfo });
  },

  setActivePaymentInfo: (activePaymentInfo) => {
    set({ activePaymentInfo });
  },

  setStep: (step, redirectUrl = null) => {
    set({
      step,
      messageInfo: DEFAULT_MESSAGE_INFO,
      depositQrFormInfo: null,
      redirectUrl,
    });
  },
  setActivePaymentChannel: (activeChannelId) => {
    set({
      activeChannelId,
      messageInfo: DEFAULT_MESSAGE_INFO,
      step: Steps.ChooseMethod,
      depositQrFormInfo: null,
    });
  },
  setActiveTab: (activeTab) => {
    set({ activeTab });
  },

  setMessageInfo: (messageInfo) => {
    set({ messageInfo, step: Steps.MessageScreen });
  },
}));
