/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-console */
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import 'decryption_component-kit/dist/main.css';
import { HOCErrorBoundary } from 'decryption_component-kit';
import { YMInitializer } from 'react-yandex-metrika';

import App from './App';
import Modal from 'components/Alerts/Modal';
import ScrollToTop from 'components/ScrollToTop';
import Footer from 'components/ui/KitComponents/Footer';
import Notification from 'components/Alerts/Notification';
import AppNotify from 'components/AppNotify';

import reportWebVitals from './reportWebVitals';

import { sentryInitializing } from 'services/sentry';
import { initializingPixel } from 'services/pixel';
// import * as serviceWorkerRegistration from './serviceWorkerResgistration';
import { initializeWindowTools } from 'services/tools';

import './styles/normalize.css';
import './styles/colors.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

try {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
    console.debug = () => {};
    console.info = () => {};
  }

  root.render(
    <HOCErrorBoundary
      tapesPaths={[
        '/icons-elon/ui-kit/errorPage/top-tape.png',
        '/icons-elon/ui-kit/errorPage/bottom-tape.png',
        '/icons-elon/ui-kit/errorPage/top-tape-desk.png',
        '/icons-elon/ui-kit/errorPage/bottom-tape-desk.png',
      ]}
      logoProps={{
        appType: Number(process.env.REACT_APP_TYPE) || 0,
      }}
    >
      <>
        <Router>
          <AppNotify />

          <ScrollToTop />

          <App />

          <Modal />

          <Notification />

          <Footer />
        </Router>

        {!!process.env.REACT_APP_YANDEX &&
          process.env.NODE_ENV === 'production' && (
            <YMInitializer
              accounts={[+process.env.REACT_APP_YANDEX]}
              options={{ webvisor: true }}
            />
          )}
      </>
    </HOCErrorBoundary>
  );

  sentryInitializing();

  reportWebVitals();

  initializingPixel();

  initializeWindowTools();

  // serviceWorkerRegistration.register({
  //   onSuccess: () => {
  //     console.info('WORKERS ARE BEING INSTALLED');
  //   },
  //   onUpdate() {
  //     console.info('content updated');
  //   },
  // });
} catch (error) {
  console.info('INTERNAL ERROR');
}
