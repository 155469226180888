import { EPixSelector } from '../types';

export const FIRST_SELECTOR = [
  {
    name: 'walletPopUp.forms.cpf',
    id: EPixSelector.Cpf,
  },
  {
    name: 'walletPopUp.forms.cnpj',
    id: EPixSelector.Cnpj,
  },
];

export const SECOND_SELECTOR = [
  {
    name: 'walletPopUp.forms.checking',
    id: EPixSelector.Checking,
  },
  {
    name: 'walletPopUp.forms.saving',
    id: EPixSelector.Savings,
  },
];
