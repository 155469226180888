import { Text } from 'decryption_component-kit';

import PromoCodeRow from '../PromoCodeRow';

import styles from './PromoCodeTitle.module.css';

export type PromoCodeTitleProps = {
  title: string;
  description: string;
  specV: string | number;
  list: { title: string; value: string | number }[];
};

const PromoCodeTitle = (props: PromoCodeTitleProps) => {
  const { title, description, list, specV } = props;

  return (
    <div className={styles.wrapperBonusInfo}>
      <div className={styles.title}>
        <Text idT={title} specValue={`${specV}`} />
      </div>

      {list.map((i) => (
        <PromoCodeRow {...i} key={i.title} />
      ))}

      <div className={styles.description}>
        <Text idT={description} specValue={`${specV}`} />
      </div>
    </div>
  );
};

export default PromoCodeTitle;
