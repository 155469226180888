import { BaseInputUiProps } from 'decryption_component-kit/dist/lib/components/ui/Inputs/BaseInput/types';
import { BaseInputUi, useTranslate } from 'decryption_component-kit';

const ComponentKitBaseInputUi = (props: BaseInputUiProps) => {
  const { placeholder } = props;

  const { name, getText } = useTranslate(placeholder, '');

  return (
    <BaseInputUi
      {...props}
      placeholder={name}
      errorText={getText(props.errorText || '')}
    />
  );
};

export default ComponentKitBaseInputUi;
