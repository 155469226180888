export const TURKISH_HAVALE_HERA_BANK_IDS = [
  {
    id: 1,
    name: 'Garanti Bankası',
    src: '/icons-elon/payments/banks/turkish/garanti.svg',
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 2,
    name: 'Akbank',
    src: '/icons-elon/payments/banks/turkish/akbank.svg',
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 3,
    name: 'DenizBank',
    src: '/icons-elon/payments/banks/turkish/deniz.svg',
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 4,
    name: 'FinansBank',
    src: '/icons-elon/payments/banks/turkish/finansbank.svg',
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 5,
    name: 'İş Bankası',
    src: '/icons-elon/payments/banks/turkish/isBank.svg',
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 6,
    name: 'Ziraat Bankası',
    src: '/icons-elon/payments/banks/turkish/ziraat.svg',
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 7,
    name: 'Türkiye Ekonomik Bankası',
    src: '/icons-elon/payments/banks/turkish/teb.svg',
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 8,
    name: 'Halkbank',
    src: '/icons-elon/payments/banks/turkish/halkbank.svg',
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 9,
    name: 'Albaraka Türk',
    src: '/icons-elon/payments/banks/turkish/albaraka.svg',
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 10,
    name: 'KuveytTürk',
    src: '/icons-elon/payments/banks/turkish/KuveytTurk.svg',
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 11,
    name: 'İş Bankası',
    src: '/icons-elon/payments/banks/turkish/isBank.svg',
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 12,
    name: 'Fibabanka',
    src: '/icons-elon/payments/banks/turkish/fibababank.svg',
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 13,
    name: 'Yapı Kredi',
    src: '/icons-elon/payments/banks/turkish/yapiKredi.svg',
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 14,
    name: 'Vakıfbank',
    src: '/icons-elon/payments/banks/turkish/vakifBank.svg',
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 15,
    name: 'HSBC',
    src: '/icons-elon/payments/banks/turkish/hsbc.svg',
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 16,
    name: 'İng Bank',
    src: '/icons-elon/payments/banks/turkish/ing.svg',
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 17,
    name: 'Şekerbank',
    src: '/icons-elon/payments/banks/turkish/sekerbank.svg',
    minLimit: 0,
    maxLimit: 0,
  },
  {
    id: 18,
    name: 'DenizBank',
    src: '/icons-elon/payments/banks/turkish/deniz.svg',
    minLimit: 0,
    maxLimit: 0,
  },
];
