import React from 'react';
import { useBetSlipSpecialOdd } from 'decryption_protocol_hooks';

import { useHookFabric } from 'hooks/useHookFabric';

const ExpressOdd = () => {
  const odd = useHookFabric({
    fn: useBetSlipSpecialOdd,
    customProps: { expressOdd: true },
  }) as {
    express: string;
  };

  return <div>{odd.express}</div>;
};

export default ExpressOdd;
