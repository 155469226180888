import { useState } from 'react';
import cln from 'classnames';

import Input from 'components/ui/Input/Base';

import { DefaultInputType } from 'components/ui/Input/Base/types';
import { DEFAULT_INPUT_VALUE } from 'components/ui/Input/Base/constant';

import {
  composeValidations,
  extractNumbersFromString,
  isRequired,
} from 'utils/validation';

import animation from 'styles/animation.module.css';

type Props = {
  classes?: string;
  onChange: (v: string) => void;
};

const IdSignInForm = ({ classes, onChange }: Props) => {
  const [userId, setUserId] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const validateValue = (value: string) => {
    const errorText = composeValidations([
      isRequired({ value, errorText: 'errors.required' }),
    ]);

    setUserId({ value, errorText });

    if (errorText) {
      onChange('');

      return;
    }

    onChange(value);
  };

  return (
    <div className={cln(animation.animationFadeIn, classes)}>
      <Input
        isHidePlaceholder
        placeholder="authorization.inputs.userId"
        size="full"
        errorText={userId.errorText}
        patternFn={extractNumbersFromString}
        onChange={validateValue}
      />
    </div>
  );
};

export default IdSignInForm;
