import { MOBILE_POST_MASSAGE_ANALYTICS } from 'constants/transport';

export const sendAnalyticEvent = (payload: {
  eventName: string | number;
  eventBody: { [key: string | number]: any };
}) => {
  try {
    if (!window.ReactNativeWebView) {
      return;
    }

    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: MOBILE_POST_MASSAGE_ANALYTICS,
        payload,
      })
    );
  } catch (e) {
    console.error('send analytic event error');
  }
};
