import { ZustandBettingFrameStore } from './types';

export const getBettingFramePathnameSelector = (
  state: ZustandBettingFrameStore
) => state.navigateToPage;
export const getBettingFrameModalSelector = (state: ZustandBettingFrameStore) =>
  state.openPopUpType;

// ACTIONS

export const setBettingFramePropertyAction = (
  state: ZustandBettingFrameStore
) => state.setBettingFrameProperty;
