import cln from 'classnames';

import { ArrowSvg } from 'decryption_component-kit';

import styles from './ArrowButton.module.css';

type Props = {
  isOpened?: boolean;
  toggleState?: (e: any) => void;
  classes?: string;
};

const ArrowButton = ({ isOpened, toggleState, classes = '' }: Props) => (
  <ArrowSvg
    classes={cln(styles.default, classes, {
      [styles.openedColor]: isOpened,
    })}
    handleClick={toggleState}
  />
);

export default ArrowButton;
