export interface ZustandUiStore extends ZustandUiStoreEntities {
  setHeaderType: (d: EHeaderType) => void;
  setFooterType: (d: EFooterType) => void;
}

interface ZustandUiStoreEntities {
  headerType: EHeaderType;
  footerType: EFooterType;
}

export enum EHeaderType {
  Base,
  Authorized,
  SignUp,
  Game,
  WheelOfFortune,
  Hidden,
}

export enum EFooterType {
  Base,
  Hidden,
}
