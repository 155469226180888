import { ModalLayout } from 'decryption_component-kit';

import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';
import ReferralTitles from '../ReferralSubTitle';
import { ReactComponent as SuccessSvg } from 'assets/referrals/success.svg';

import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { useZustandModalStore } from 'stores/modal/store';
import { ModalTypes } from 'stores/modal/types';

import styles from './ReferralSuccess.module.css';

const ReferralSuccess = () => {
  const setModal = useZustandModalStore(closeAndOpenModalAction);

  const onCloseModal = () => {
    setModal(ModalTypes.Unknown);
  };

  return (
    <ModalLayout
      classes={styles.wrapperReferralFailure}
      popUpHeaderProps={{
        transparent: true,
      }}
      buttonClose={onCloseModal}
    >
      <>
        <ReferralTitles subTitle="referral.successModal.subTitle" />

        <SuccessSvg className={styles.marginB} />

        <ComponentKitBaseButton
          text="referral.successModal.ok"
          handleClick={onCloseModal}
          size="--button-full"
          buttonHeight="--button-height-full"
          color="primary"
          centreText
        />
      </>
    </ModalLayout>
  );
};

export default ReferralSuccess;
