import { ZustandBettingTimeFilterStore } from './types';

export const getActiveTimeFilterEventsSelector = (
  store: ZustandBettingTimeFilterStore
) => store.time;

// ACTIONS

export const setTimeFilterEventsAction = (
  store: ZustandBettingTimeFilterStore
) => store.setActiveTime;
