import cln from 'classnames';

import animation from 'styles/animation.module.css';
import EmailInput from 'components/ui/Input/Email';

type Props = {
  classes?: string;
  onChange: (v: string) => void;
};
const EmailSignInForm = ({ classes, onChange }: Props) => (
  <div className={cln(animation.animationFadeIn, classes)}>
    <EmailInput
      isHidePlaceholder
      placeholder="authorization.inputs.email"
      size="full"
      withoutConfirmCode
      onChange={onChange}
    />
  </div>
);

export default EmailSignInForm;
