import cln from 'classnames';

import CircularLoader from '../Circular';

import styles from './BonusCancelLoader.module.css';

type Props = {
  classes?: string;
};

const BonusCancelLoader = (props: Props) => {
  const { classes } = props;

  return (
    <div className={cln(styles.wrapperBonusLoader, classes)}>
      <CircularLoader classes={styles.size} />
    </div>
  );
};

export default BonusCancelLoader;
