import { create } from 'zustand';
import { ZustandBonusPackStore } from './types';

export const useZustandBonusPackStore = create<ZustandBonusPackStore>(
  (set) => ({
    isAvailable: false,

    setIsAvailableStatus: (isAvailable) => {
      set({ isAvailable });
    },
  })
);
