export interface ZustandProfileStore extends ZustandProfileEntities {
  setError: (errorText: string) => void;
  setStatus: (status: UpdateStatus) => void;
}

interface ZustandProfileEntities {
  errorText: string;
  status: UpdateStatus;
}

export enum UpdateStatus {
  Unknown,
  Rejected,
  Success,
}
