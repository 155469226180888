import cln from 'classnames';

import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';

import { useZustandRightSideStore } from 'stores/drawers/rightSide/store';
import {
  getActiveRightSideTabSelector,
  setRightSideActiveTabAction,
} from 'stores/drawers/rightSide/selectors';
import { EActiveRightSideTab } from 'stores/drawers/rightSide/types';

import styles from './RightSideToggler.module.css';

const RightSideToggler = () => {
  const activeTab = useZustandRightSideStore(getActiveRightSideTabSelector);

  const setTab = useZustandRightSideStore(setRightSideActiveTabAction);

  const handleSetTab = (tab: EActiveRightSideTab) => () => {
    setTab(tab);
  };

  return (
    <div className={styles.rightSideTogglerWrapper}>
      <ComponentKitBaseButton
        text="tournaments.tabs.activeTournament"
        color="transparent"
        size="--button-full"
        buttonHeight="--button-height-l"
        handleClick={handleSetTab(EActiveRightSideTab.ActiveBattle)}
        classes={cln(styles.toggler, {
          [styles.active]: activeTab === EActiveRightSideTab.ActiveBattle,
        })}
        centreText
      />

      <ComponentKitBaseButton
        text="tournaments.tabs.providerTournaments"
        color="transparent"
        size="--button-full"
        buttonHeight="--button-height-l"
        handleClick={handleSetTab(EActiveRightSideTab.ProviderBattles)}
        classes={cln(styles.toggler, {
          [styles.active]: activeTab === EActiveRightSideTab.ProviderBattles,
        })}
        centreText
      />
    </div>
  );
};

export default RightSideToggler;
