import { useEffect, useState } from 'react';

import { Text } from 'decryption_component-kit';
import Input from 'components/ui/Input/Base';

import styles from '../../SignUp.module.css';
import { REFERRAL_CODE } from 'constants/ui';

type Props = {
  setPromoCode: (v: string) => void;
};

const RegistrationPromoCode = ({ setPromoCode }: Props) => {
  const [isShownPromoCode, setPromoCodeState] = useState(false);

  const handleShowPromoCode = () => {
    setPromoCodeState(!isShownPromoCode);
  };

  useEffect(() => {
    if (!REFERRAL_CODE) {
      return;
    }

    setPromoCode(`ref_code::${REFERRAL_CODE}`);
  }, []);

  if (REFERRAL_CODE) {
    return null;
  }

  return (
    <div className={styles.marginB}>
      {!isShownPromoCode ? (
        <div className={styles.promoTitle} onClick={handleShowPromoCode}>
          <Text idT="registrations.promoCode.openPromoCodeTitle" />
        </div>
      ) : (
        <Input
          placeholder="registrations.promoCode.placeholder"
          size="full"
          isHidePlaceholder
          onChange={setPromoCode}
          classes={styles.marginB}
        />
      )}
    </div>
  );
};

export default RegistrationPromoCode;
