import cln from 'classnames';

import styles from './Circular.module.css';

type Props = {
  classes?: string;
};

const CircularLoader = ({ classes }: Props) => (
  <div className={cln(styles.wrapper, classes)}>
    <div className={styles.insideCircular} />
  </div>
);

export default CircularLoader;
