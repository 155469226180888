import { useState } from 'react';
import ReactDOM from 'react-dom';
import cln from 'classnames';
import { useBalance } from 'decryption_protocol_hooks';
import { BalanceInfo } from 'decryption_protocol/dist/lib/store/user/entities/balance/types';

import { Text, CloseSvg } from 'decryption_component-kit';
import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';
import ComponentKitLogo from 'components/ui/KitComponents/ComponentKitLogo';

import { APP_TYPE, BROWSER_TYPE } from 'constants/ui';

import { useZustandMarketingTabsStore } from 'stores/marketing/store';
import { isValidMobileAppSelector } from 'stores/marketing/selectors';
import { useZustandUiStore } from 'stores/ui/store';
import { getFooterTypeSelector } from 'stores/ui/selectors';
import { getMobileAppsInfoSelector } from 'stores/configuration/selectors';
import { useZustandWebConfiguration } from 'stores/configuration/store';

import { EFooterType } from 'stores/ui/types';

import { BrowserType } from 'utils/browser';
import { openExternalLink } from 'utils/shared';
import { useHookFabric } from 'hooks/useHookFabric';

import styles from './AppNotify.module.css';

const root = document.getElementById('App_notify');

const AppNotify = () => {
  const footerType = useZustandUiStore(getFooterTypeSelector);

  const [isHide, setShowState] = useState(false);

  const isValidMobileApp = useZustandMarketingTabsStore(
    isValidMobileAppSelector
  );

  const appsInfo = useZustandWebConfiguration(getMobileAppsInfoSelector);

  const handleToggleState = () => {
    setShowState(!isHide);
  };

  const balanceInfo = useHookFabric({
    fn: useBalance,
    customProps: {
      isBalanceInfo: true,
    },
  }) as BalanceInfo | null;

  const isAndroid =
    BROWSER_TYPE === BrowserType.Android && Boolean(appsInfo?.android);

  const handleDownloadApk = () => {
    if (!isAndroid) {
      if (appsInfo?.ios) {
        openExternalLink(appsInfo?.ios);
      }

      return;
    }
    const a = document.createElement('a');
    // TODO: move to config
    const link = `/apps/${APP_TYPE}/${appsInfo?.android}?source=${window.location.origin}`;

    a.setAttribute('href', link);
    a.target = '_blank';

    a.click();
  };

  const isIos =
    BROWSER_TYPE === BrowserType.Iphone &&
    Boolean(appsInfo?.ios) &&
    (balanceInfo?.successDepositCnt || 0) >= 2;

  const isNotValidPlatform =
    BROWSER_TYPE !== BrowserType.Android && BROWSER_TYPE !== BrowserType.Iphone;

  if (
    isValidMobileApp ||
    !root ||
    isHide ||
    isNotValidPlatform ||
    (!isAndroid && !isIos)
  ) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className={cln(styles.wrapperAppNotify, {
        [styles.hide]: footerType === EFooterType.Hidden,
      })}
    >
      <div className={styles.leftContent}>
        <ComponentKitLogo classes={styles.logoWrapper} />

        <div className={styles.appText}>
          <div className={styles.title}>
            <Text
              idT="appNotify.title"
              specValue={isAndroid ? 'Android' : 'IOS'}
            />
          </div>
          <div className={styles.subTitle}>
            <Text
              idT="appNotify.description"
              specValue={isAndroid ? 'Android' : 'IOS'}
            />
          </div>
        </div>
      </div>

      <div className={styles.rightSection}>
        <ComponentKitBaseButton
          isResized
          color="primary"
          size="--button-s"
          centreText
          text="appNotify.download"
          handleClick={handleDownloadApk}
        />

        <CloseSvg classes={styles.closePopUp} handleClick={handleToggleState} />
      </div>
    </div>,
    root
  );
};

export default AppNotify;
