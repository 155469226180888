import { InstaPost } from 'decryption_protocol/dist/lib/service/experimental/instaNews/types';
import cln from 'classnames';
import Dash from '../Dash';

import { NEXT_PUBLIC_BASE_IMAGE_URL } from 'constants/image';
import { useZustandInstaNewsStore } from 'stores/instaNews/store';
import { setInstaNewsPropertyAction } from 'stores/instaNews/selectors';
import { getDateByFormat } from 'utils/date';

import styles from './SlideProgress.module.css';

type Props = {
  activeSlide: number;
  posts: InstaPost[];
  setActiveSlide: (n: number) => void;
};

const SlideProgress = (props: Props) => {
  const { posts, activeSlide, setActiveSlide } = props;

  const activePost = posts[activeSlide];

  const setLastId = useZustandInstaNewsStore(setInstaNewsPropertyAction);

  const setNextSlide = () => {
    if (activeSlide === posts.length) {
      return;
    }

    const newInx = activeSlide + 1;

    setActiveSlide(newInx);
    const { id } = posts[newInx] || {};

    if (id) {
      setLastId('lastTimeStoryId', id);
    }
  };

  return (
    <div className={styles.wrapperSlideProgress}>
      <div className={styles.content}>
        {posts.map(({ id }, inx) => (
          <Dash
            key={id}
            isActive={id === activePost?.id}
            classes={cln(styles.dash, {
              [styles.active]: inx < activeSlide,
            })}
            callback={setNextSlide}
          />
        ))}
      </div>

      {/* TODO: remove it */}
      <div className={styles.row}>
        <img
          src={`${NEXT_PUBLIC_BASE_IMAGE_URL}/logo/logo.webp`}
          className={styles.logo}
        />
        <div className={styles.infoBox}>
          <div className={styles.title}>{activePost?.name || 'test'} </div>
          <div className={styles.description}>
            {getDateByFormat('DD:mm', activePost?.startTime || 0)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlideProgress;
