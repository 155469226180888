import { ERouteNames } from 'Routes/types';

export const BIG_APP_BUTTONS = [
  {
    type: 'casino',
    text: 'drawers.casino.title',
    to: ERouteNames.CasinoHome,
  },
  {
    type: 'betting',
    text: 'drawers.betting.title',
    to: ERouteNames.FrameBetting,
    // : ERouteNames.BettingLive}
  },
];
