import cln from 'classnames';
import { useTransportControllers } from 'decryption_protocol_hooks';
import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';

import ProgressWagering from '../../Progress/ProgressWagering';
import ButtonCancelBonus from 'components/ui/Buttons/CancelBonus';
import HOCRequestLoader from 'components/HOCs/HOCRequestLoader';
import BonusCancelLoader from 'components/ui/Loaders/BonusCancelLoader';
import CountDownKit from 'components/ui/KitComponents/CountDownKit';
import { Text, useTranslate, BonusGiftSvg } from 'decryption_component-kit';
import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';

import { ACTIVATED_INFO, ACTIVATE_INFO } from './constants';
import { MANUAL_BET } from 'constants/localstoreKeys';
import { BASE_IMG_URL } from 'constants/image';

import { Props } from './types';

import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { useZustandFormsStore } from 'stores/money/forms/store';
import { setDepositBonusIdForActivateAction } from 'stores/money/forms/selectors';

import { ModalTypes } from 'stores/modal/types';

import { getFromLocalStorage } from 'utils/localstorage';
import { useHookFabric } from 'hooks/useHookFabric';

import styles from './BonusOnDeposit.module.css';

const BonusOnDeposit = (props: Props) => {
  const {
    operationId,
    timeStamp,
    promotion,
    classes,
    isActive = false,
    fullRolled = 0,
    percent = 0,
    rolled = 0,
    isPack,
    wager,
  } = props;

  const setModal = useZustandModalStore(closeAndOpenModalAction);

  const { getText } = useTranslate('', '');

  const setBonusId = useZustandFormsStore(setDepositBonusIdForActivateAction);

  const { sendCommand } = useHookFabric({ fn: useTransportControllers });

  const handleBetManual = () => {
    const manualBetSum = getFromLocalStorage(MANUAL_BET);

    if (manualBetSum) {
      const data = {
        operationId,
        amount: manualBetSum,
        requestId: 1,
      };

      sendCommand('43x', data);

      return;
    }

    setModal(ModalTypes.MoneyOperations);
    setBonusId(operationId);
  };

  const info = isActive ? ACTIVATED_INFO : ACTIVATE_INFO;

  return (
    <div className={cln(styles.wrapperBonusOnDeposit, classes)}>
      <div className={styles.content}>
        <div className={styles.title}>
          <span className={styles.left}>
            <Text
              idT="bonusCabinet.bonusOnDeposit.title"
              specValue={`${promotion}`}
            />
          </span>
          <span className={styles.detailsBtn}>
            {isActive && (
              <HOCRequestLoader
                requestId={ERequestIds.CancelOperation}
                command="23x"
                customLoader={<BonusCancelLoader />}
              >
                <ButtonCancelBonus
                  nameBonusKey="bonusCabinet.cancelBonus.cancelOperation"
                  classes={styles.cancelBtn}
                  operationId={operationId}
                />
              </HOCRequestLoader>
            )}
          </span>
        </div>

        <div className={styles.rowInfo}>
          <div
            className={cln(styles.shortDescription, {
              [styles.disabledMarginB]: isPack,
              [styles.disableText]: isActive,
            })}
          >
            {info.map(({ text, key }) => (
              <div className={styles.row} key={text}>
                <Text idT={text} />:<span>{props[key] || '-1'}</span>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.row}>
          {!isPack && !isActive && (
            <ComponentKitBaseButton
              color="primary"
              size="--button-xxl"
              buttonHeight="--button-height-l"
              handleClick={handleBetManual}
              startIcon={<BonusGiftSvg />}
              centreText
              text={getText('bonusCabinet.bonusOnDeposit.getBonus')}
              classes={styles.button}
            />
          )}
          {!isActive && !isPack && timeStamp && (
            <CountDownKit
              endTimestamp={timeStamp}
              wrapperClasses={styles.countDownClasses}
              timeBlockClasses={styles.timeBlockClasses}
              timeClasses={styles.timeClasses}
            />
          )}
        </div>
      </div>

      {isActive && (
        <ProgressWagering
          rolled={rolled}
          fullRolled={fullRolled}
          percent={percent}
          classes={styles.zIndex}
          wager={wager}
        />
      )}
      <img
        src={`${BASE_IMG_URL}/bonuses/bonus-on-deposit-active.png`}
        className={cln(styles.bonusOnDepositImg, styles.activeIcon)}
      />
    </div>
  );
};

export default BonusOnDeposit;
