import { useZustandMarketingTabsStore } from 'stores/marketing/store';

import { DeviceType } from 'types/hooks';
import {
  ERequestIds,
  RegistrationSubType,
} from 'decryption_protocol/dist/lib/binary/types';
import { useZustandUserStore } from 'stores/user/personal/store';

import {
  useZustandLanguagesKitStore,
  languageSelectors,
} from 'decryption_component-kit';
import { APP_TYPE } from 'constants/ui';

export const getAuthorizationData = (
  at1: string,
  at2: string,
  requestId: ERequestIds
) => ({
  at1,
  at2,
  location: window.location.origin, // not important
  timeZone: Math.floor(new Date().getTimezoneOffset() / -60), // not important
  language: languageSelectors.getActiveLanguageNameKitSelector(
    useZustandLanguagesKitStore.getState()
  ),
  userAgent: window.navigator.userAgent,
  appType: process.env.REACT_APP_TYPE || 1,
  requestId,
});

type Props = {
  currencyId: number;
  countryId: number;
  countryName: string;
  promoCode?: string;
  registrationType: RegistrationSubType;
};

export const getRegistrationData = (props: Props) => {
  const {
    currencyId,
    countryId,
    countryName,
    registrationType,
    promoCode = '',
  } = props;

  const { utmMedium, utmSource, utmCampaning, clickIdCampaing } =
    useZustandMarketingTabsStore.getState();

  // utmContent
  return {
    registrationType,
    appType: APP_TYPE,
    currencyId,
    countryName,
    countryId,
    gmt: Math.floor((new Date().getTimezoneOffset() / 60) * -1), // not important,
    location: window.location.origin, // not important,
    promoCode: promoCode.toUpperCase(),
    utmMedium,
    utmSource,
    utmCampaign: utmCampaning,
    language: languageSelectors.getActiveLanguageNameKitSelector(
      useZustandLanguagesKitStore.getState()
    ),
    clickId: clickIdCampaing,
    // TODO dynamic
    deviceType: DeviceType.Mobile,
    requestId: ERequestIds.Registration,
  };
};
type UpdateUserProps = {
  phoneNumber?: string;
  email?: string;
  code: string;
  requestId: ERequestIds;
};

export const getUpdateUserData = (props: UpdateUserProps) => {
  const { phoneNumber = '', email = '', code, requestId } = props;
  const user = useZustandUserStore.getState().userInfo;

  if (!user) {
    return null;
  }

  return {
    registerType: RegistrationSubType.UpdateUserInfo,
    clientId: 0,
    appType: process.env.REACT_APP_TYPE || -2,
    surname: '',
    name: '',
    patronymic: '',
    birthDay: 1,
    month: 0,
    year: 1970,
    country: '',
    countryId: 0,

    city: '',
    cityId: 0,
    postal: '',
    regionName: '',
    regionId: 0,
    address: '',
    login: '',
    password: '',
    currencyId: 0,

    newsletters: 0,
    timeZone: 0,
    domain: '',

    phoneNumber,
    email,
    sms: code,
    requestId,
  };
};
