import { InfoRow } from './types';

export const ACTIVATE_INFO: InfoRow[] = [
  {
    text: 'bonusCabinet.info.wager',
    key: 'wager',
  },
  {
    text: 'bonusCabinet.info.maxBonus',
    key: 'maxBonus',
  },
  {
    text: 'bonusCabinet.info.minBet',
    key: 'minBet',
  },
  {
    text: 'bonusCabinet.info.maxBet',
    key: 'maxBet',
  },
];

export const ACTIVATED_INFO: InfoRow[] = [
  {
    text: 'bonusCabinet.info.minBet',
    key: 'minBet',
  },
  {
    text: 'bonusCabinet.info.wager',
    key: 'wager',
  },
  {
    text: 'bonusCabinet.info.maxBet',
    key: 'maxBet',
  },
  {
    text: 'bonusCabinet.info.maxBonus',
    key: 'maxBonus',
  },
];
