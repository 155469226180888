import { useEffect, useState } from 'react';
import cln from 'classnames';

import styles from './Indicator.module.css';

type Props = {
  isRed: boolean;
  isAnimate: boolean;
  isGreen: boolean;
};

const Indicator = ({ isGreen, isRed, isAnimate }: Props) => {
  const [animation, setAnimationState] = useState<'unknown' | 'green' | 'red'>(
    'unknown'
  );

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;

    if (isGreen || isRed) {
      timer = setTimeout(() => {
        setAnimationState('unknown');
      }, 1500);

      if (isRed) {
        setAnimationState('red');
      }

      if (isGreen) {
        setAnimationState('green');
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isGreen, isRed]);

  return (
    <div
      className={cln(styles.oddIndicate, {
        [styles.greenAnimation]: animation === 'green',
        [styles.redAnimation]: animation === 'red',
      })}
    />
  );
};

export default Indicator;
